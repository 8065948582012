import { useContext } from "react"
import { matchContext, matchContextInterface } from "../../App"
import PlayerResultTile from "./PlayerResultTile"


interface ResultsPanelProps {
    translate: string,
    transition:string,
    winners:any,
    losers:any,
    playerId:string,
    playerPlacement:any
}




export default function ResultsPanel(props:ResultsPanelProps) {

    const matchContextValues:matchContextInterface = useContext(matchContext)

    function showPlayerTileGroup(group:any) {
        if (!group) return
        return (
            <div style={{display:'flex', flexDirection:'column', rowGap:'10px'}}>
                {Object.keys(group).map((currentPlayerId)=> {
                    return <PlayerResultTile key={currentPlayerId} playerResult={group[currentPlayerId]} isCurrentPlayer={props.playerId === currentPlayerId}/>
                })}
            </div>
        )
    }



    function returnPlacementText() {
        switch(props.playerPlacement.place) {
            case 1:
                return '1st'
            case 2:
                return '2nd'
            case 3:
                return '3rd'
            default:
                return`${props.playerPlacement.place}th`
        }
    }
    // console.log(props.playerPlacement.place)

    return (
        <div className="settingsPanel-master" style={{'transform':props.translate, 'transition': props.transition, zIndex:'2'}}>
            
            <div className="settingsPanel-content">
                <div>
                    <h2 className="settingsPanel-header">FINAL SCORES</h2>
                    <div style={{fontSize:'1.1rem', fontWeight:'bold', textAlign:'left'}}>
                        {props.playerPlacement.didTie ? 
                        <span>you tied for </span>
                        : <span>you came in </span>
                        }

                        <span style={{color:'var(--highlight)'}}>{returnPlacementText()} place!</span>
                    </div>
                    <div style={{'height':'1px', 'marginTop':'10px','backgroundColor':'var(--bg)'}}></div>
                </div>
                
                <div className="settingsPanel-settings">
                        <h4 className="settingsPanel-subHeader">{Object.keys(props.winners).length > 1? 'winners' : 'winner'}</h4>
                    <div className="settingsPanel-settingsSection">
                        {showPlayerTileGroup(props.winners)}
                    </div>

                    {Object.keys(props.losers).length > 0 &&
                        <>
                            <h4 className="settingsPanel-subHeader" >{Object.keys(props.losers).length > 1? 'losers' : 'loser'}</h4>
                            <div className="settingsPanel-settingsSection" style={{overflowY:'scroll', scrollPaddingTop:'200px'}}>
                                {showPlayerTileGroup(props.losers)}

                                {/* below is used to test scroll / overflow behaviour with multiple losers */}
                                {/* 
                                {showLosers()}
                                {showLosers()}
                                {showLosers()}
                                {showLosers()}
                                {showLosers()}
                                {showLosers()}
                                {showLosers()} 
                                */}
                                

                                <div>
                                    <div style={{height:'10rem'}}></div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>

            <div style={{position:'absolute', bottom:0, height:'30vh', width:'100%', background: 'linear-gradient(0deg, var(--prompt) 40%, transparent)', borderRadius:'10px'}}/>
                
            <div style={{position:'absolute', bottom:0, width:'calc(100% - 60px)', margin:'0px 30px 30px 30px'}}>
                <div className="settingsPanel-optionRow">
                    <button className="settingsPanel-optionRow-ActionButton" onClick={()=>matchContextValues.setShouldDoHomeWipe!({shouldWipe:true, reason:""})}>leave</button>
                </div>
            </div>
            
        </div>
    )
}