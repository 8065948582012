import React, {useContext, useEffect, useLayoutEffect, useRef, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {analytics, database} from '../firebase-connect'
import {ref, get, update, child, onDisconnect} from "firebase/database";
import {shuffle} from 'lodash'
import { getRandomNicknameNoun, returnTouchMoveDelta} from '../components/fill-my-prompt/utils';
import { matchContext } from '../App'
import { timeouts } from '../components/fill-my-prompt/config';
import roomIds from '../roomIds.json'
import { isMobile } from 'react-device-detect';
import HomepageCard from './HomepageCard'
import HomepageExample from './HomepageExample';
import { logEvent } from 'firebase/analytics';



let greetingList = [
    "hello there, ",
    "howdy, ",
    "what's up ",
    "ahoy, ",
    "hey, ",
    "oh hi, "
]
 
let greeting = shuffle(greetingList)[0]


function generateRandomRoomId(roomsAlreadyTaken:string[]) {
    function gen() {
        let res = ""
        const characters ='ABCDEFGHIJKLMNOPQRSTUVWXYZ';
        for (let i = 0; i < 4; i++) {
            res += characters[Math.floor(Math.random() * characters.length)]
            
        }
        return res
    }


    let newRoomId = gen()

    while (roomsAlreadyTaken.includes(newRoomId)) {
        newRoomId = gen()
    }

    return newRoomId
}


function isValidNickname(nickNameToCheck: string | undefined) {
    if (nickNameToCheck === "" || nickNameToCheck === undefined) return false
    
    nickNameToCheck = nickNameToCheck.toLowerCase()
    let blockedWords: string[] = [
        "rape",
        "rapes",
        "raper",
        "rapers",
        "rapist",
        "raping",
        "nigger",
        "niggers",
        "nigg",
        "nig",
        "niggy",
        "faggot",
        "faggots",
        "fag",
        "fags",
        "faggy",
        "nazi",
        "chink",
        "negro",
        "dyke",
        "pedo",
        "gook",
        "hitler",
    ]
    if (
        blockedWords.some((blockedWord) => {
            if (nickNameToCheck!.includes(blockedWord) && blockedWord.length === nickNameToCheck!.length) return true
            return false
        })
    ) {
        return false
    }

    return true
}




interface HomepageLocationState{
    doBackAnimation?:boolean,
    messagePopup?:string
}

interface HomepageProps{
    playerId:string,
    setChosenTheme:any
}

const exampleData = [
    {
        "prompt":"anything is edible if you have the guts, even ________",
        "fills":["cute singing chipmunks","foot fungus","the declaration of independence"]
    },
    {
        "prompt":"sure i was caught ________, but that's no reason to fire me",
        "fills":["trying to eat staplers","covering my feet in queso","picking other people's noses"]
    },
    {
        "prompt":"5.99 for _______? that's a steal!",
        "fills":["the soul of an innocent rat","a sentient toilet plunger","a slightly used diaper"]
    }
]

const shuffledExampleData = shuffle(exampleData)



function Homepage(props:HomepageProps){
    let matchContextValues = useContext(matchContext)
    const locationState = useLocation().state as HomepageLocationState?? {}
    const navigate = useNavigate()
    const [nickname, setNickname] = useState<string|null>(null)
    const [isNicknameBoxFocused, setIsNicknameBoxFocused] = useState<boolean>(false)
    const [fallbackNickname, setFallbackNickname] = useState<string|undefined>(undefined)
    const [homepageDivTranslate, setHomepageDivTranslate] = useState<number>(locationState.doBackAnimation ? 105 : 0)
    const [homepageDivTransition, setHomepageDivTransition] = useState<string>(locationState.doBackAnimation ? `transform ${timeouts.HOMEPAGE_TO_ROOM*2}ms cubic-bezier(0.23, 0.59, 0.14, 1)` : `transform ${timeouts.HOMEPAGE_TO_ROOM}ms cubic-bezier(0.68, 0.01, 0.8, 0.89)`)
    const [isJoinBoxFocused, setIsJoinBoxFocused] = useState<boolean>(false)
    const [touchStartX, setTouchStartX] = useState<number>(0)
    const [touchDeltaX, setTouchDeltaX] = useState<number>(0)
    const swipeDeltaNeeded = 50
    const [mobileHeight, setMobileHeight] = useState<number>(0)
    let messagePopup = locationState.messagePopup?? ""
    const [homepageMessageText, setHomepageMessageText] = useState<string>("")
    const [messagePopupTransform, setMessagePopupTransform] = useState<string>('translate(0px, -20vh)')
    const [focusedCard, setFocusedCard] = useState<string>('prompt')
    const nicknameInputRef = useRef<HTMLInputElement>(null)
    const nicknameInputSizerRef = useRef<HTMLDivElement>(null)
    const [nicknameInputWidth, setNicknameInputWidth] = useState<number>(0)
    const homepageDivRef = useRef<HTMLDivElement>(null)
    const fillAndPromptDivRef = useRef<HTMLDivElement>(null)
    const [fillAndPromptDivVisible, setFillAndPromptDivVisible] = useState<boolean>(false)
    const goalExplanationDivRef = useRef<HTMLDivElement>(null)
    const [goalExplanationDivVisible, setGoalExplanationDivVisible] = useState<boolean>(false)
    const tryToFillDivRef = useRef<HTMLDivElement>(null)
    const [tryToFillDivVisible, setTryToFillDivVisible] = useState<boolean>(false)
    const thatIsAllDivRef = useRef<HTMLDivElement>(null)
    const [thatIsAllDivVisible, setThatIsAllDivVisible] = useState<boolean>(false)
    const [exampleSetIndex, setExampleSetIndex] = useState<number>(0)
    const renderCounter  = useRef(0);
    renderCounter.current = renderCounter.current + 1;
    console.debug('> Homepage Rendered:', renderCounter.current)

    useLayoutEffect(() => {
        // console.log('LAYOUT EFFECT:', nicknameInputSizerRef.current?.clientWidth)
        setNicknameInputWidth(nicknameInputSizerRef.current?.clientWidth??0)
    },[nickname])


    useEffect(() => {
        console.log("Homepage Loaded")
        window.scrollTo({ top: 0});

        if (isMobile) setMobileHeight(window.innerHeight)
        document.body.style.overflow = ""
        document.body.style.overflowX = "hidden"
        document.body.style.position = "relative"


        if (messagePopup !== "") {
            setTimeout(() => {setMessagePopupTransform('')},50)
            setTimeout(() => {
                setMessagePopupTransform('translate(0px, -20vh)')
                window.history.replaceState({}, document.title)
            }, 4000)
        }
        

        if (locationState.doBackAnimation) {
            greeting = shuffle(greetingList)[0]
            setTimeout(() => {
                setHomepageDivTranslate(0)
            }, 50)
            setTimeout(() => {
                setHomepageDivTransition(`transform ${timeouts.HOMEPAGE_TO_ROOM}ms cubic-bezier(0.68, 0.01, 0.8, 0.89)`)
            }, 100)
            window.history.replaceState({}, document.title)
        }

        if (matchContextValues.roomId !== "") matchContextValues.setRoomId!("")
        if (matchContextValues.isInMatch) matchContextValues.setIsInMatch!(false)


        // get player's nickname if one is found in Database, otherwise create and set one
        get(child(ref(database), `players/${props.playerId}/nickname`))
            .then((snapshot) => {
                let newNickname = ""
                if (snapshot.exists()) {
                    newNickname = snapshot.val()
                    if (newNickname.includes(" ")) newNickname = newNickname.split(" ")[1]
                } else {
                    newNickname = getRandomNicknameNoun()
                    update(ref(database, `players/${props.playerId}`), { nickname: newNickname })
                }

                setNickname(newNickname)
                setFallbackNickname(newNickname)
            })
            .catch((error) => {
                console.error(error)
            })
    }, [])


    useEffect(()=> {
        
        if (homepageMessageText !== "") {
            setTimeout(() => {setMessagePopupTransform('')},50)
            setTimeout(() => {
                setMessagePopupTransform('translate(0px, -20vh)')
                setTimeout(() => {setHomepageMessageText("")}, 1000)
            }, 4000)
        }

    },[homepageMessageText])


    useEffect(()=> {
        window.addEventListener('scroll', scrollHandler);
        return () => window.removeEventListener('scroll', scrollHandler);
    },[fillAndPromptDivVisible, goalExplanationDivVisible, tryToFillDivVisible, thatIsAllDivVisible])


    function scrollHandler(e:any) {
        let scrollPos = window.pageYOffset + (window.innerHeight * 0.66)

        if (!fillAndPromptDivVisible && scrollPos >= fillAndPromptDivRef.current!.offsetTop + 100){
            setFillAndPromptDivVisible(true)
        }

        if (!goalExplanationDivVisible && scrollPos >= goalExplanationDivRef.current!.offsetTop){
            setGoalExplanationDivVisible(true)
        }

        if (!tryToFillDivVisible && scrollPos >= tryToFillDivRef.current!.offsetTop){
            setTryToFillDivVisible(true)
        }

        if (!thatIsAllDivVisible && window.pageYOffset + window.innerHeight >= thatIsAllDivRef.current!.offsetTop + thatIsAllDivRef.current!.clientHeight){
            setThatIsAllDivVisible(true)
        }
        
    }


    function animateToRoom(roomId:string) {
        setHomepageDivTranslate(-105)
        setTimeout(() => {
            navigate(`/${roomId}`, { state: { nickname: nickname, isCreator: true } })
        }, timeouts.HOMEPAGE_TO_ROOM)
    }



    function createMatch() {

        get(child(ref(database), `matchesMeta`))
            .then((snapshot) => {
                if (!snapshot.exists()) return
                
                let roomsAlreadyTaken = Object.keys(snapshot.val())
                let roomIdList = shuffle(roomIds)
                let newRoomId = roomIdList.pop()

                while (roomsAlreadyTaken.includes(newRoomId ?? "")) {
                    if (roomIdList.length === 0) {
                        newRoomId = generateRandomRoomId(roomsAlreadyTaken)
                        break
                    }
                    newRoomId = roomIdList.pop()
                }

                if (newRoomId === undefined) return
                animateToRoom(newRoomId)
                matchContextValues.setRoomId!(newRoomId)

                update(ref(database,`players/${props.playerId}/`), {"room":newRoomId})
                const updates: any = {}
                updates[`matchesMeta/${newRoomId}`] = false
                updates[`matchesData/${newRoomId}/creator/${props.playerId}`] = true
                update(ref(database), updates)

                onDisconnect(ref(database, `matchesData/${newRoomId}/creator/${props.playerId}`)).remove()
                
            })
            .catch((error) => {
                console.error(error)
            })
    }


    


    function showGreetingAndNickname() {

        function showEditOrCharCount() {
            if (isNicknameBoxFocused) {
                return (
                    <div>
                        <p style={{fontSize:'1.2rem', display:'inline-flex', color:'var(--disabled)'}}>{nickname?.length}/8</p>
                    </div>
                )
            }

            return (
                <svg
                    style={{width:'1.7rem', height:'1.7rem', cursor:'pointer'}}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 23 21"
                    fill="none"
                >
                    <path style={{stroke:'#666666', strokeWidth:'1.5', strokeLinecap:'round', strokeLinejoin:'round'}} d="M15.6066 5.70711C15.9971 5.31658 16.6303 5.31658 17.0208 5.70711L17.7279 6.41421C18.1185 6.80474 18.1185 7.4379 17.7279 7.82843L9.5765 15.9799C9.35695 16.1994 9.0893 16.3648 8.79475 16.463L6.25041 17.3111C6.17223 17.3372 6.09786 17.2628 6.12392 17.1846L6.97203 14.6403C7.07022 14.3457 7.23563 14.0781 7.45518 13.8585L15.6066 5.70711Z"/>
                    <path style={{stroke:'#666666', strokeWidth:'1.5', strokeLinecap:'round', strokeLinejoin:'round'}} d="M14.1924 7.12134L16.3137 9.24266"/>
                </svg>
            )
        }

        return (
            <>
                <div
                    className="fmpHomepageGreeting-master--div"
                    style={{
                        opacity: nickname === null ? 0 : 1,
                        transform: nickname === null ? "translate(0px,2rem)" : "",
                        transition: "opacity 700ms ease-out, transform 700ms ease-out",
                        fontSize: isMobile ? "1.5rem" : "",
                        paddingTop: isMobile ? "1rem" : "0px",
                        fontWeight:'bold',
                        cursor:'default',
                    }}>
                        

                    <div style={{display:'flex', justifyContent:'center', alignItems:'center', columnGap:'1rem'}}>
                        <p>{greeting}</p>
                    
                        <div className="fmpHomepageGreeting-inputContainer--div" onClick={()=>nicknameInputRef.current?.focus()}>
                            <input
                                ref={nicknameInputRef}

                                onChange={(event) => {
                                    let newNickname = event.target.value
                                    newNickname = newNickname.slice(0, 8)
                                    if (!/^$|^[a-zA-Z]+$/.test(newNickname)) return
                                    setNickname(newNickname.toLocaleLowerCase())
                                }}

                                onKeyPress={e => {
                                    if(e.key === 'Enter') {
                                        e.preventDefault()
                                        nicknameInputRef.current?.blur()
                                    }
                                }}

                                value={nickname ?? ""}

                                onBlur={() => {
                                    if (nickname === "") setNickname(fallbackNickname!.toLocaleLowerCase())
                                    else if (isValidNickname(nickname!)) setFallbackNickname(nickname!.toLocaleLowerCase())
                                    else setNickname(fallbackNickname!.toLocaleLowerCase())

                                    setIsNicknameBoxFocused(false)
                                    window.scrollTo(0, 0)
                                }}

                                onFocus={() => {
                                    setIsNicknameBoxFocused(true)
                                    setNickname("")
                                }}

                                style={{width:`${nicknameInputWidth + 20}px`, cursor:'text'}}

                                autoComplete="new-password"
                                aria-autocomplete="list"
                                spellCheck="false"
                            />


                            {showEditOrCharCount()}

                        </div>
                    </div>

                    <div ref={nicknameInputSizerRef} style={{width:'fit-content', position:'absolute', pointerEvents:'none', opacity:0}}>{nickname===""? ".":nickname}</div>
                    

                </div>
            </>
        )
    }


    function showMessagePopup() {
        if (messagePopup === "" && homepageMessageText === "") return

        let messagePopupStyle:React.CSSProperties = {
            maxWidth: isMobile? '90%' : '50%',
            left: isMobile? '5%' : '25%',
            padding: isMobile? '0.75rem 1rem' : '1rem 1rem',
            backgroundColor:'var(--prompt)',
            color:'var(--promptText)',
            borderRadius:'10px',
            fontWeight:'bold',
            fontSize:'1.25rem',
            transform: messagePopupTransform,
            transition: "transform 600ms cubic-bezier(0.54, 0.01, 0.35, 1)",
            zIndex:20,
        }

        let parentDivStyle:React.CSSProperties = {
            position:'absolute',
            width:'100%',
            display:'flex',
            justifyContent:'center',
            top: isMobile? '0.75rem' : '1rem',
        }

        return (
            <div style={parentDivStyle}>
                <div style={messagePopupStyle}>
                    {messagePopup}{homepageMessageText}
                </div>
            </div>
        )
    }



    function handleTouchEnd(event:any) {
        setTouchDeltaX(0)
        let delta = event.changedTouches[0].clientX - touchStartX
        console.debug(`ENDING DELTA WAS: ${delta/swipeDeltaNeeded}`)

        if (Math.abs(delta) >= swipeDeltaNeeded) {
            if (delta < 0) {
                console.debug('NEXT >>')
                if (focusedCard === "fill") return
                setFocusedCard('fill')
            } 
            else {
                console.debug('<< PREV')
                if (focusedCard === "prompt") return
                setFocusedCard('prompt')
            }
        }
    }


    function fillAndPromptDiv() {

        let transformTransition = 'transform 800ms cubic-bezier(0.25, 0.04, 0.22, 1)'
        let opacityTransition = 'opacity 800ms ease-in-out'

        if (isMobile) {
            return (
                <div ref={fillAndPromptDivRef} style={{width:'calc(100% - 40px)', margin:'0px 50px'}}>
                    <div style={{borderRadius:'20px', backgroundColor:'var(--fill)', display:'flex', paddingBottom:'35px',marginBottom:'30px', flexDirection:'column', opacity:fillAndPromptDivVisible? 1:0 , transition:`${opacityTransition}`}}>
                        <h2 style={{fontSize:'2rem', margin:'20px 0px 0px 0px', opacity:fillAndPromptDivVisible? 1:0, transition:`${opacityTransition} 300ms`}}>these are fills</h2>
                        <div style={{display:'flex', justifyContent: 'center', marginTop:'30px'}}>
                            <div style={{position:'absolute', display:'flex'}}>
                                <div className="homepageFill" style={{width:'200px', height:'280px', transform:`translate(0px, ${fillAndPromptDivVisible? '5' : '45'}px) rotate(-5deg)`, boxShadow:'0px 0px 0px 12px var(--bg)', transition:`${transformTransition}`}}/>
                                <div className="homepageFill" style={{width:'200px', height:'280px',margin:'0px 0px 0px -50px', transform:`translate(0px, ${fillAndPromptDivVisible? '0' : '45'}px) rotate(5deg)`, boxShadow:'0px 0px 0px 12px var(--bg)', transition:`${transformTransition} 200ms`}}/>
                            </div>
                            <div className="homepageFill" style={{width:'200px', height:'280px', transform:`translate(0px, ${fillAndPromptDivVisible? '5' : '45'}px) rotate(-5deg)`, transition:`${transformTransition}`}}>
                                <p style={{padding:'20px', fontSize:'1.25rem', fontWeight:'bold', textAlign:'left'}}>stealing candy from babies</p>
                            </div>
                            <div className="homepageFill" style={{width:'200px', height:'280px', margin:'0px 0px 0px -50px', transform:`translate(0px, ${fillAndPromptDivVisible? '0' : '45'}px) rotate(5deg)`, transition:`${transformTransition} 200ms`}}>
                                <p style={{padding:'20px', fontSize:'1.25rem', fontWeight:'bold', textAlign:'left'}}>cosplaying as a green scottish ogre</p>
                            </div>
                        </div>
                    </div>


                    <div style={{borderRadius:'20px', backgroundColor:'var(--prompt)', display:'flex', paddingBottom:'35px',marginBottom:'30px',alignItems:'center', flexDirection:'column', opacity:fillAndPromptDivVisible? 1:0 , transition:`${opacityTransition} 300ms`}}>
                        <h2 style={{fontSize:'2rem', margin:'20px 0px 0px 0px', opacity:fillAndPromptDivVisible? 1:0, transition:`${opacityTransition} 500ms`}}>this is a prompt</h2>
                        <div style={{display:'flex', justifyContent: 'center', marginTop:'30px'}}>
                            <div className="homepagePrompt" style={{width:'200px', height:'280px',transform:`translate(0px,${fillAndPromptDivVisible? '0' : '60'}px) rotate(3deg)`, boxShadow:'0px 0px 0px 2px #272727, 0px 0px 0px 12px var(--bg)',transition:`${transformTransition} 400ms`}}>
                                <p style={{padding:'20px', fontSize:'1.25rem', fontWeight:'bold', textAlign:'left'}}>Grandma, for the last time would you stop _______</p>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        
        if (window.innerWidth > 1250) {
            return (
                <div ref={fillAndPromptDivRef} style={{display:'grid', gridTemplateColumns: 'repeat(5, 1fr)', width:'calc(100% - 100px)', maxWidth:'1400px', columnGap:'30px', margin:'0px 50px'}}>
                        <div style={{gridColumn:'span 3', borderRadius:'20px', backgroundColor:'var(--fill)', display:'flex', justifyContent: 'center', flexDirection:'column', opacity:fillAndPromptDivVisible? 1:0 , transition:`${opacityTransition}`}}>
                            <div style={{display:'flex', justifyContent: 'center'}}>
                                <div style={{position:'absolute', display:'flex'}}>
                                    <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-45' : '45'}px) rotate(-5deg)`, boxShadow:'0px 0px 0px 12px var(--bg)', transition:`${transformTransition}`}}/>
                                    <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-70' : '70'}px) rotate(-0deg)`, margin:'0px -50px', boxShadow:'0px 0px 0px 12px var(--bg)', transition:`${transformTransition} 100ms`}}/>
                                    <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-45' : '45'}px) rotate(5deg)`, boxShadow:'0px 0px 0px 12px var(--bg)', transition:`${transformTransition} 200ms`}}/>
                                </div>
                                <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-45' : '45'}px) rotate(-5deg)`, transition:`${transformTransition}`}}>
                                    <p style={{padding:'20px', fontSize:'1.5rem', fontWeight:'bold', textAlign:'left'}}>stealing candy from babies</p>
                                </div>
                                <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-70' : '70'}px) rotate(0deg)`, margin:'0px -50px', transition:`${transformTransition} 100ms`}}>
                                    <p style={{padding:'20px', fontSize:'1.5rem', fontWeight:'bold', textAlign:'left'}}>trying to mine bitcoin</p>
                                </div>
                                <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-45' : '45'}px) rotate(5deg)`, transition:`${transformTransition} 200ms`}}>
                                    <p style={{padding:'20px', fontSize:'1.5rem', fontWeight:'bold', textAlign:'left'}}>cosplaying as a green scottish ogre</p>
                                </div>
                            </div>
                            <h2 style={{fontSize:'40px', margin:'0px 0px 20px 0px', opacity:fillAndPromptDivVisible? 1:0, transition:`${opacityTransition} 300ms`}}>these are fills</h2>
                        </div>

                        <div style={{gridColumn:'span 2', borderRadius:'20px', backgroundColor:'var(--prompt)', display:'flex', justifyContent: 'center',alignItems:'center', flexDirection:'column', opacity:fillAndPromptDivVisible? 1:0 , transition:`${opacityTransition} 300ms`}}>
                            <div className="homepagePrompt" style={{transform:`translate(0px,${fillAndPromptDivVisible? '-60' : '60'}px) rotate(3deg)`, boxShadow:'0px 0px 0px 2px #272727, 0px 0px 0px 12px var(--bg)',transition:`${transformTransition} 400ms`}}>
                                <p style={{padding:'20px', fontSize:'1.5rem', fontWeight:'bold', textAlign:'left'}}>Grandma, for the last time would you stop _______</p>
                            </div>
                            <h2 style={{fontSize:'40px', margin:'0px 0px 20px 0px', opacity:fillAndPromptDivVisible? 1:0, transition:`${opacityTransition} 500ms`}}>this is a prompt</h2>
                        </div>

                    </div>
            )
        }

        return (
            <div ref={fillAndPromptDivRef} style={{display:'grid', gridTemplateColumns: 'repeat(5, 1fr)', width:'calc(100% - 100px)', maxWidth:'1400px', columnGap:'30px', margin:'0px 50px'}}>
                    <div style={{gridColumn:'span 3', borderRadius:'20px', backgroundColor:'var(--fill)', display:'flex', justifyContent: 'center', flexDirection:'column', opacity:fillAndPromptDivVisible? 1:0 , transition:`${opacityTransition}`}}>
                        <div style={{display:'flex', justifyContent: 'center'}}>
                            <div style={{position:'absolute', display:'flex'}}>
                                <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-70' : '70'}px) rotate(-5deg)`, marginRight:-50, boxShadow:'0px 0px 0px 12px var(--bg)', transition:`${transformTransition}`}}/>
                                {/* <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-70' : '70'}px) rotate(-0deg)`, margin:'0px -50px', boxShadow:'0px 0px 0px 12px var(--bg)', transition:`${transformTransition} 100ms`}}/> */}
                                <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-45' : '45'}px) rotate(5deg)`, boxShadow:'0px 0px 0px 12px var(--bg)', transition:`${transformTransition} 200ms`}}/>
                            </div>
                            <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-70' : '70'}px) rotate(-5deg)`,marginRight:-50, transition:`${transformTransition}`}}>
                                <p style={{padding:'20px', fontSize:'1.5rem', fontWeight:'bold', textAlign:'left'}}>stealing candy from babies</p>
                            </div>
                            {/* <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-70' : '70'}px) rotate(0deg)`, margin:'0px -50px', transition:`${transformTransition} 100ms`}}>
                                <p style={{padding:'20px', fontSize:'1.5rem', fontWeight:'bold', textAlign:'left'}}>trying to mine bitcoin</p>
                            </div> */}
                            <div className="homepageFill" style={{transform:`translate(0px, ${fillAndPromptDivVisible? '-45' : '45'}px) rotate(5deg)`, transition:`${transformTransition} 200ms`}}>
                                <p style={{padding:'20px', fontSize:'1.5rem', fontWeight:'bold', textAlign:'left'}}>cosplaying as a green scottish ogre</p>
                            </div>
                        </div>
                        <h2 style={{fontSize:'40px', margin:'0px 0px 20px 0px', opacity:fillAndPromptDivVisible? 1:0, transition:`${opacityTransition} 300ms`}}>these are fills</h2>
                    </div>

                    <div style={{gridColumn:'span 2', borderRadius:'20px', backgroundColor:'var(--prompt)', display:'flex', justifyContent: 'center',alignItems:'center', flexDirection:'column', opacity:fillAndPromptDivVisible? 1:0 , transition:`${opacityTransition} 300ms`}}>
                        <div className="homepagePrompt" style={{transform:`translate(0px,${fillAndPromptDivVisible? '-60' : '60'}px) rotate(3deg)`, boxShadow:'0px 0px 0px 2px #272727, 0px 0px 0px 12px var(--bg)',transition:`${transformTransition} 400ms`}}>
                            <p style={{padding:'20px', fontSize:'1.5rem', fontWeight:'bold', textAlign:'left'}}>Grandma, for the last time would you stop _______</p>
                        </div>
                        <h2 style={{fontSize:'40px', margin:'0px 0px 20px 0px', opacity:fillAndPromptDivVisible? 1:0, transition:`${opacityTransition} 500ms`}}>this is a prompt</h2>
                    </div>

                </div>
        )
    }

    function goalExplanationDiv() {
        let transformTransition = 'transform 1000ms cubic-bezier(0.25, 0.04, 0.22, 1)'
        let opacityTransition = 'opacity 500ms ease-in-out'

        if (isMobile) {
            return(
                <div ref={goalExplanationDivRef} style={{transform:`translate(0px,${goalExplanationDivVisible?0:50}px)`, backgroundColor:'transparent', width:'calc(100% - 40px)', marginTop:'50px', borderRadius:'20px', opacity:goalExplanationDivVisible?1:0, transition:`${transformTransition}, ${opacityTransition}`}}>
                    <h2 style={{fontSize:'1.5rem', padding:'0px 20px', margin:0}}>every prompt has a blank just <i>waiting</i> to be filled</h2>
                    <h3 style={{fontSize:'1.2rem', padding:'0px 20px', margin:'10px 0px 0px 0px', color:'#808080'}}>try it out below!</h3>
                </div>
            )
        }

        return (
            <div ref={goalExplanationDivRef} style={{transform:`translate(0px,${goalExplanationDivVisible?0:50}px)`, backgroundColor:'transparent', width:'calc(100% - 100px)', maxWidth:'1400px', marginTop:'100px', borderRadius:'20px', opacity:goalExplanationDivVisible?1:0, transition:`${transformTransition}, ${opacityTransition}`}}>
                <h2 style={{fontSize:'2.5rem', padding:'0px 20px', margin:0}}>every prompt has a blank just <i>waiting</i> to be filled</h2>
                <h3 style={{fontSize:'1.9rem', padding:'0px 20px', margin:'10px 0px 30px 0px', color:'#808080'}}>try choosing a fill to complete the prompt below!</h3>
            </div>
        )
    }

    function tryToFillDiv() {

        if (isMobile) {
            return (
                <div ref={tryToFillDivRef} style={{backgroundColor:'transparent', marginTop:'30px', height:'var(--cardHeight)', width:'100%'}}>
                    <HomepageExample fills={shuffledExampleData[exampleSetIndex].fills} prompt={shuffledExampleData[exampleSetIndex].prompt} shouldReveal={tryToFillDivVisible} setExampleSetIndex={setExampleSetIndex}/>
                </div>
            )
        }

        return (
            <div ref={tryToFillDivRef} style={{backgroundColor:'transparent', marginTop:'30px', height:'var(--cardHeight)', width:'calc(100% - 200px)',maxWidth:'1300px'}}>
                <HomepageExample fills={shuffledExampleData[exampleSetIndex].fills} prompt={shuffledExampleData[exampleSetIndex].prompt} shouldReveal={tryToFillDivVisible} setExampleSetIndex={setExampleSetIndex}/>
            </div>
        )
    }


    function handleThatIsAllClick() {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setTimeout(()=>{
            setFillAndPromptDivVisible(false)
            setGoalExplanationDivVisible(false)
            setTryToFillDivVisible(false)
            setThatIsAllDivVisible(false)
        },200)
    }


    function thatIsAllDiv() {

        if (isMobile) {
            return (
                <div ref={thatIsAllDivRef} style={{transform:`translate(0px,${thatIsAllDivVisible?0:50}px)`,opacity:thatIsAllDivVisible?1:0, backgroundColor:'var(--prompt)', width:'calc(100% - 40px)', marginTop:'200px', paddingBottom:'50px', borderRadius:'20px', transition:'transform 1000ms cubic-bezier(0.25, 0.04, 0.22, 1), opacity 500ms ease-in-out'}}>
                    <h3 style={{fontSize:'2rem', padding:'0px 20px'}}>i'm sure you can figure out the rest.</h3>
                    <button className="homepageButton" onClick={handleThatIsAllClick}>
                        i'm ready to play
                    </button>
                </div>
            )
        }


        return (
            <div ref={thatIsAllDivRef} style={{transform:`translate(0px,${thatIsAllDivVisible?0:50}px)`,opacity:thatIsAllDivVisible?1:0, backgroundColor:'var(--prompt)', width:'calc(100% - 100px)', maxWidth:'1400px', marginTop:'100px', paddingBottom:'50px', borderRadius:'20px', transition:'transform 1000ms cubic-bezier(0.25, 0.04, 0.22, 1), opacity 500ms ease-in-out'}}>
                <h3 style={{fontSize:'40px'}}>i'm sure you can figure out the rest.</h3>
                <button className="homepageButton" onClick={handleThatIsAllClick}>
                    i'm ready to play
                </button>
            </div>
        )
    }

    


    
    const homepageDivStyle:React.CSSProperties = {
        transform: `translate(0,${homepageDivTranslate}vh)`,
        transition: homepageDivTransition,
        display:'flex',
        flexDirection:'column',
        height:isMobile?`${mobileHeight}px`:'100vh',
        justifyContent:'center',
        minHeight: '-webkit-fill-available'
    }

    function returnTitleFontSize() {
        if (window.innerWidth > 1250) return "8rem"
        if (window.innerWidth > 480) return "6rem"
        return "2.5rem"
    }

    return (
        <>
            {showMessagePopup()}

            { !isMobile &&
                <header style={{position:'absolute', width:'100vw', display:'flex', justifyContent:'end', paddingTop:'10px', zIndex:1}}>
                    <div style={{padding:'0px 20px', fontSize:'1.2rem', fontWeight:'bold'}}>
                        <div className="homepageHeaderItem" style={{display:'flex', alignItems:'center'}}>
                            <svg style={{height:'1rem', paddingRight:'5px'}} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 127.14 96.36">
                                <path d="M107.7,8.07A105.15,105.15,0,0,0,81.47,0a72.06,72.06,0,0,0-3.36,6.83A97.68,97.68,0,0,0,49,6.83,72.37,72.37,0,0,0,45.64,0,105.89,105.89,0,0,0,19.39,8.09C2.79,32.65-1.71,56.6.54,80.21h0A105.73,105.73,0,0,0,32.71,96.36,77.7,77.7,0,0,0,39.6,85.25a68.42,68.42,0,0,1-10.85-5.18c.91-.66,1.8-1.34,2.66-2a75.57,75.57,0,0,0,64.32,0c.87.71,1.76,1.39,2.66,2a68.68,68.68,0,0,1-10.87,5.19,77,77,0,0,0,6.89,11.1A105.25,105.25,0,0,0,126.6,80.22h0C129.24,52.84,122.09,29.11,107.7,8.07ZM42.45,65.69C36.18,65.69,31,60,31,53s5-12.74,11.43-12.74S54,46,53.89,53,48.84,65.69,42.45,65.69Zm42.24,0C78.41,65.69,73.25,60,73.25,53s5-12.74,11.44-12.74S96.23,46,96.12,53,91.08,65.69,84.69,65.69Z"/>
                            </svg>
                            <a href="https://discord.gg/8waQ7waHju" target="_blank" rel="noreferrer">Discord</a>
                        </div>
                    </div>
                </header>
            }

            <div ref={homepageDivRef} style={homepageDivStyle}>
                <h1 style={{ fontSize:returnTitleFontSize() , margin:0, cursor:'default'}}>FILL MY PROMPT</h1>
                {showGreetingAndNickname()}
                <div style={{display:isMobile?'flex':'', justifyContent:isMobile?'center':''}}>
                    <div 
                        style={{overflow: "hidden", marginTop:isMobile? '1rem' : '10vh', height:isMobile ? '350px' : '400px', width:'250px'}}
                        onTouchStart={(event) => setTouchStartX(event.touches[0].clientX)}
                        onTouchMove={(event)=>{setTouchDeltaX(returnTouchMoveDelta(event, touchStartX, swipeDeltaNeeded))}}
                        onTouchEnd ={handleTouchEnd}
                    >
                        <HomepageCard
                            type={"fill"}
                            text={"create a match"}
                            handleCardClick={createMatch}
                            isJoinBoxFocused={isJoinBoxFocused}
                            isNicknameBoxFocused={false}
                            focusedCard={focusedCard}
                            setFocusedCard={setFocusedCard}
                            touchDeltaX={touchDeltaX}
                            setHomepageMessageText={setHomepageMessageText}
                        />
                        <HomepageCard
                            type={"prompt"}
                            text={"join a match"}
                            nickname={nickname??''}
                            setIsJoinBoxFocused={setIsJoinBoxFocused}
                            isNicknameBoxFocused={false}
                            focusedCard={focusedCard}
                            setFocusedCard={setFocusedCard}
                            touchDeltaX={touchDeltaX}
                            setHomepageMessageText={setHomepageMessageText}
                        />
                    </div>
                </div>
                <div style={{position:'absolute', width:'100%', bottom:'2rem', opacity:fillAndPromptDivVisible?0:1, transition:'opacity 200ms ease-in-out'}}>
                    <p style={{animationName:'resultsScrollHintAnimation',animationDelay:'2s', animationFillMode:'forwards', animationDuration:'2.5s', animationIterationCount:'infinite', fontSize:'1.5rem', fontWeight:'bold', color:'#404040', opacity:0}}>how do you play?</p>
                </div>
            </div>
            <div style={{display:'flex', flexDirection:'column', paddingTop:'100px', alignItems:'center'}}>

                {fillAndPromptDiv()}

                {goalExplanationDiv()}

                {tryToFillDiv()}

                {thatIsAllDiv()}

                <div style={{marginBottom:'100px'}} />


            </div>
        </>
    )
}

export default Homepage







