import html2canvas from "html2canvas";
import { useContext, useMemo, useRef, useState } from 'react';
import { matchContext } from "../../App";
import ToolTip from "../fill-my-prompt/ToolTip";
import { getRandomNumberAbsolute } from '../fill-my-prompt/utils';


interface ShareResultImageProps {
    prompt:string,
    fill:string,
}

export default function ShareResultImage(props:ShareResultImageProps) {

    const ref = useRef<HTMLDivElement>(null)
    let matchContextValues = useContext(matchContext)
    const promptRef = useRef<HTMLDivElement>(null);
    const [isHoveringCopy, setIsHoveringCopy] = useState<boolean>(false)
    const [wasClickedCopy, setWasClickedCopy] = useState<boolean>(false)
    const [isHoveringDownload, setIsHoveringDownload] = useState<boolean>(false)
    const [wasClickedDownload, setWasClickedDownload] = useState<boolean>(false)
    let promptRotation = useMemo(()=> {
        getRandomNumberAbsolute(0,0.5, true)
    },[])
    let fillRotation = useMemo(()=> {
        return getRandomNumberAbsolute(0,2, true)
    },[])

    let promptTextHeight = promptRef.current !== null ? promptRef.current!.clientHeight : 0
    let stackHeight = promptTextHeight + 350


    
    function onButtonClickCopy() {
        if (ref.current === null) return

        setWasClickedCopy(true)
        html2canvas(ref.current, {
            onclone:(cloned,clonedElement)=> {
                clonedElement.style.opacity = '1'
            }, backgroundColor:null
        })
            .then((canvas)=> {
                canvas.toBlob((blob)=>{
                    if (blob == null) return

                    const data = [new ClipboardItem({ [blob?.type]: blob })]
                    navigator.clipboard.write(data)

                    //* opens image in new tab since firefox doesn't support image to clipboard
                    // var image = new Image();
                    // image.src = URL.createObjectURL(blob)
                    // var w = window.open("");
                    // w?.document.write(image.outerHTML);
                })
            })
    }



    function onButtonClickDownload() {
        if (ref.current === null) return
        
        setWasClickedDownload(true)
        html2canvas(ref.current, {
            onclone:(cloned,clonedElement)=> {
                clonedElement.style.opacity = '1'
            }, backgroundColor:null, logging:true
        })
            .then((canvas)=> {
                let url = canvas.toDataURL()
                let link = document.createElement('a');
                link.download = 'fillmyprompt-cards.png';
                link.href = url;
                link.click();
            })
    }



    function handleMouseOverCopy() {
        setIsHoveringCopy(true)
    }



    function handleMouseOutCopy() {
        setIsHoveringCopy(false)
        if (wasClickedCopy) setWasClickedCopy(false)
    }
    


    function handleMouseOverDownload() {
        setIsHoveringDownload(true)
    }



    function handleMouseOutDownload() {
        setIsHoveringDownload(false)
        if (wasClickedDownload) setWasClickedDownload(false)
    }



    const fillStyle:React.CSSProperties = {
        width: '250px',
        height: '350px',
        border:'2px solid var(--fillBorder)',
        display:'flex',
        flexDirection:'column',
        justifyContent:'space-between',
        transform:`translate(0px,${promptTextHeight}px) rotate(${fillRotation}deg)`
    }



    const promptStyle:React.CSSProperties = {
        width: '250px',
        height: '350px',
        transform:`rotate(${promptRotation}deg)`
    }



    function card(type:string,text:string) {
        if (type==="fill" && promptRef.current === null) return

        return (
            <div
            className = {type === 'prompt' ? "FMP-card-master--div FMP-prompt-card" : "FMP-card-master--div"}
            style={type==="fill" ? fillStyle : promptStyle}
            >
                {type === "prompt"
                    ? <div ref={promptRef} className="FMP-card-textbox--div" style={{padding:20, margin:0}}>{text}</div>
                    : <>
                        <div className="FMP-card-textbox--div" style={{padding:20, margin:0}}>{text}</div>
                        <p style={{paddingBottom:'20px', color:'#404040', fontSize:'24px'}}>fill my prompt</p>
                    </>
                }
            </div>
        )
    }




    function showCopyButton() {
        if (matchContextValues.browserName === "Firefox") {
            return (
                <div className="settingsPanel-optionRow-ActionButton transparentBackgroundHover" style={{padding:0, display:'flex', justifyContent:'center', alignItems:'center', width:40, height:40, borderColor:'var(--disabled)', cursor:'auto'}} onMouseOver={handleMouseOverCopy} onMouseOut={handleMouseOutCopy}>
                        <svg style={{width:'100%', height:'100%', padding:5, stroke:'var(--disabled)'}} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none">
                            <path style={{stroke:'#404040', strokeWidth:'1', strokeLinecap:'round', strokeLinejoin:'round'}} d="M12 4.5V8C12 9.10457 12.8954 10 14 10H17.5M17.4142 9.41421L12.5858 4.58579C12.2107 4.21071 11.702 4 11.1716 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V10.8284C18 10.298 17.7893 9.78929 17.4142 9.41421Z" />
                        </svg>
                        {isHoveringCopy && <ToolTip
                            text={wasClickedCopy? "copied!" : "firefox doesn't support copying images to the clipboard"}
                            fontSize={'1rem'}
                            widthRems={wasClickedCopy? 6 : 16}
                            alignment='center'
                            position={{x:'0px',y:'30px'}}
                            />
                        }
                    </div>
            )
        }

        
        return (
            <div className="settingsPanel-optionRow-ActionButton" style={{padding:0, display:'flex', justifyContent:'center', alignItems:'center', width:40, height:40}} onMouseOver={handleMouseOverCopy} onMouseOut={handleMouseOutCopy} onClick={onButtonClickCopy}>
                    <svg style={{width:'100%', height:'100%', padding:5, stroke:'white'}} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none">
                        <path style={{stroke:'white', strokeWidth:'1', strokeLinecap:'round', strokeLinejoin:'round'}} d="M12 4.5V8C12 9.10457 12.8954 10 14 10H17.5M17.4142 9.41421L12.5858 4.58579C12.2107 4.21071 11.702 4 11.1716 4H8C6.89543 4 6 4.89543 6 6V18C6 19.1046 6.89543 20 8 20H16C17.1046 20 18 19.1046 18 18V10.8284C18 10.298 17.7893 9.78929 17.4142 9.41421Z" />
                    </svg>
                    {isHoveringCopy && <ToolTip
                        text={wasClickedCopy? "copied!" : "copy cards to clipboard"}
                        fontSize={'1rem'}
                        widthRems={wasClickedCopy? 6 : 8}
                        alignment='center'
                        position={{x:'0px',y:'30px'}}
                        />
                    }
                </div>
        )
    }



    return (
        <>
            <div ref={ref} style={{backgroundColor:'transparent', width:'300px', height:stackHeight + 15, opacity:0., position:'absolute', pointerEvents:'none'}}>
                <div style={{display:'flex', justifyContent:'center', alignItems:'center', flexDirection:'column', rowGap:'1rem', height:stackHeight + 15}}>
                    <div style={{width:250, height:stackHeight, position:'relative'}}>
                        {card("prompt", props.prompt)}
                        {card("fill", props.fill)}
                    </div>
                </div>
            </div>

            <div style={{width:'250px', position:'absolute', display:'flex', justifyContent:'space-evenly'}}>
                {showCopyButton()}

                <div className="settingsPanel-optionRow-ActionButton" style={{padding:0, display:'flex', justifyContent:'center', alignItems:'center', width:40, height:40}} onMouseOver={handleMouseOverDownload} onMouseOut={handleMouseOutDownload} onClick={onButtonClickDownload}>
                    <svg style={{width:'100%', height:'100%', padding:5, stroke:'white', transform:'translate(0px,-2px)'}} xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 24 24" fill="none">
                        <path style={{stroke:'white', strokeWidth:'1', strokeLinecap:'round', strokeLinejoin:'round'}} xmlns="http://www.w3.org/2000/svg" d="M12 3.5V16M12 16L15.5 12.5M12 16L8.5 12.5"/>
                        <path style={{stroke:'white', strokeWidth:'1', strokeLinecap:'round', strokeLinejoin:'round'}} xmlns="http://www.w3.org/2000/svg" d="M15.5 9H17.5C18.6046 9 19.5 9.89543 19.5 11V19.5C19.5 20.6046 18.6046 21.5 17.5 21.5H6.5C5.39543 21.5 4.5 20.6046 4.5 19.5V11C4.5 9.89543 5.39543 9 6.5 9H8.5"/>
                    </svg>
                    {isHoveringDownload && <ToolTip
                        text={wasClickedDownload? "done!" : "download image of cards"}
                        fontSize={'1rem'}
                        widthRems={wasClickedDownload? 5 : 9}
                        alignment='center'
                        position={{x:'0px',y:'30px'}}
                        />
                    }
                </div>
            </div>
        </>
    )
}