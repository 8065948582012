import { useEffect, useState } from "react";
import { getRandomNumberAbsolute, returnTouchMoveDelta } from "../components/fill-my-prompt/utils";
import HomepageExampleCard from "./HomepageExampleCard";
import { isMobile } from 'react-device-detect';
import HomepageExampleCardMobile from "./HomepageExampleCardMobile";


interface HomepageExampleProps{
    fills:string[],
    prompt:string,
    shouldReveal:boolean,
    setExampleSetIndex:React.Dispatch<React.SetStateAction<number>>
}

export default function HomepageExample(props:HomepageExampleProps) {
    // const scaleMultiplier = window.innerWidth > 1200 ? 1 : 0.8
    const [scaleMultiplier, setScaleMultiplier] = useState<number>(window.innerWidth > 1250 ? 1 : 0.8)
    const cardWidth = 250 * scaleMultiplier
    const [startRotations, setStartRotations] = useState<number[]>([getRandomNumberAbsolute(1,4,true),getRandomNumberAbsolute(1,4,true),getRandomNumberAbsolute(1,4,true),getRandomNumberAbsolute(1,4,true)])
    const [selectedFillIndex, setSelectedFillIndex] = useState<number|undefined>(undefined)
    const [workableSpace, setWorkableSpace] = useState<number>(window.innerWidth>1500 ? 1400 : window.innerWidth - 100)
    const [fillIndicies, setFillIndicies] = useState<number[]>(props.fills.map((fill,index)=>index))
    const [focusedIndex, setFocusedIndex] = useState<number|undefined>(0)
    const [touchStartX, setTouchStartX] = useState<number>(0)
    const [touchDeltaX, setTouchDeltaX] = useState<number>(0)
    const swipeDeltaNeeded = 50


    useEffect(()=> {
        let checkWorkableSpace = window.innerWidth>1500 ? 1300 : window.innerWidth - 200
        if (workableSpace !== checkWorkableSpace) {
            setWorkableSpace(checkWorkableSpace)
        }

        if (window.innerWidth > 1250) {
            setScaleMultiplier(1)
        }
        else {
            setScaleMultiplier(0.8)
        }
    },[window.innerWidth])


    useEffect(()=> {
        if (selectedFillIndex === undefined) return
        setFocusedIndex(undefined)
        setTimeout(()=> {
            setSelectedFillIndex(undefined)
            props.setExampleSetIndex((prevIndex) => {
                console.log('pop[')
                if (prevIndex === 2) return 0
                else return prevIndex+1
            })
            setFocusedIndex(0)
        },isMobile?1600:2800)
    },[selectedFillIndex])


    function handleTouchEnd(event:any) {
        setTouchDeltaX(0)
        let delta = event.changedTouches[0].clientX - touchStartX
        if (Math.abs(delta) >= swipeDeltaNeeded) {
            if (delta < 0) {
                console.debug('NEXT >>')
                setFocusedIndex(prev => {
                    if (prev === undefined) return 0
                    if (prev === 2) return prev
                    return prev+1
                })
            }
            else {
                console.debug('<< PREV')
                setFocusedIndex(prev => {
                    if (prev === undefined) return 0
                    if (prev === 0) return prev
                    return prev-1
                })
            }
        }
    }
   

    if (isMobile) {
        return (
            <div>
                {[props.prompt].map(()=> {
                    return (
                        <HomepageExampleCardMobile
                            key={props.prompt}
                            type='prompt'
                            text={props.prompt}
                            index={4}
                            focusedIndex={focusedIndex}
                            startX={window.innerWidth/2 - (250/2)}
                            startY={0}
                            startRot={startRotations[3]}
                            shouldReveal={props.shouldReveal}
                            selectedFillIndex={selectedFillIndex}
                        />

                    )
                })}


                <div
                    onTouchStart={(event) => setTouchStartX(event.touches[0].clientX)}
                    onTouchMove={(event)=>setTouchDeltaX(returnTouchMoveDelta(event, touchStartX, swipeDeltaNeeded))}
                    onTouchEnd ={handleTouchEnd}
                >
                   
                    <HomepageExampleCardMobile
                        type='fill'
                        text={props.fills[0]}
                        index={0}
                        focusedIndex={focusedIndex}
                        startX={0}
                        startY={0}
                        startRot={startRotations[0]}
                        shouldReveal={props.shouldReveal}
                        touchDeltaX={touchDeltaX}
                        selectedFillIndex={selectedFillIndex}
                        setSelectedFillIndex={setSelectedFillIndex}
                    />
                    <HomepageExampleCardMobile
                        type='fill'
                        text={props.fills[1]}
                        index={1}
                        focusedIndex={focusedIndex}
                        startX={1*250}
                        startY={0}
                        startRot={startRotations[1]}
                        shouldReveal={props.shouldReveal}
                        touchDeltaX={touchDeltaX}
                        selectedFillIndex={selectedFillIndex}
                        setSelectedFillIndex={setSelectedFillIndex}
                    />
                    <HomepageExampleCardMobile
                        type='fill'
                        text={props.fills[2]}
                        index={2}
                        focusedIndex={focusedIndex}
                        startX={2*250}
                        startY={0}
                        startRot={startRotations[2]}
                        shouldReveal={props.shouldReveal}
                        touchDeltaX={touchDeltaX}
                        selectedFillIndex={selectedFillIndex}
                        setSelectedFillIndex={setSelectedFillIndex}
                    />
                </div>
            </div>
        )
    }



    return (
        <div>
            {props.fills.map((fillText, index)=> {
                return (
                    <HomepageExampleCard
                        key={fillText}
                        type='fill'
                        text={fillText}
                        index={index}
                        startX={index*cardWidth}
                        startY={0}
                        startRot={startRotations[index]}
                        selectedFillIndex={selectedFillIndex}
                        workableSpace={workableSpace}
                        shouldReveal={props.shouldReveal}
                        fillIndicies={fillIndicies}
                        setSelectedFillIndex={setSelectedFillIndex}
                    />
                )
            })}

            {[props.prompt].map(()=> {
                return (
                    <HomepageExampleCard
                        key={props.prompt}
                        type='prompt'
                        text={props.prompt}
                        index={0}
                        startX={selectedFillIndex===undefined? workableSpace-(cardWidth): workableSpace-(cardWidth*2)}
                        startY={0}
                        startRot={startRotations[3]}
                        selectedFillIndex={selectedFillIndex}
                        workableSpace={workableSpace}
                        shouldReveal={props.shouldReveal}
                    />

                )
            })}

        </div>
    )
}