import React, {useEffect, useState, createContext, useRef} from 'react';
import './App.css';
import Homepage from './Homepage/Homepage'
import {Routes, Route } from "react-router-dom";
import {analytics, auth} from './firebase-connect'
import {signInAnonymously, onAuthStateChanged } from "firebase/auth";
import MatchRoomGatekeeper from './components/MatchRoom/MatchRoomGatekeeper'
import {isMobile, browserName} from 'react-device-detect';
import { themes } from './components/fill-my-prompt/config';
import Header from './components/fill-my-prompt/Header';
import { useWindowDimension } from './components/fill-my-prompt/utils';
import Results from './components/Results/Results';
import { logEvent } from 'firebase/analytics';
import ResultsMobile from './components/Results/ResultsMobile';


export const windowContext = createContext({
  windowWidth: window.innerWidth,
  windowHeight: window.innerHeight
});

export interface matchContextInterface{
  roomId?:string,
  browserName?:string,
  setRoomId?: React.Dispatch<React.SetStateAction<string>>,
  isInMatch?:boolean,
  setIsInMatch?: React.Dispatch<React.SetStateAction<boolean>>,
  cardsInHandButtonPressed?:boolean,
  setCardsInHandButtonPressed?: React.Dispatch<React.SetStateAction<boolean>>,
  shouldDoHomeWipe?:homeWipe,
  setShouldDoHomeWipe?: React.Dispatch<React.SetStateAction<homeWipe>>,
  windowDimension?: number[]
}

export const matchContext = createContext<matchContextInterface>({})

interface homeWipe{
  shouldWipe:boolean,
  reason:string
}


function App() {

  const [playerId, setPlayerId] = useState<string>('')
  const [isInMatch, setIsInMatch] = useState<boolean>(false)
  const [roomId, setRoomId] = useState<string>('')
  const [chosenTheme, setChosenTheme] = useState<string>('classic')
  const [cardsInHandButtonPressed, setCardsInHandButtonPressed] = useState<boolean>(isMobile? true: false)
  const [shouldDoHomeWipe, setShouldDoHomeWipe] = useState<homeWipe>({shouldWipe:false, reason:""})
  const windowDimension = useWindowDimension()

  
  useEffect(()=>{
    if (playerId === '') {
      signInAnonymously(auth).catch(error => {
        console.log(error)
      });
    
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log(user)
          setPlayerId(user.uid)
        } else {
          console.log('NO')
          // User is signed out
          // ...
        }
         
      });
    }

    
    


  },[])



  
 
  
  





  let votes:any = {
    0:{
      "XWMyiCKDoFh6clqBOrladtE1mn74": 2,
      "TDXObJyr08c0Ge6DWn3KL07719y1":2,
      "XWMyiCKDoFh6clqBOrladtE1mn73": 2
    },
    1:{
      "XWMyiCKDoFh6clqBOrladtE1mn74": 1,
      "TDXObJyr08c0Ge6DWn3KL07719y1":1,
      "XWMyiCKDoFh6clqBOrladtE1mn73": 1
    },
    2:{
      "XWMyiCKDoFh6clqBOrladtE1mn74": 1,
      "TDXObJyr08c0Ge6DWn3KL07719y1":1,
      "XWMyiCKDoFh6clqBOrladtE1mn73": 1
    },
    3:{
      "XWMyiCKDoFh6clqBOrladtE1mn74": 1,
      "TDXObJyr08c0Ge6DWn3KL07719y1":1,
      "XWMyiCKDoFh6clqBOrladtE1mn73": 1
    },
    4:{
      "XWMyiCKDoFh6clqBOrladtE1mn74": 1,
      "TDXObJyr08c0Ge6DWn3KL07719y1":1,
      "XWMyiCKDoFh6clqBOrladtE1mn73": 1
    },
  }

  let players = {
    "TDXObJyr08c0Ge6DWn3KL07719y1": {
      "fills": [
        [
          "tony stark's left nut",
          "your mom",
          "fuck you, kid"
        ],
        [
          "green scottish ogres",
          "snorting mysterious powders off of restroom floors",
          "a glock"
        ],
        [
          "when the fire nation attacked",
          "bill nye's chest pubes",
          "pointing and laughing at a beached whale"
        ]
      ],
      "submittedFills": [
        [
          "tony stark's left nut"
        ],
        [
          "snorting mysterious powders off of restroom floors"
        ],
        [
          "bill nye's chest pubes"
        ],
        [
          "ambos are cars"
        ],
        [
          "justin bieber"
        ]
      ]
    },
    "XWMyiCKDoFh6clqBOrladtE1mn73": {
      "fills": [
        [
          "literally take candy from a baby",
          "michael scott's juicy ass",
          "harry potter"
        ],
        [
          "boiling my enemies alive to make a nice stew",
          "horse cock",
          "john cena"
        ],
        [
          "picking your nose so hard you find some brain",
          "wopping horse cock",
          "big ol dildo"
        ]
      ],
      "nickname": "creepy sempai",
      "submittedFills": [
        [
          "michael scott's juicy ass",
          "test"
        ],
        [
          "horse cock"
        ],
        [
          "wopping horse cock"
        ],
        [
          "harry potter"
        ],
        [
          "womping willow"
        ]
      ]
    },
    "XWMyiCKDoFh6clqBOrladtE1mn74": {
      "fills": [
        [
          "literally take candy from a baby",
          "michael scott's juicy ass",
          "harry potter"
        ],
        [
          "boiling my enemies alive to make a nice stew",
          "horse cock",
          "john cena"
        ],
        [
          "picking your nose so hard you find some brain",
          "wopping horse cock",
          "big ol dildo"
        ]
      ],
      "nickname": "creepy sempai",
      "submittedFills": [
        [
          "michael scott's juicy ass"
        ],
        [
          "horse cock"
        ],
        [
          "wopping horse cock"
        ],
        [
          "harry potter"
        ],
        [
          "womping willow"
        ]
      ]
    }
  }

  let playerNames = {
    "TDXObJyr08c0Ge6DWn3KL07719y1": {
      "nickname": "meaty sploot"
    },
    "XWMyiCKDoFh6clqBOrladtE1mn73": {
      "nickname": "stinky freak"
    },
    "XWMyiCKDoFh6clqBOrladtE1mn74": {
      "nickname": "huge porg"
    }
  }

  let prompts = [
    {
      "blanks": 1,
      "prompt": "santa would be a lot cooler if he just started ________"
    },
    {
      "blanks": 1,
      "prompt": "witches hate water, but wizards hate ________"
    },
    {
      "blanks": 1,
      "prompt": "Tom Cruise REALLY hates ________"
    },
    {
      "blanks": 1,
      "prompt": "shoop de doop doop? ________"
    },
    {
      "blanks": 1,
      "prompt": "brool dool mcgool? ________"
    },
  ]

  let otherPlayersProgress = {
    'temp':100
  }

  let playersInRoom ={
    "temp": {
      "nickname":"kabir"
    }
  }
  

  function loadWhenSignedIn(){

    if (!playerId) return
    return (
      <>
        <Header />

        <Routes>
          <Route path="/" element={<Homepage playerId={playerId} setChosenTheme={setChosenTheme}/>} />
          <Route path="/:roomId" element={<MatchRoomGatekeeper playerId={playerId}/>} />
        </Routes>
      </>
      
    )
  }
  
  

  return (
    <div className="App">
      {/* <div className='bg'></div> */}
      
      <matchContext.Provider value={{browserName, roomId, setRoomId, isInMatch, setIsInMatch, cardsInHandButtonPressed, setCardsInHandButtonPressed, shouldDoHomeWipe, setShouldDoHomeWipe, windowDimension}}>

        {loadWhenSignedIn()}
        
        {/* <Header /> */}
        {/* <RoundInfoText roundNumber={9} matchRoundCount={3} canStartVoting={true} canStartResults={true} matchTimeLimit={0} forceNextRound={()=>{}} otherPlayersProgress={otherPlayersProgress} playersInRoom={playersInRoom} playerId={playerId}/> */}
        {/* <Results votes={votes} players={players} playerNames={playerNames} playerId={"XWMyiCKDoFh6clqBOrladtE1mn73"} prompts={prompts}/> */}

        {/* <ResultsMobile votes={votes} players={players} playerNames={playerNames} playerId={"XWMyiCKDoFh6clqBOrladtE1mn73"} prompts={prompts}/> */}
      </matchContext.Provider>

    </div>
  );
}

export default App;