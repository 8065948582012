import { child, get, ref } from "firebase/database";
import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { isOnlyLetters} from "../components/fill-my-prompt/utils";
import { database } from "../firebase-connect";

interface HomepageCardProps{
    text:string,
    handleCardClick?:()=> void,
    isMatchJoinCardOut?:boolean
    nickname?:string,
    type:string,
    setIsJoinBoxFocused?:any,
    isJoinBoxFocused?:boolean,
    isNicknameBoxFocused:boolean,
    focusedCard:string,
    setFocusedCard:Dispatch<SetStateAction<string>>,
    touchDeltaX:number,
    setHomepageMessageText: Dispatch<SetStateAction<string>>
}

export default function HomepageCard(props:HomepageCardProps){

    const navigate = useNavigate()
    const [transitionValue, setTransitionValue] = useState<string>(`transform 1000ms cubic-bezier(0.13, 0.38, 0.2, 1), top 1000ms cubic-bezier(0.13, 0.38, 0.2, 1)`)
    const [canSlideIn, setCanSlideIn] = useState<boolean>(false)
    const [isHovering, setIsHovering] = useState<boolean>(false)
    const [isFinishedSlidingIn, setIsFinishedSlidingIn] = useState<boolean>(false)
    const [inputState, setInputState] = useState<string>('')
    const [xOffset, setXOffset] = useState<number>(window.innerWidth/2 - 250/2)
    const matchInput = useRef<any>(undefined)
    const [isTapped, setIsTapped] = useState<boolean>(false)
    const [hintTextOpacity, setHintTextOpacity] = useState<number>(0)
    const renderCounter  = useRef(0);
    renderCounter.current = renderCounter.current + 1;

    console.debug(`> HomepageCard ${props.type} Rendered:`, renderCounter.current)

    let slideInTimer = Math.floor(Math.random() * (400 - 50 + 1) + 50)
    let finalRotation = props.type === 'fill' ? 5 : -5
    let topOffset = props.type === 'fill' ? 20 : 0
    
    if (isMobile) topOffset = 0


    useEffect(()=>{
        setTimeout(()=>{
            setCanSlideIn(true)
            setTimeout(()=>{
                setIsFinishedSlidingIn(true)
                setTransitionValue(`transform 250ms cubic-bezier(0.13, 0.38, 0.2, 1), box-shadow 200ms cubic-bezier(0.13, 0.38, 0.2, 1), opacity 250ms ease-in-out`)
            }, 1000)
        },slideInTimer)

        if (!isMobile){
            if (props.type === "fill") setXOffset(window.innerWidth/2 - 250/2 + 250)
            if (props.type === "prompt") setXOffset(window.innerWidth/2 - 250/2 - 250)
        }
        else {
            if (props.type === "fill") setXOffset(20)
            if (props.type === "prompt") setXOffset(-20)
        }
    },[])


    useEffect(()=>{
        if (isMobile) return

        let tempXOffset = window.innerWidth/2 - 250/2
        if (props.type === 'prompt'){tempXOffset += -250}
        else if (props.type === 'fill'){tempXOffset += 250}
        setXOffset(tempXOffset)
    },[window.innerWidth])


    useEffect(()=>{
        if (!isMobile) return
        if (props.isNicknameBoxFocused) setCanSlideIn(false)
        else {
            setTransitionValue(`transform 1000ms cubic-bezier(0.13, 0.38, 0.2, 1), top 1000ms cubic-bezier(0.13, 0.38, 0.2, 1), opacity 250ms ease-in-out`)
            setTimeout(()=>{
                setCanSlideIn(true)
                setTimeout(()=>setTransitionValue(`transform 250ms cubic-bezier(0.13, 0.38, 0.2, 1), box-shadow 200ms cubic-bezier(0.13, 0.38, 0.2, 1), opacity 250ms ease-in-out`),1000)
            },100)
        }

    },[props.isNicknameBoxFocused])


    function handleMOver() {
        if (!isMobile) setIsHovering(true)
        if (props.type === "prompt") setHintTextOpacity(0.2)
    }


    function handleMOut() {
        if (isHovering) setIsHovering(false)
        if (props.type === "prompt") setHintTextOpacity(0)
    }


    function returnBoxShadowValue() {
        let border = ''
        if (props.type === "fill") border = '0 0 0 2px var(--fillBorder)'
        else border = '0 0 0 2px #272727'
        if (isHovering) return `${border}, 0px 5px 5px 0px rgb(0 0 0 /15%),0px 10px 10px 0px rgb(0 0 0 /15%),0px 30px 30px 0px rgb(0 0 0 /15%)`

        return border
    }
    

    function returnPointerEventValue() {
        if (!isFinishedSlidingIn) return "none"

        if (isMobile){
            if (props.type === props.focusedCard) return 'auto'
            return 'none'
        }
        return "auto"
    }


   
    function handleInputChange(e: any) {
        let rawInputValue: string = e.target.value.toUpperCase()
        let inputValue: string = rawInputValue.slice(0, 4)
        if (rawInputValue.length > 4 && rawInputValue !== inputValue) return

        if (inputValue.length === 4) {
            console.debug(`try to join ${inputValue}`)
            if (isOnlyLetters(inputValue)) {
                get(child(ref(database), `matchesMeta/${inputValue}`))
                    .then((snapshot) => {
                        if (!snapshot.exists()) {
                            console.debug("room isnt real")
                            props.setHomepageMessageText(`no room with id ${inputValue}`)
                            return
                        }

                        
                        if (snapshot.val() === true) {
                            props.setHomepageMessageText(`match is either full or already in progress`)
                            return
                        }

                        navigate(`/${inputValue}`, { state: { nickname: props.nickname ?? "", isCreator: false } })
                        
                    })
                    .catch((error) => {
                        console.error(error)
                    })
            }
        }

        setInputState(inputValue)
    }


    function returnTranslateValue() {
        
        if (canSlideIn) {
            if (props.focusedCard === props.type) return `translate(${xOffset + props.touchDeltaX}px,${topOffset}px)`
            return `translate(${xOffset}px,${topOffset}px)`
        }
        
        return `translate(${xOffset}px,${window.innerHeight + 50}px)`
    }


    function returnRotationValue(){
        if (isHovering) return `rotate(0deg) scale(1.02)`
        else if (canSlideIn){
            if (isMobile && props.focusedCard === props.type) return `rotate(${0}deg) `
            return `rotate(${finalRotation}deg)`
        }
        return `rotate(${finalRotation*3}deg)`
    }


    function returnScaleValue(){
        if (isMobile){
            if (props.focusedCard !== props.type) return 'scale(0.7)'
            if (isTapped) return 'scale(0.7)'
            return'scale(0.8)'
        }
        return ''
    }


    function returnCardTextColor(){
        if (!isMobile) return ''
        if (props.focusedCard !== props.type) return 'var(--disabled)'
        return ''
    }


    function returnCardOpacity(){
        if (props.isJoinBoxFocused??false) return 0
        if (props.isNicknameBoxFocused && isMobile) return 0
        if (!canSlideIn) return 0
        return 1
    }


    return (
        <>
            <div
                className={props.type === "fill" ? "FMP-homepage-card-master--div" : "FMP-homepage-card-master--div FMP-prompt-card"}
                style={{
                    transform: `${returnTranslateValue()} ${returnRotationValue()} ${returnScaleValue()}`,
                    transition: transitionValue,
                    width: 'var(--cardWidth)',
                    height:'var(--cardHeight)',
                    pointerEvents: returnPointerEventValue(),
                    boxShadow: returnBoxShadowValue(),
                    zIndex: props.type === props.focusedCard ? 2 : 0,
                    opacity: returnCardOpacity(),
                    position:'absolute',
                    display:'flex',
                    flexDirection:'column',
                    justifyContent: 'space-between',  
                }}
                onMouseOver={handleMOver}
                onMouseOut={handleMOut}
                onClick={()=>{
                    if (!props.handleCardClick) return
                    
                    if (window.scrollY > 0) {
                        window.scrollTo({ top: 0, behavior: 'smooth' })
                        setTimeout(()=>{
                            props.handleCardClick!()
                            setIsTapped(true)
                        },300)
                    } else {
                        props.handleCardClick()
                        setIsTapped(true)
                    }
                    
                }}
            >
                <div className="FMP-card-textbox--div homepageCard" style={{ fontSize: "1.6rem", color:returnCardTextColor(), transition: 'color 250ms cubic-bezier(0.13, 0.38, 0.2, 1)', margin:'0px', padding:'20px'}}>
                    {props.type === "fill"
                        ? props.text 
                        :<>
                            <p>{props.text}</p>{" "}
                            <input
                                style={{
                                    borderBottom:isMobile && props.focusedCard !== props.type ? '2px var(--disabled) solid' : '',
                                    transition:'border-bottom 250ms cubic-bezier(0.13, 0.38, 0.2, 1)',
                                    pointerEvents: 'auto',
                                    borderColor: isHovering? 'white':''
                                    
                                }}
                                ref={matchInput}
                                className="fmpHomepageJoinMatch--input"
                                onChange={handleInputChange}
                                value={inputState}
                                placeholder="????"
                                autoComplete="new-password"
                                aria-autocomplete="list"
                                onFocus={()=>{
                                    if (!isMobile) return
                                    props.setIsJoinBoxFocused!(true)
                                }}
                                onBlur={()=>{
                                    if (!isMobile) return
                                    props.setIsJoinBoxFocused!(false)
                                    window.scrollTo(0, 0)
                                }}
                            />
                        </>
                    }
                </div>

                {props.type === "prompt" && <div>
                    <p style={{fontSize: "1.2rem", padding:20, opacity:hintTextOpacity, transition:'opacity 200ms ease-in-out'}}> enter the room id to join a match </p>
                </div>}

            </div>
        </>
    )
}


