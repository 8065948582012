import { useEffect, useState } from "react"
import { getRandomNumberAbsolute } from "../components/fill-my-prompt/utils"

interface HomepageExampleCardMobileProps{
    type:string,
    text:string,
    index:number,
    focusedIndex:number|undefined,
    startX:number,
    startY:number,
    startRot:number,
    shouldReveal:boolean,
    touchDeltaX?:number,
    selectedFillIndex:number|undefined,
    setSelectedFillIndex?:React.Dispatch<React.SetStateAction<number | undefined>>,
}

export default function HomepageExampleCardMobile(props:HomepageExampleCardMobileProps) {

    const [translate, setTranslate] = useState<string>(props.type === "prompt"? `translate(${-window.innerWidth}px,0px)` : `translate(${-window.innerWidth}px,150px)`)
    const [rotate, setRotate] = useState<string>(`rotate(${props.startRot}deg)`)
    const [scale, setScale] = useState<string>(props.focusedIndex === props.index? 'scale(0.9)' : 'scale(0.8)')
    const [transition, setTransition] = useState<string>(`transform 1000ms cubic-bezier(0.68, 0, 0.22, 1)`)
    const [isAnimatedOn, setIsAnimatedOn] = useState<boolean>(false)
    const [zIndex, setZIndex] = useState<number>(0)
    const [pointerEvents, setPointerEvents] = useState<boolean>(false)
    const cardOffset = 30



    useEffect(()=> {
        if (!props.shouldReveal) return

        setTimeout(()=> {
            if (props.type === "prompt") setTranslate(`translate(${window.innerWidth/2 - (250/2)}px,0px)`)
            else updateCardState()

            setTimeout(() => {
                setIsAnimatedOn(true)
                setPointerEvents(true)
                if (props.type === "fill") setTransition(`transform 250ms cubic-bezier(0.25, 0.04, 0.22, 1)`)
            },1000)
            
        }, getRandomNumberAbsolute(0,200))

    },[props.shouldReveal])



    useEffect(()=> {
        if (!isAnimatedOn) return
        if (props.type === "prompt") return
        if (props.focusedIndex === undefined) return

        updateCardState()
    },[props.focusedIndex])



    useEffect(()=> {
        if (props.index !== props.focusedIndex) return
        if (!isAnimatedOn) return

        setTranslate(`translate(${window.innerWidth/2 - (250/2) + props.touchDeltaX!}px,150px)`)
    },[props.touchDeltaX])



    useEffect(()=> {
        if (!props.shouldReveal) return
        if (!isAnimatedOn) return

        if (props.selectedFillIndex !== undefined) {
            setPointerEvents(false)

            setTimeout(()=> {
                setTransition(`transform 1000ms cubic-bezier(0.68, 0, 0.22, 1)`)
                if (props.type==="prompt") setTranslate(`translate(${window.innerWidth+props.startX}px,0px)`)
                else {
                    setTranslate(()=> {
                        if (props.selectedFillIndex === props.index) return `translate(${window.innerWidth + 200}px,0px)`
                        else return `translate(${window.innerWidth + 200}px,150px)`
                    })
                }
            },1000)

            if (props.type === "fill") {
                setTimeout(()=> {
                    setTransition(`transform 0ms cubic-bezier(0.68, 0, 0.22, 1)`)
                    setTranslate(`translate(${-window.innerWidth}px,150px)`)
                },1500) 

                setTimeout(()=> {
                    setTransition(`transform 1000ms cubic-bezier(0.68, 0, 0.22, 1)`)
                },1550)

                setTimeout(()=> {
                    setTransition(`transform 250ms cubic-bezier(0.25, 0.04, 0.22, 1)`)
                    setPointerEvents(true)
                },2650) 
            }
        }

        if (props.type === "prompt") {
            setTransition(`transform 450ms cubic-bezier(0.29, 0, 0, 1)`)
            setTranslate(`translate(${window.innerWidth/2 - (250/2) - (200/2)}px,0px)`)
        }

        if (props.selectedFillIndex !== props.index) return

        setTransition(`transform 450ms cubic-bezier(0.29, 0, 0, 1)`)
        setTranslate(`translate(${window.innerWidth/2 - (250/2) + (200/2)}px,0px)`)
        setScale('scale(0.8)')
        setZIndex(0)

    },[props.selectedFillIndex])



    function updateCardState() {
        setScale(props.focusedIndex === props.index? 'scale(0.9)' : 'scale(0.8)')
        setTranslate(()=> {
            if (props.index < props.focusedIndex!) return `translate(${window.innerWidth/2 - (250/2) + -cardOffset}px,150px)`
            if (props.index > props.focusedIndex!) return `translate(${window.innerWidth/2 - (250/2) + cardOffset}px,150px)`
            return `translate(${window.innerWidth/2 - (250/2) + props.touchDeltaX!}px,150px)`
        })
        setZIndex(()=> {
            if (props.index === props.focusedIndex! + 1 || props.index === props.focusedIndex! - 1) return 3
            if (props.index === props.focusedIndex! + 2 || props.index === props.focusedIndex! - 2) return 2
            return 5
        })
    }



    function handleClick() {
        if (props.setSelectedFillIndex === undefined) return
        if (props.index !== props.focusedIndex) return
        if (!pointerEvents) return

        props.setSelectedFillIndex(props.index)
        setPointerEvents(false)
    }
    


    return (
        <div
            className={props.type==="fill" ? "homepageFill" : "homepagePrompt"}
            style={{
                position:'absolute',
                transform:`${translate} ${rotate} ${scale}`,
                transition:transition,
                cursor:props.type==="fill"?'pointer':'auto',
                width:'15.625rem',
                height:'21.875rem',
                zIndex:zIndex
            }}
            onClick={handleClick}
        >
            <p style={{padding:'20px', fontSize:"1.5rem", fontWeight:'bold', textAlign:'left'}}>{props.text}</p>
        </div>
    )
}