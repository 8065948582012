/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState, useContext, SetStateAction} from 'react'
import Card from './Card'
import {getPercentOfLength, getRandomNumberAbsolute, mapRange, useWindowDimension} from '../fill-my-prompt/utils'
import { matchContext } from '../../App'
import {shuffle} from 'lodash'
import Results from '../Results/Results'
import { timeouts } from '../fill-my-prompt/config'
import RoundInfoText from './RoundInfoText'
import { isMobile } from 'react-device-detect'
import ResultsMobile from '../Results/ResultsMobile'
import { MatchSettings } from '../MatchRoom/FillMyPromptRoom'


export interface FillMyPromptsProps {
    matchFills:string[][],
    matchPrompts:string[],
    submitChosenCards:(fills:string[], roundNumber:number) => Promise<void>,
    matchRoundCount:number,
    canStartVoting:boolean,
    getAllSubmittedFills:() => void,
    allPlayerFillData:any,
    submitVotesToDatabase:(votes:string[], roundNumber:number) => void,
    voteResults:any,
    playerId:string,
    playersInRoom:any,
    matchTimeLimit:number,
    otherPlayersProgress: any,
    matchSettings: MatchSettings
}



var promptSafeZone:any = {}

function populatePromptSafeZone(cardWidth:number) {
    let promptPosXMin = getPercentOfLength(window.innerWidth,50) - cardWidth
    let promptPosXMax = promptPosXMin + 500
    let promptPosYMin = 150
    let promptPosYMax = promptPosYMin + 350
    return {promptPosXMin, promptPosXMax, promptPosYMin, promptPosYMax}
}

promptSafeZone = populatePromptSafeZone(250)



interface CardData {
    key: number,
    id:number,
    type:string,
    isInputCard: boolean,
    text: string,
    promptTags?:string[][],
    startTop:number,
    isHovering:boolean,
    startingRotation:number,
    startZ:number,
    z:number,
    fillsNeeded?:number,
    startTranslation?:string,
    handleCardHover?:void,
    handleCardClick?:void,
    shouldLeave:boolean,
    voteResults:any,
    winningCardId?:number|undefined,
    isMultiFillCard?:boolean,
    promptCanIgnoreFillsNeeded?:boolean,
    setSendClickToWinningCard?:any,
    endTranslation?:string,
    transformObj:any,
    centeredCardIndex:number,
    centeredCardCount: number,
    centeredCards:number[],
    areCardsInHand:boolean,
    focusedCardInHand?:number
    setFocusedCardInHand?: React.Dispatch<SetStateAction<number>>,
    fillCardCount?:number,
    validFillCardIds?: number[],
    hideVoteCount?: boolean,
    shouldDim:boolean,
    shouldForceCardClick:boolean,
    forceCenterCardPositionRerender:number,
    isPairedWithInputCard?:boolean,
    promptText?:string
}

interface validPositionsInterface {
    singleHasRoom: boolean,
    singlePositions: {x:number,y:number}[],
    doubleHasRoom: boolean,
    doublePositions: {x:number,y:number}[],
}






function FillMyPrompt(props:FillMyPromptsProps){

    let initialPromptCard = {
        key: 0,
        id:100,
        type: 'prompt',
        isInputCard:false,
        text: '',
        startTop:0,
        transformObj:{x:0,y:0,rotation:0, scale:1},
        isHovering:false,
        startZ:0,
        z:0,
        startingRotation: 0,
        startTranslation:`0px,0px`,
        shouldLeave:false,
        voteResults: false,
        centeredCardIndex:-1,
        centeredCardCount: 1,
        centeredCards:[0],
        areCardsInHand: false,
        hideVoteCount: false,
        shouldDim: false,
        shouldForceCardClick:false,
        forceCenterCardPositionRerender: 0
    }

    let initialFillCards = [{...initialPromptCard, type:'fill'}]
    let matchContextValues = useContext(matchContext)
    let cardHeight = isMobile? 350*0.7 : 350
    
    const cardWidth = 250
    const [windowWidth, windowHeight] = useWindowDimension();
    const [roundNumber, setRoundNumber] = useState<number>(0)
    const [centeredCards, setCenteredCards] = useState<number[]>([0])
    const [centeredCardRefs, setCenteredCardRefs] = useState<any[]>([undefined])
    const [promptCard, setPromptCard] = useState<CardData>(initialPromptCard)
    const [fillCards, setFillCards] = useState<CardData[]>(initialFillCards)
    const [storedFillCards, setStoredFillCards] = useState<CardData[]>(initialFillCards)
    const [validFillCardIds, setValidFillCardIds] = useState<number[]>([])
    const [canHaveCards, setCanHaveCards] = useState<boolean>(false)
    const [fillsToSubmit, setFillsToSubmit] = useState<CardData[]>()
    const [matchSubmittedFills, setMatchSubmittedFills] = useState<any>({})
    const [votesToSubmit, setVotesToSubmit] = useState<string[]>([])
    const [endOfMatch, setEndOfMatch] = useState<boolean>(false)
    const [sendClickToWinningCard, setSendClickToWinningCard] = useState<boolean>(false)
    const [areCardsInHand, setAreCardsInHand] = useState<boolean>(matchContextValues.cardsInHandButtonPressed??false)
    const [focusedCardInHand, setFocusedCardInHand] = useState<number>(1)
    const [isReadyToSubmit, setIsReadyToSubmit] = useState<boolean>(false)
    const [validCardPositions, setValidCardPositions] = useState<validPositionsInterface>(generateValidCardPositions())
    const [enableCardConfirm, setEnableCardConfirm] = useState<boolean>(true)




    function detectKeyDown(e:KeyboardEvent) {
        console.log(isReadyToSubmit)
        if (!isReadyToSubmit) return
        if (e.key === "Enter") {
            console.log("ENTER")
            if (!enableCardConfirm) return
            confirmChosenCards()
            

        } else if (e.key === "Backspace") {
            e.preventDefault()
            if (isReadyToSubmit && props.matchSettings.gameMode===1) {
                setFillCards((prevFillCards) => {
                    const newFillCards = []
                    for(let i=0; i < prevFillCards.length;i++){
                        const currentFillCard = prevFillCards[i]
                        if (currentFillCard.id === 1){
                            let updatedFillCard
                            let stored = storedFillCards[0]
                            updatedFillCard = { ...currentFillCard, z: 101, startTop:stored.startTop, endTranslation:stored.endTranslation, transformObj:stored.transformObj, centeredCardIndex:stored.centeredCardIndex, forceCenterCardPositionRerender:currentFillCard.forceCenterCardPositionRerender + 1, shouldUncenter:true}
                            
                            newFillCards.push(updatedFillCard)
                        } else {
                            const updatedFillCard = { ...currentFillCard, hideVoteCount:true}
                            newFillCards.push(updatedFillCard)
                        }
                    }
                    return newFillCards 
                })
            }
        }
    }
    

    useEffect(()=>{
        setValidCardPositions(generateValidCardPositions())
        matchContextValues.setIsInMatch!(true)
        setTimeout(() => {
            setCanHaveCards(true)
        }, 200);
    },[])



    useEffect(()=>{
        window.addEventListener('keydown', detectKeyDown, true)
        return ()=> {
            window.removeEventListener('keydown',detectKeyDown, true)
        }
    },[isReadyToSubmit])   



    useEffect(()=>{
        setValidCardPositions(generateValidCardPositions())
    },[windowWidth, windowHeight])



    useEffect(() => {
        console.debug('useEffect called from centeredCards')
        positionCenterCards()

        let fillsNeeded = promptCard.fillsNeeded ?? 1
        if (promptCard.promptCanIgnoreFillsNeeded) fillsNeeded = 1
        if (centeredCards.length === fillsNeeded + 1) {
            
            setFillCards((prevFillCards) => {
                const newFillCards = []
                for (let i = 0; i < prevFillCards.length; i++) {
                    const currentFillCard = prevFillCards[i]
                    const updatedFillCard = {
                        ...currentFillCard,
                        shouldDim: currentFillCard.centeredCardIndex === -1 && true
                    }
                    newFillCards.push(updatedFillCard)
                }
                return newFillCards
            })

            if (props.voteResults && roundNumber < props.matchPrompts.length*3) {
                setTimeout(() => {
                    makeAllCardsLeave()
                },timeouts.SHOULD_LEAVE)
                setTimeout(() => {
                    setRoundNumber(prevRoundNumber => prevRoundNumber + 1)
                    console.debug('---> ADVANCE ROUND')
                }, timeouts.ADVANCE_ROUND_NUMBER)

            } else {
                setIsReadyToSubmit(true)  
            }
        } 

        else {
            if (isReadyToSubmit) setIsReadyToSubmit(false)
            setFillCards((prevFillCards) => {
                const newFillCards = []
                for (let i = 0; i < prevFillCards.length; i++) {
                    const currentFillCard = prevFillCards[i]
                    const updatedFillCard = {
                        ...currentFillCard,
                        shouldDim: false
                    }
                    newFillCards.push(updatedFillCard)
                }
                return newFillCards
            })
            
        }
    },[centeredCards])




    useEffect(() => {
        if (props.matchRoundCount <= 0) return
        console.debug({'round':roundNumber+1})

        if (areCardsInHand) setFocusedCardInHand(1)
        if (isMobile || matchContextValues.cardsInHandButtonPressed) setAreCardsInHand(true)

        if (roundNumber <= props.matchRoundCount){
            if (fillsToSubmit) {
                let fillStrings:string[] = []
                fillsToSubmit.forEach(fill =>{
                    fillStrings.push(fill.text)
                })
                props.submitChosenCards(fillStrings, roundNumber-1)
                setFillsToSubmit([])
            }
        }
    
        if (roundNumber + 1 <= props.matchRoundCount){
            setCenteredCards([0])
            setCenteredCardRefs([null])
            setPromptCard(generatePromptCard(props.matchPrompts, roundNumber))

            if (props.matchSettings.gameMode === 0) {
                let generatedFills = generateFillCards(props.matchFills, roundNumber, false)
                setFillCards(generatedFills)
                setStoredFillCards(generatedFills)
                setPromptCard(generatePromptCard(props.matchPrompts, roundNumber))
            }
            else {
                let prompt = generatePromptCard(props.matchPrompts, roundNumber, false, undefined, true)
                setPromptCard(prompt)
                setFillCards(()=> {
                    let vh = window.innerHeight
                    let randomKey = Math.floor(Math.random() * (100000+ 1))
                    let rotAmount = getRandomNumberAbsolute(5, 15, true)
                    let xPos = window.innerWidth/2 - 250/2
                    let yPos = window.innerHeight - 350 - 180
                    let startTranslationX = xPos + getRandomNumberAbsolute(0, 300, true)
                    let startTranslationY = vh + 500 
                    let newFillCards:CardData[] = []
                    newFillCards.push({
                        key: randomKey,
                        id:1,
                        type:'fill',
                        isInputCard:true,
                        text: '',
                        transformObj:{x:xPos,y:yPos ,rotation:rotAmount, scale:1},
                        startTop: yPos,
                        isHovering:false,
                        centeredCardIndex:-1,
                        centeredCardCount: 1,
                        centeredCards: [0],
                        startZ:201,
                        z:201,
                        startingRotation: 0,
                        startTranslation:`${startTranslationX}px,${startTranslationY}px`,
                        endTranslation: `${xPos}px,${yPos}px`,
                        shouldLeave:false,
                        voteResults:undefined,
                        winningCardId:undefined,
                        isMultiFillCard:false,
                        setSendClickToWinningCard,
                        areCardsInHand,
                        focusedCardInHand:1, 
                        setFocusedCardInHand,
                        fillCardCount: 1,
                        validFillCardIds:validFillCardIds,
                        hideVoteCount: false,
                        shouldDim: false,
                        shouldForceCardClick:false,
                        forceCenterCardPositionRerender: 0,
                        promptText:prompt.text
                        
                    })
                    setStoredFillCards(newFillCards)
                    return (
                        newFillCards
                    )
                })
            }
        }



        if (roundNumber > props.matchRoundCount && roundNumber <= (props.matchRoundCount*2)-1){
            // continue with vote rounds
            setCenteredCards([0])
            setCenteredCardRefs([null])
            setPromptCard(generatePromptCard(props.matchPrompts, roundNumber - props.matchPrompts.length, true))
            setFillCards(generateFillCards(matchSubmittedFills, roundNumber - props.matchPrompts.length, true))
        }

        if (votesToSubmit.length > 0){
            if (!props.voteResults){
                if (votesToSubmit.length === props.matchRoundCount){
                    props.submitVotesToDatabase(votesToSubmit, roundNumber)
                }
            }
        }


        if (props.voteResults && roundNumber < props.matchPrompts.length*3){
            // continue with Vote Results
            showVoteResults()
        }

        if (props.voteResults && roundNumber === props.matchPrompts.length*3){
            setEndOfMatch(true)
        }

        if (isMobile || matchContextValues.cardsInHandButtonPressed) setAreCardsInHand(true)
        setFocusedCardInHand(1)
    },[roundNumber])




    useEffect(() => {
        if (!props.canStartVoting) return
        props.getAllSubmittedFills()
    },[props.canStartVoting])



    useEffect(() => {
        if (!props.voteResults) return
        showVoteResults()
    },[props.voteResults])



    useEffect(() => {
        if (Object.keys(props.allPlayerFillData).length === 0) return

        let dbData = props.allPlayerFillData
        let dbDataKeys = Object.keys(dbData)
        let matchSubmittedFills:any = {}
        dbDataKeys.forEach((key)=>{
            let _playerId = key
            let _submittedFills = dbData[key].submittedFills
            _submittedFills.forEach((roundFills:string[], index:number)=>{
                if (index in matchSubmittedFills) matchSubmittedFills[index][_playerId] = roundFills
                else matchSubmittedFills[index] = { [_playerId] : roundFills }
            })
        })

        setMatchSubmittedFills(matchSubmittedFills)

        // start first vote round
        setCenteredCards([0])
        setCenteredCardRefs([null])
        setPromptCard(generatePromptCard(props.matchPrompts, roundNumber - props.matchPrompts.length, true))
        setFillCards(generateFillCards(matchSubmittedFills, roundNumber - props.matchPrompts.length, true))
    },[props.allPlayerFillData])



    useEffect(() => {
        console.debug(`sendClickToWinningCard triggered: ${sendClickToWinningCard}`)
        if (sendClickToWinningCard){
            setFillCards((prevFillCards) => {
                const newFillCards = []
                for (let i = 0; i < prevFillCards.length; i++) {
                    const currentFillCard = prevFillCards[i]
                    const updatedFillCard = { ...currentFillCard, clickWinningCardId:true }
                    newFillCards.push(updatedFillCard)
                }
                return newFillCards
            })
            setSendClickToWinningCard(false)
        }
    }, [sendClickToWinningCard])



    useEffect(() => {
        if (fillCards.length <= 1) return
        
        let cardIds:number[] = []
        let chosenCardIds:number[] = []
        fillCards.forEach(card => {
            if (card.centeredCardIndex > 0) chosenCardIds.push(card.id)
            cardIds.push(card.id)
        })
        let cardOptionCount = cardIds.length
        let focusDict:any = {}

        if (!cardIds.includes(focusedCardInHand)) {
            setFocusedCardInHand(prevFocused => prevFocused + 1)
        }

        focusDict[focusedCardInHand] = cardOptionCount
        let afterCardInFocus = cardIds.slice(cardIds.indexOf(focusedCardInHand) + 1)
        let beforeCardInFocus = cardIds.slice(0,cardIds.indexOf(focusedCardInHand)).reverse()
        afterCardInFocus.forEach((card, index) => focusDict[card] = cardOptionCount - (index+1))
        beforeCardInFocus.forEach((card, index) => focusDict[card] = cardOptionCount - (index+1))
        
        focusDict['focused']=focusedCardInHand

        setFillCards((prevFillCards) => {
            const newFillCards = []

            for (let i = 0; i < prevFillCards.length; i++) {
                const currentFillCard = prevFillCards[i]
                const updatedFillCard = {
                    ...currentFillCard,
                    focusedCardInHand,
                    z:centeredCards.includes(currentFillCard.id) ? 100+centeredCards.indexOf(currentFillCard.id) : focusDict[currentFillCard.id]
                }
                newFillCards.push(updatedFillCard)
                
            }
            return newFillCards
        })

    },[focusedCardInHand])



    useEffect(()=>{
        setAreCardsInHand(matchContextValues.cardsInHandButtonPressed!)
    },[matchContextValues.cardsInHandButtonPressed])



    useEffect(()=>{
        setFillCards((prevFillCards) => {
            const newFillCards = []

            for (let i = 0; i < prevFillCards.length; i++) {
                const currentFillCard = prevFillCards[i]
                const updatedFillCard = {
                    ...currentFillCard,
                    validFillCardIds:validFillCardIds
                }
                newFillCards.push(updatedFillCard)
                
            }
            return newFillCards
        })
    },[validFillCardIds])




    function getRoundFills(matchFills:any, relativeRoundNumber:number, isShowingPlayerFills:boolean){
        let fillList:any[] = []
    
        if (isShowingPlayerFills){
            Object.keys(matchFills[relativeRoundNumber]).forEach((_playerId, index) => {
                if (roundNumber + 1 > props.matchRoundCount && roundNumber + 1 <= props.matchRoundCount*2){
                    // IF MORE THAN 3 PLAYERS, YOU CAN'T VOTE FOR YOURSELF
                    if (Object.keys(props.playersInRoom).length >= 3 && _playerId === props.playerId) return
                }
                fillList.push({fill:matchFills[relativeRoundNumber][_playerId], id:index + 1})
            })
        } else fillList = matchFills[relativeRoundNumber].map( (fill: any, index: number) => ( {fill:fill, id:index+1} ) ) 

        
        return fillList
    }

    

    function forceNextRound(){
        // disable being able to manually confirm
        setEnableCardConfirm(false)


        if (roundNumber + 1 <= props.matchRoundCount){
            
            // handle if game mode is create-your-own-fills
            if (props.matchSettings.gameMode === 1) {
                

                // if theres a fill card centered, then simulate a confirmation
                if (centeredCards.length === 2) {
                    confirmChosenCards()
                    setTimeout(() => setEnableCardConfirm(true), 100)
                }
                else{
                    setFillCards((prevFillCards) => {
                        prevFillCards[0].shouldForceCardClick = true
                        return [...prevFillCards]
                    })

                    setTimeout(()=> {
                        confirmChosenCards()
                        setTimeout(() => setEnableCardConfirm(true), 100)
                    },750)
                }

                // otherwise simulate click to card THEN simulate a confirmation
                return
            }



            let prompt = props.matchPrompts[roundNumber] as any
            let idsAlreadyChosen = fillsToSubmit?.map((fill) => fill.id)
            let fillsSubmitted = fillsToSubmit?.length ?? 0

            // creates an array of options --> uses a map to start array at 1 and end on length of options
            let options = shuffle(Array.from(Array(props.matchFills[0].length), (e, i) => i + 1))
            if (idsAlreadyChosen) options = options.filter((id)=> !idsAlreadyChosen!.includes(id))
            for (let i = 0; i < (prompt.blanks - fillsSubmitted) ; i++) {
                setTimeout(() => { 
                    let choice = options.pop() ?? 1

                    if (isMobile) {
                        setFillCards((prevFillCards) => {
                            const updatedFillCards = []
                            for (let i = 0; i < prevFillCards.length; i++) {
                                const currentFillCard = prevFillCards[i]
                                if (i !== choice){
                                    // TODO: maybe trigger something to disable tap on all cards so you can't select one at the same time as an auto choice
                                    updatedFillCards.push(currentFillCard)
                                    continue
                                }
                                const updatedFillCard = {
                                    ...currentFillCard,
                                    shouldForceCardClick: true,
                                }
                                updatedFillCards.push(updatedFillCard)
                            }
                            return updatedFillCards
                        })
                    } else {
                        handleCardClick(choice)
                    }

                    setTimeout(()=> {
                        confirmChosenCards()
                        setTimeout(() => setEnableCardConfirm(true), 100)
                    },750)

                }, i*100)
                return
            }

            confirmChosenCards()
            setTimeout(() => setEnableCardConfirm(true), 100)

            return
        }


        if (roundNumber + 1 <= props.matchRoundCount*2){
            let options = shuffle(validFillCardIds)
            let choice = options.pop() ?? 1

            if (centeredCards.length === 2) {
                confirmChosenCards()
                setTimeout(() => setEnableCardConfirm(true), 100)
            }
            else {
                handleCardClick(choice)
                setTimeout(()=> {
                    confirmChosenCards()
                    setTimeout(() => setEnableCardConfirm(true), 100)
                },750)
            }

            return
        }
        
    }




    function positionCenterCards(){
        let center = windowWidth/2 - 250/2
        let padding = 10
        let furthestLeft = (cardWidth/2) * (centeredCards.length-1)
        let increaseBy = cardWidth + (padding)
        let yOffset = 0

        if (isMobile) {
            for (let centeredCardIndex = 0; centeredCardIndex < centeredCards.length; centeredCardIndex++) {
                let centeredCardId = centeredCards[centeredCardIndex]
                if (centeredCardId === 0) continue
                if (centeredCardIndex === centeredCards.length - 1) continue
                yOffset += centeredCardRefs[centeredCardIndex] + 35
            }
        }

        setPromptCard((prevPromptCard) => {
            return {
                ...prevPromptCard,
                endTranslation:`${center - furthestLeft}px, ${promptSafeZone.promptPosYMin}px`,
                centeredCardIndex: 0,
                centeredCardCount: centeredCards.length,
                centeredCards:centeredCards
            }
        })
        
        setFillCards((prevFillCards) => {
            const newFillCards = []
            for (let fillIndex = 0; fillIndex < prevFillCards.length; fillIndex++) {
                const currentFillCard = prevFillCards[fillIndex]
                
                if (centeredCards.at(-1) === currentFillCard.id) {
                    let centeredCardIndex = centeredCards.indexOf(currentFillCard.id)
                    const updatedFillCard = {
                        ...currentFillCard,
                        startTop: promptSafeZone.promptPosYMin,
                        endTranslation:`${center - furthestLeft + increaseBy * centeredCardIndex}px, ${promptSafeZone.promptPosYMin}px`,
                        transformObj:{
                            x:center - furthestLeft + increaseBy * centeredCardIndex,
                            y:cardHeight/2 + yOffset,
                            rotate:0,
                            scale:1
                        },
                        centeredCardIndex: centeredCardIndex,
                        centeredCardCount: centeredCards.length,
                        centeredCards:centeredCards
                    }
                    
                    newFillCards.push(updatedFillCard)
                }
                else {
                    newFillCards.push({...currentFillCard, centeredCards:centeredCards, centeredCardCount: centeredCards.length})
                }
            }
            return newFillCards
        })
        
    }
    



    function makeAllCardsLeave() {
        setFillCards((prevFillCards) => {
            const newFillCards = []
            for (let i = 0; i < prevFillCards.length; i++) {
                const currentFillCard = prevFillCards[i]
                const updatedFillCard = {
                    ...currentFillCard,
                    shouldLeave: true,
                }
                newFillCards.push(updatedFillCard)
            }
            return newFillCards
        })
        setPromptCard((prevPromptCard) => {
            return {
                ...prevPromptCard,
                shouldLeave: true,
            }
        })
        
    }
    

    
    function showVoteResults(){
        let resultRoundIndex = roundNumber - (props.matchPrompts.length*2)
        let roundObj:any = props.voteResults[resultRoundIndex]
        let roundVoteList:string[] = []
        let roundVoteIdList:number[] = []

        Object.entries(roundObj).forEach((entry: any) => {
            entry[1] > 0 && roundVoteList.push(...Array(entry[1]).fill(entry[0]))
        })

        roundVoteList.forEach((roundListUsername)=>{
            Object.keys(matchSubmittedFills[resultRoundIndex]).forEach((player, index)=>{
                if (player === roundListUsername) roundVoteIdList.push(index + 1)
            })
        })

        let winningCardId:number | undefined
        
        // get cardId that appears the most in roundVoteIdList
        let winner = roundVoteIdList.slice().sort((a,b) =>
        roundVoteIdList.filter(v => v===a).length
        - roundVoteIdList.filter(v => v===b).length
        ).pop();

        // how many times does the cardId appear
        let countOfWinner = roundVoteIdList.filter(x => x === winner).length

        // if the winner is removed from the list, what card appears second-most?
        let secondWinner = roundVoteIdList.filter(e => e !== winner).slice().sort((a,b) =>
        roundVoteIdList.filter(v => v===a).length
        - roundVoteIdList.filter(v => v===b).length
        ).pop();

        // how many times does that card appear?
        let countOfSecondWinner = roundVoteIdList.filter(x => x === secondWinner).length

        // if the 1st card appears more times than the second, that is the winner
        if (countOfWinner > countOfSecondWinner) winningCardId = winner

        // show first round Vote Results
        setCenteredCards([0])
        setCenteredCardRefs([null])
        setPromptCard(generatePromptCard(props.matchPrompts, roundNumber - (props.matchPrompts.length*2),true,roundVoteIdList))
        setFillCards(generateFillCards(matchSubmittedFills, roundNumber - (props.matchPrompts.length*2), true, roundVoteIdList, winningCardId, setSendClickToWinningCard))
    }



    function generateFillCards(matchFills:any, relativeToundNumber:number, isShowingPlayerFills:boolean, voteResults?:number[], winningCardId?:number|undefined, setSendClickToWinningCard?:any) : CardData[]{
        console.debug('generateFillCards')
        
        let fillList = getRoundFills(matchFills, relativeToundNumber, isShowingPlayerFills)
        let fillCards:CardData[] = []
        let forceCardsInHand: boolean = false

        setValidFillCardIds(()=>{
            return fillList.map((item)=>item.id) 
        })
        
        let startingFocusedCardId = fillList[0].id
        console.debug(fillList)

        fillList.forEach((fillObj:any, index:number) =>{
            let cardPosition
            if (fillList.length <= 5 && validCardPositions.singleHasRoom) {
                cardPosition = validCardPositions.singlePositions[index]
            } 
            else if (fillList.length <= 10 && validCardPositions.doubleHasRoom){
                cardPosition = validCardPositions.doublePositions[index]
            }
            else {
                forceCardsInHand = true
                cardPosition = {x:0,y:0}
            }

            let vh = window.innerHeight
            let randomKey = Math.floor(Math.random() * (100000+ 1))
            let rotAmount = getRandomNumberAbsolute(5, 15, true)
            let startTranslationX = cardPosition.x + getRandomNumberAbsolute(0, 300, true)
            let startTranslationY = vh + 500
            let isMultiFillCard = false

            if (isShowingPlayerFills && fillObj.fill.length > 1) isMultiFillCard = true
    
            fillCards.push({
                key: randomKey,
                id:fillObj.id,
                isInputCard:false,
                type:'fill',
                text: isMultiFillCard ? fillObj.fill.join('----'): fillObj.fill,
                transformObj:{x:cardPosition.x,y:cardPosition.y,rotation:rotAmount, scale:1},
                startTop: cardPosition.y,
                isHovering:false,
                centeredCardIndex:-1,
                centeredCardCount: 1,
                centeredCards: [0],
                startZ:index+1,
                z:fillList.length - (index + 1),
                startingRotation: rotAmount,
                startTranslation:`${startTranslationX}px,${startTranslationY}px`,
                endTranslation: `${cardPosition.x}px,${cardPosition.y}px`,
                shouldLeave:false,
                voteResults,
                winningCardId,
                isMultiFillCard,
                setSendClickToWinningCard,
                areCardsInHand: forceCardsInHand ? true : areCardsInHand,
                focusedCardInHand:startingFocusedCardId, 
                setFocusedCardInHand,
                fillCardCount: fillList.length,
                validFillCardIds:validFillCardIds,
                hideVoteCount: false,
                shouldDim: false,
                shouldForceCardClick:false,
                forceCenterCardPositionRerender: 0
                
            })
        })

        return fillCards
    }



    function generatePromptCard(matchPrompts:any[], roundNumber:number, promptCanIgnoreFillsNeeded:boolean = false, voteResults?:number[], isPairedWithInputCard:boolean = false) : CardData{
        var chosenPrompt = matchPrompts[roundNumber].prompt
        let rotAmount = getRandomNumberAbsolute(0, 5, true)
        let randomKey = Math.floor(Math.random() * (10000+ 1))
        let vh = window.innerHeight
        let vw = window.innerWidth
        let promptTags = matchPrompts[roundNumber].tags
    
        return {
            key: randomKey,
            id:100,
            type: 'prompt',
            isInputCard:false,
            text: chosenPrompt,
            promptTags,
            startTop:150,
            transformObj:{x:vw/2 - 125,y:cardHeight/2,rotation:rotAmount, scale:1},
            isHovering:false,
            startZ:0,
            z:0,
            startingRotation: rotAmount,
            startTranslation:`${vw/2 - 125}px,${vh+500}px`,
            endTranslation:`${vw/2 - 125}px,${150}px`,
            shouldLeave:false,
            fillsNeeded:matchPrompts[roundNumber].blanks,
            voteResults:voteResults,
            promptCanIgnoreFillsNeeded,
            centeredCardIndex:0,
            centeredCardCount: 1,
            centeredCards: [0],
            areCardsInHand:false,
            shouldDim: false,
            shouldForceCardClick:false,
            forceCenterCardPositionRerender: 0,
            isPairedWithInputCard:isPairedWithInputCard
        }
    }
 


    function handleCardClick(id:number, isResults?:boolean, isTie?:boolean, textHeight?:number, inputText?:string){
        console.debug(`handleCardClick: ${id}`)

        let shouldUnclick = centeredCards.includes(id) ? true : false
        let indexToRemove = centeredCards.indexOf(id)
        console.debug('index to remove:', indexToRemove)
        let spliceIndex = 0
        setValidFillCardIds((prevValidFillCardIds)=>{
            if (shouldUnclick) {
                for (let i = 0; i < prevValidFillCardIds.length; i++) {
                    let currId = prevValidFillCardIds[i]
                    if (id > currId) spliceIndex = i + 1
                }
                prevValidFillCardIds.splice(spliceIndex,0,id)
                return prevValidFillCardIds
            }
            return prevValidFillCardIds.filter((item)=> { return item !== id })
            
        })
       
        // if in the standard fill submission rounds
        if (Object.keys(matchSubmittedFills).length < 1) {
            setFillsToSubmit((prevFillsToSubmit) => {
                let newFillsToSubmit:CardData[] = []
                if (prevFillsToSubmit){
                    newFillsToSubmit = [...prevFillsToSubmit]
                }

                // trying to get the index of unclicked card (index in relation to centered cards) and using that to take the index out of newFillsToSubmit
                if (shouldUnclick) {
                    newFillsToSubmit.splice(indexToRemove - 1, 1)
                } else {
                    if (inputText) {
                        fillCards[0].text = inputText
                        newFillsToSubmit.push(fillCards[0])
                    } else {
                        for(let i=0; i < fillCards.length;i++){
                            const currentFillCard = fillCards[i]
                            if (currentFillCard.id === id) newFillsToSubmit.push(currentFillCard)
                        }
                    }
                }

                return newFillsToSubmit
            });
        }

        else if (!isResults){
            if (shouldUnclick) {
                let updatedVotesToSubmit = votesToSubmit
                updatedVotesToSubmit.pop()
                setVotesToSubmit(updatedVotesToSubmit)
            }
            else {

                let fillList = matchSubmittedFills[roundNumber - props.matchPrompts.length]
                let _playerIds = Object.keys(fillList)
                setVotesToSubmit(prevVotesToSubmit => {
                    let submit = [...prevVotesToSubmit]
                    submit.push(_playerIds[id-1])
                    return submit
                })
            }
        }


        if (isTie) {
            makeAllCardsLeave()
            setTimeout(() => { setRoundNumber(prevRoundNumber => prevRoundNumber + 1) }, timeouts.ADVANCE_ROUND_NUMBER)
            return
        } 


        setPromptCard((prevPromptCard) =>  ({ ...prevPromptCard, z: 100,forceCenterCardPositionRerender:prevPromptCard.forceCenterCardPositionRerender + 1 }))
        setFillCards((prevFillCards) => {
            const newFillCards = []
            for(let i=0; i < prevFillCards.length;i++){
                const currentFillCard = prevFillCards[i]
                if (currentFillCard.id === id){
                    let updatedFillCard
                    if (shouldUnclick) {
                        let stored = storedFillCards[id-1]
                        
                        updatedFillCard = { ...currentFillCard, z: id, startTop:stored.startTop, endTranslation:stored.endTranslation, transformObj:stored.transformObj, centeredCardIndex:stored.centeredCardIndex, forceCenterCardPositionRerender:currentFillCard.forceCenterCardPositionRerender + 1, shouldUncenter:false}
                    }
                    else updatedFillCard = { ...currentFillCard, z: 100 + centeredCards.length}
                    newFillCards.push(updatedFillCard)
                } else {
                    const updatedFillCard = { ...currentFillCard, hideVoteCount:true}
                    newFillCards.push(updatedFillCard)
                }
            }
            return newFillCards 
        })

        

        setCenteredCards((prevCenteredCards) => {
            if (shouldUnclick){
                prevCenteredCards.splice(indexToRemove,1)
                return [...prevCenteredCards]
            }
            else return [ ...prevCenteredCards, id ]
        })
        setCenteredCardRefs((prevCardHeights) => {
            if (shouldUnclick) {
                prevCardHeights.splice(indexToRemove,1)
                return [...prevCardHeights]
            }
            else return [ ...prevCardHeights, textHeight ]
        })
        
        if (shouldUnclick) {
            if (validFillCardIds.at(-1) === id) {
                setFocusedCardInHand(prev => validFillCardIds.at(-1)!)
            }
            else setFocusedCardInHand(prev => spliceIndex+1)
        }
        
    }





    function Cards(){
        if (!canHaveCards) return

        return(
            <div>
                <Card {...promptCard} handleCardClick={handleCardClick}/>
                {fillCards.map((fillCard) => (<Card {...fillCard} handleCardClick={handleCardClick}/>))}
            </div>
        )
    }
    


    function showGame(){
        if (endOfMatch) {
            if (isMobile) return <ResultsMobile playerId={props.playerId} prompts={props.matchPrompts} players={props.allPlayerFillData} votes={props.voteResults} playerNames={props.playersInRoom}/>
            return <Results playerId={props.playerId} prompts={props.matchPrompts} players={props.allPlayerFillData} votes={props.voteResults} playerNames={props.playersInRoom}/>
        }
        return Cards()
    }



    function generateValidCardPositions() {
        let validPositions: validPositionsInterface = {
            singleHasRoom: false,
            singlePositions:[],
            doubleHasRoom: false,
            doublePositions:[]
        }

        let center = window.innerWidth/2 - 250/2
        let cardWidth = 250

        if (window.innerWidth > 1100) {
            validPositions.singleHasRoom = true
            let rowOne = mapRange(window.innerWidth, 1100, 1400, cardWidth*1.5, cardWidth*2)
            let rowTwo = mapRange(window.innerWidth, 1100, 1400, cardWidth*1.1, cardWidth*1.3)
            validPositions.singlePositions = [
                {x:center, y: 550},
                {x:center - rowTwo, y: 500},
                {x:center + rowTwo, y: 500},
                {x:center - rowOne, y: 175},
                {x:center + rowOne, y: 175}
            ]
        }

        if (window.innerWidth > 1400) {
            validPositions.doubleHasRoom = true
            let rowOne = mapRange(window.innerWidth, 1400, 1700, cardWidth*2, cardWidth*2.2)
            let rowTwo = mapRange(window.innerWidth, 1400, 1700, cardWidth*2.2, cardWidth*2.8)
            let rowThree = mapRange(window.innerWidth, 1400, 1700, cardWidth*2, cardWidth*2.5)
            let rowFour = mapRange(window.innerWidth, 1400, 1700, cardWidth*1.5, cardWidth*1.6)
            validPositions.doublePositions = [
                {x:center - (cardWidth/2 + 10), y: 600},
                {x:center + (cardWidth/2 + 10), y: 600},
                {x:center - rowFour, y: 575},
                {x:center + rowFour, y: 575},
                {x:center - rowThree, y: 475},
                {x:center + rowThree, y: 475},
                {x:center - rowTwo, y: 325},
                {x:center + rowTwo, y: 325},
                {x:center - rowOne, y: 150},
                {x:center + rowOne, y: 150}
            ]

        }

        return validPositions
    }



    function confirmChosenCards() {
        makeAllCardsLeave()
        setTimeout(() => {
            setRoundNumber(prevRoundNumber => prevRoundNumber + 1)
            console.debug('---> ADVANCE ROUND')
        }, 700)
        setIsReadyToSubmit(false)
    }



    function showConfirmCardsButton(shouldShowEdit=false) {
        if (!isReadyToSubmit) return
        if (!enableCardConfirm) return
        let buttonsTranslate = `translate(0px,${175 + cardHeight + 30}px)`
        return (
            <>
                <div style={{position:'absolute', display:'flex',width:'100%', justifyContent:'center', top:0, transform:buttonsTranslate, zIndex:205, columnGap:'20px'}}> 
                    
                {shouldShowEdit &&
                    <div>
                        <button className="settingsPanel-optionRow-ActionButton"
                            style={{width:'auto', padding:isMobile?'10px 20px':'', minWidth:isMobile?'5rem':'200px'}}
                            onClick={()=> {
                                setFillCards((prevFillCards) => {
                                    const newFillCards = []
                                    for(let i=0; i < prevFillCards.length;i++){
                                        const currentFillCard = prevFillCards[i]
                                        if (currentFillCard.id === 1){
                                            let updatedFillCard
                                            let stored = storedFillCards[0]
                                            updatedFillCard = { ...currentFillCard, z: 101, startTop:stored.startTop, endTranslation:stored.endTranslation, transformObj:stored.transformObj, centeredCardIndex:stored.centeredCardIndex, forceCenterCardPositionRerender:currentFillCard.forceCenterCardPositionRerender + 1, shouldUncenter:true}
                                            newFillCards.push(updatedFillCard)
                                        } else {
                                            const updatedFillCard = { ...currentFillCard, hideVoteCount:true}
                                            newFillCards.push(updatedFillCard)
                                        }
                                    }
                                    return newFillCards 
                                })
                                
                                
                            }}
                            > 
                            edit
                        </button>
                        {!isMobile && <p style={{color:'#404040', fontWeight:'bold', fontSize:'18px', marginTop:'5px'}}>( backspace )</p>}
                    </div>
                    }
                    
                    
                    <div>
                        <button className="settingsPanel-optionRow-ActionButton"
                            style={{width:'auto', padding:isMobile?'10px 20px':'', minWidth: isMobile?'5rem':'200px'}}
                            onClick={()=> {
                                if (!enableCardConfirm) return
                                confirmChosenCards()
                            }}
                            > 
                            confirm
                        </button>
                        {shouldShowEdit && !isMobile && <p style={{color:'#404040', fontWeight:'bold', fontSize:'18px', marginTop:'5px'}}>( enter )</p>}
                    </div>

                    
                </div>

                
            </>
        )
    }




    return(
        <div className="FMP-master--div">
          
            <RoundInfoText roundNumber={roundNumber} matchRoundCount={props.matchRoundCount} canStartVoting={props.canStartVoting} canStartResults={props.voteResults ? true : false} matchTimeLimit={props.matchTimeLimit} forceNextRound={forceNextRound} otherPlayersProgress={props.otherPlayersProgress} playersInRoom={props.playersInRoom} playerId={props.playerId} matchSettings={props.matchSettings}/>

            {showConfirmCardsButton(props.matchSettings.gameMode === 1 && roundNumber + 1 <= props.matchRoundCount)}
            
            <div style={{position:'absolute', width:'100%', height:'100%', top:0, backgroundColor:'rgb(0 0 0 /30%)', zIndex:99, pointerEvents:'none', opacity:isReadyToSubmit? 1 : 0, transition:'opacity 300ms ease-in'}}/>
            
            {showGame()}
        </div>
    )
    
}

export default FillMyPrompt