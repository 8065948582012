import { isMobile } from "react-device-detect"


interface PlayerResultTileProps{
    playerResult:any,
    isCurrentPlayer:boolean
}

export default function PlayerResultTile(props:PlayerResultTileProps) {

    let nickname = props.playerResult.nickname
    let points = props.playerResult.points
    let wins = props.playerResult.wins
    let ties = props.playerResult.ties
    let votes = props.playerResult.votes

    const parentDivStyle:React.CSSProperties = {
        display:'flex',
        flexDirection:'column',
        rowGap:props.isCurrentPlayer ?'20px': '10px',
        fontSize: '24px',
        fontWeight:'bold',
        padding:'20px',
        backgroundColor:isMobile? 'var(--fill)' : 'var(--bg)',
        borderRadius:'10px',
        border:props.isCurrentPlayer ? '2px solid var(--highlight)' : ''
        // border:props.isCurrentPlayer ? '2px solid var(--highlight)' : ''
    }



    function showPointBreakdown(points:number) {
        if (!props.isCurrentPlayer) return

        let pointText:string
        if (points === 1) pointText = `(${points} pt)`
        else pointText = `(${points} pts)`

        return <p style={{color:"#404040"}}>{pointText}</p>
    }

    function returnTotalPointText() {
        if (isMobile) {
            if (points === 1) return "pt"
            else return "pts"
        }

        if (points === 1) return "point"
        else return "points"
    }



    return (
        <div style={parentDivStyle}>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <p>{nickname}</p>
                <p>{points} {returnTotalPointText()}</p>
            </div>

            <div style={{fontSize:'16px', color:'#808080', display:'grid', gridAutoFlow:'column', gridAutoColumns:'auto'}}>
                <div>
                    <p>{wins} {wins===1 ? 'win':'wins'}</p>
                    {showPointBreakdown(wins*10)}
                </div>

                <div>
                    <p>{ties} {ties===1 ? 'tie':'ties'}</p>
                    {showPointBreakdown(ties*5)}
                </div>

                <div>
                    <p>{votes} {votes===1 ? 'vote':'votes'}</p>
                    {showPointBreakdown(votes)}
                </div>
                
            </div>
        </div>
                
    )
}