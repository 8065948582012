import { useEffect, useState } from "react"
import { getRandomNumberAbsolute } from "../components/fill-my-prompt/utils"


interface HomepageExampleCardProps{
    type:string,
    text:string,
    startX:number,
    startY:number,
    startRot:number,
    index:number,
    workableSpace:number,
    selectedFillIndex:number|undefined,
    shouldReveal:boolean,
    fillIndicies?:number[],
    setSelectedFillIndex?:React.Dispatch<React.SetStateAction<number | undefined>>,
}

export default function HomepageExampleCard(props:HomepageExampleCardProps) {
    const [scaleMultiplier, setScaleMultiplier] = useState<number>(window.innerWidth > 1250 ? 1 : 0.8)
    const cardWidth = 250 * scaleMultiplier
    const [translate, setTranslate] = useState<string>(`translate(${-window.innerWidth}px,0px)`)
    const [rotate, setRotate] = useState<string>(`rotate(${props.startRot}deg)`)
    const [scale, setScale] = useState<string>(`scale(${1*scaleMultiplier})`)
    const [transition, setTransition] = useState<string>(`transform 1500ms cubic-bezier(0.25, 0.04, 0, 1)`)
    const [isAnimatedOn, setIsAnimatedOn] = useState<boolean>(false)
    const [isHovering, setIsHovering] = useState<boolean>(false)
    const [pointerEvents, setPointerEvents] = useState<boolean>(false)
    

    useEffect(()=> {
        if (!props.shouldReveal) return
        setTimeout(()=> {
            setTranslate(`translate(${props.startX}px,0px)`)
            setTimeout(() => setIsAnimatedOn(true) ,1500)
        }, getRandomNumberAbsolute(0,200))
    },[props.shouldReveal])

    useEffect(()=> {
        if (window.innerWidth > 1250) {
            setScaleMultiplier(1)
            setScale(`scale(${1})`)
        }
        else {
            setScaleMultiplier(0.8)
            setScale(`scale(${0.8})`)
        }
    },[window.innerWidth])


    useEffect(()=> {
        if (!isAnimatedOn) return
        if (props.selectedFillIndex === undefined) setPointerEvents(true)
    },[isAnimatedOn])


    useEffect(()=> {
        if (!props.shouldReveal) return
        if (!isAnimatedOn) return
        if (props.selectedFillIndex !== undefined) {
            setPointerEvents(false)
            setTimeout(()=> {
                setTransition(`transform 1000ms cubic-bezier(0.68, 0, 0.22, 1)`)
                setTranslate(`translate(${window.innerWidth+props.startX}px,0px)`)
            },2000)
        }

        setTransition(`transform 1000ms cubic-bezier(0.16, 0, 0.08, 1)`)

        if (props.type === "prompt") {
            let newX = props.selectedFillIndex===undefined? props.workableSpace-(cardWidth): props.workableSpace-(cardWidth*2)
            setTranslate(`translate(${newX}px,0px)`)
        }
        else {
            if (props.selectedFillIndex === undefined) {
                setTranslate(`translate(${props.startX}px,0px)`)
                return
            }
            if (props.index === props.selectedFillIndex) {
                let newX = props.workableSpace - cardWidth
                setTranslate(`translate(${newX}px,0px)`)
                return
            }

            let newIndicies = [...props.fillIndicies!]
            newIndicies.splice(props.selectedFillIndex,1)

            let newX = newIndicies.indexOf(props.index)*cardWidth
            setTranslate(`translate(${newX}px,0px)`)
        }
    },[props.workableSpace, props.selectedFillIndex])


    function handleClick() {
        if (!props.setSelectedFillIndex) return
        if (!pointerEvents) return

        setScale(`scale(${1*scaleMultiplier})`)
        setIsHovering(false)
        setTransition(`transform 1000ms cubic-bezier(0.25, 0.04, 0.22, 1)`)
        props.setSelectedFillIndex(props.index)
    }


    function handleMOver() {
        if (props.type === "prompt") return
        if (!pointerEvents) return
        setIsHovering(true)
        setTransition(`transform 150ms cubic-bezier(0.25, 0.04, 0.22, 1)`)
        setRotate(`rotate(0deg)`)
        setScale(`scale(${1.05*scaleMultiplier})`)
    }


    function handleMOut() {
        if (!pointerEvents) return
        setIsHovering(false)
        setScale(`scale(${1*scaleMultiplier})`)
        setRotate(`rotate(${props.startRot}deg)`)
    }


    return (
        <div
            className={props.type==="fill" ? "homepageFill" : "homepagePrompt"}
            style={{
                position:'absolute',
                transform:`${translate} ${rotate} ${scale}`,
                transition:transition,
                cursor:props.type==="fill"?'pointer':'auto',
                zIndex:props.index === props.selectedFillIndex || isHovering? 5 : 0,
                pointerEvents:pointerEvents? 'auto' : 'none',
                width:'15.625rem',
                height:'21.875rem',
            }}
            onClick={handleClick}
            onMouseOver={handleMOver}
            onMouseOut={handleMOut}
        >
            <p style={{padding:'20px', fontSize:"1.5rem", fontWeight:'bold', textAlign:'left'}}>{props.text}</p>
        </div>
    )
}