import { useContext, useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { matchContext, matchContextInterface } from "../../App"
import { isMobile } from 'react-device-detect';


export default function Header() {
    const navigate = useNavigate()
    const matchContextValues:matchContextInterface = useContext(matchContext)
    const [roomIdToShow, setRoomIdToShow] = useState<string>("") 
    const FMPTextRef = useRef<HTMLDivElement>(null)
    const [hasRoomId, setHasRoomId] = useState<boolean>(false) // leave this in
    const [isHoveringFMPText, setIsHoveringFMPText] = useState<boolean>(false)
    const [roomIdTooltipText, setRoomIdTooltipText] = useState<string>('copy room invite')
    const [matchIdOpacity, setMatchIdOpacity] = useState<number>(1)
    const [wipeHomeOpacity, setWipeHomeOpacity] = useState<number>(0)
    const [FMPTextOpacity, setFMPTextOpacity] = useState<number>(1)
    const [hasTransitionedIn, setHasTransitionedIn] = useState<boolean>(false)



    useEffect(()=>{
        if (!matchContextValues.shouldDoHomeWipe?.shouldWipe) return

        setHasTransitionedIn(false)
        setWipeHomeOpacity(1)
        
        setTimeout(()=>{
            navigate(`/`, { state: { doBackAnimation: true, messagePopup: matchContextValues.shouldDoHomeWipe?.reason} })
            setFMPTextOpacity(0)

            setTimeout(()=>{
                setWipeHomeOpacity(0)
                matchContextValues.setShouldDoHomeWipe!({shouldWipe:false, reason:""})
                matchContextValues.setRoomId!('')
                setTimeout(()=>{setFMPTextOpacity(1)},500)
            },100)
            
        },500)

    },[matchContextValues.shouldDoHomeWipe])


    useEffect(()=> {
        setRoomIdToShow(matchContextValues.isInMatch? '' : ` / ${matchContextValues.roomId}`)

        // hacky way of forcing a rerender to calculate proper width of Header Text
        if (matchContextValues.roomId) setTimeout(()=>setHasRoomId(true),10)
        else setTimeout(()=>setHasRoomId(false),10)

        if (matchContextValues.roomId === ""){
            setFMPTextOpacity(0)
            setHasTransitionedIn(false)
        }
        else {
            setTimeout(()=>{setHasTransitionedIn(true)},2000)
            setFMPTextOpacity(1)
        }
    },[matchContextValues.roomId])


    useEffect(()=> {
        if (matchContextValues.isInMatch) {
            setHasTransitionedIn(false)
            setMatchIdOpacity(0)
            setTimeout(()=>setRoomIdToShow(''), 1100) 
        }
        else setMatchIdOpacity(1)
    },[matchContextValues.isInMatch])


    function showToolTip(text:string, widthRems:number, position:{x?:string, y?:string, calc?:string}, alignment:string){
        let showDebugDot = false
        
        let align:number
        if (alignment === 'left') align = 1.25
        else if (alignment === 'right') align = widthRems - 1.25
        else align = widthRems/2

        let translation:string
        if (position.calc) translation = `translate(calc(${position.calc}))`
        else translation = `translate(${position.x},${position.y})`


        return (
            <div style={{position:'absolute',transform:translation,pointerEvents:'none'}}>
                {showDebugDot && <div style={{backgroundColor:'red', width:'2px', height:'2px'}}/>}
                <div
                style={{
                    position:'fixed',
                    zIndex:2,
                    opacity:'1',
                    transform:`translate(-10px,3px)`,
                    width:'0px',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderBottom: '10px solid var(--fillBorder)'
                }}
                />

                <div
                style={{
                    position:'fixed',
                    zIndex:3,
                    opacity:'1',
                    transform:`translate(-10px,6px)`,
                    width:'0px',
                    borderLeft: '10px solid transparent',
                    borderRight: '10px solid transparent',
                    borderBottom: '10px solid var(--fillColor)'
                }}
                />
                
                <div
                style={{
                    position:'fixed',
                    opacity:'1',
                    backgroundColor:'var(--fillColor)',
                    boxShadow:'0 0 0 2px var(--fillBorder)',
                    borderRadius:'0.4rem',
                    padding:'0.3rem 0',
                    width:`${widthRems}rem`,
                    transform:`translate(-${align}rem,14px)`
                }}
                >
                    <b>{text}</b>
                </div>
            </div>
        )
    }

    function returnTransform(){
        if (matchContextValues.roomId !== '' && FMPTextRef.current) return ''
        else return 'translate(0px, -200px)'
    }


    function returnMargin() {
        let left = 0
        if (matchContextValues.isInMatch) return `30px 0px 0px 30px`
        if (FMPTextRef.current) left += (440 + window.innerWidth - FMPTextRef.current.clientWidth) / 2
        
        return '30px 0px 0px calc(((100vw - 440px) / 2) + 440px)'
    }


    function handleRoomIdTooltipClick(){
        if (matchContextValues.isInMatch) {
            setWipeHomeOpacity(1)
            matchContextValues.setShouldDoHomeWipe!({shouldWipe:true, reason:""})
            setIsHoveringFMPText(false)
        } 
        else {
            navigator.clipboard.writeText(window.location.href)
            setRoomIdTooltipText('copied!')
        }
    }


    function handleMouseOutFMPText() {
        setIsHoveringFMPText(false)
        if(roomIdTooltipText !== "copy room invite") setRoomIdTooltipText('copy room invite')
    }


    function returnTransition() {
        if (hasTransitionedIn && !matchContextValues.isInMatch) return ''
        if (matchContextValues.isInMatch) return "1500ms cubic-bezier(0.63, 0, 0.34, 1.01)"

        return `1200ms cubic-bezier(0.34, 0.01, 0.21, 1)`
    }


    function showMatchId() {
        return (
            <span style={{color:'#808080', fontWeight:'normal', opacity:matchIdOpacity, transition:'opacity 1000ms ease-in-out'}}>
                {roomIdToShow}
            </span>
        )
    }

    
    function returnHeaderToolTip() {
        if (!isHoveringFMPText) return
        if (matchContextValues.isInMatch) return showToolTip('leave match', 9, {x:`${FMPTextRef.current!.clientWidth/2}px`,y:'2rem',}, 'middle')
        if (matchContextValues.roomId !== "" && !matchContextValues.isInMatch) return showToolTip(roomIdTooltipText, 9, {x:'0rem',y:'2rem',}, 'middle')
    }


    function showHomeWipe() {
        if (matchContextValues.roomId === "") return
        return (
            <div 
                style={{
                    position:'absolute',
                    width:'100vw',
                    height:'100vh',
                    backgroundColor:'var(--bg)',
                    transform: matchContextValues.shouldDoHomeWipe?.shouldWipe? 'translate(0px, 0px)' : 'translate(0px, 100vh)',
                    transition: "transform 500ms cubic-bezier(0.61, 0.01, 0.1, 1)",
                    zIndex:1000,
                    opacity: wipeHomeOpacity
                }}
            />
        )
    }


    let headerTextStyle:React.CSSProperties = {
        transform:matchContextValues.isInMatch? 'translate(0px, -0.7rem)' : 'translate(-50%, -0.7rem)', // -0.7rem to counter padding used for hover
        transition:`transform ${returnTransition()}, opacity 0ms ease-out`,
        opacity:FMPTextOpacity,
        pointerEvents: matchContextValues.shouldDoHomeWipe?.shouldWipe? 'none' : 'auto'
    }
    

    if (isMobile) return (<div></div>)

    return (
        <>
            {showHomeWipe()}
            <div style={{position:'absolute', zIndex:'100',display:'flex', width:'100%', transform:returnTransform(), transition:`transform ${returnTransition()}`}}>
                <div ref={FMPTextRef} style={{margin:returnMargin(), transition:`margin ${returnTransition()}`, display:'flex'}}>
                    <h2 className="fmpTextHeader headerButtonHover" style={headerTextStyle} onMouseOver={()=>setIsHoveringFMPText(true)} onMouseOut={handleMouseOutFMPText} onClick={handleRoomIdTooltipClick}>
                        FILL MY PROMPT {showMatchId()}
                    </h2> 
                    
                    {returnHeaderToolTip()}
                </div>
            </div>
        </>
    )
}