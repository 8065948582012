import React, {SetStateAction, useContext, useEffect,useRef,useState} from 'react'
import { matchContext } from '../../App'
import { getRandomNumberAbsolute, returnTouchMoveDelta, useWindowDimension } from '../fill-my-prompt/utils';
import { timeouts } from '../fill-my-prompt/config';
import { isMobile } from 'react-device-detect'
import CalculatedCardTextbox from '../fill-my-prompt/CalculatedText';



export interface CardDataProps {
    id:number,
    type:string,
    text: string,
    isInputCard:boolean,
    isHovering:boolean,
    startingRotation: number,
    z:number,
    handleCardClick?: (id:number, isResult?:boolean, isTie?:boolean, textHeight?:number, inputText?:string) => void,
    shouldLeave:boolean,
    voteResults:number[],
    winningCardId?:number|undefined,
    setSendClickToWinningCard?:any,
    clickWinningCardId?:boolean,
    isMultiFillCard?:boolean,
    transformObj:any,
    centeredCardIndex:number,
    centeredCardCount: number,
    centeredCards:number[],
    areCardsInHand:boolean,
    focusedCardInHand?:number
    setFocusedCardInHand?: React.Dispatch<SetStateAction<number>>,
    validFillCardIds?: number[],
    hideVoteCount?: boolean,
    shouldDim: boolean,
    shouldForceCardClick: boolean,
    shouldUncenter?:boolean,
    isPairedWithInputCard?:boolean,
    promptText?:string
}





var vh = window.innerHeight

function Card(props:CardDataProps){
    let matchContextValues = useContext(matchContext)
    const [windowWidth, windowHeight] = useWindowDimension();
    const [initialWindowDimensions, setInitialWindowDimensions] = useState<any>({x:window.innerWidth, y:window.innerHeight})
    const [transform,setTransform] = useState<string>(`translate(${props.transformObj.x}px, ${props.transformObj.y + vh + 50}px) rotate(${props.startingRotation}deg)`)
    const [transformObj,setTransformObj] = useState<any>(props.transformObj)
    const [transition, setTransition] = useState<string>(`transform ${timeouts.CARD_ANIMATE_ONSCREEN}ms cubic-bezier(0.22, 0.61, 0.25, 1.01)`)
    const [isAnimatedOn,setIsAnimatedOn] = useState<boolean>(false)
    const [isHovering, setIsHovering] = useState<boolean>(false)
    const [acceptsPointerEvents, setAcceptsPointerEvents] = useState<boolean>(false)
    const [votes,setVotes] = useState<number>(0)
    const [voteTextTransforms, setVoteTextTransforms] = useState<any>([])
    const [wasCardChosen, setWasCardChosen] = useState<boolean>(false)
    const [touchStartPosition, setTouchStartPosition] = useState<any>(undefined)
    const swipeDeltaNeeded = 60
    const inHandCardHeight = isMobile? windowHeight - 250 : windowHeight - 350
    const [haveCardsLeft, setHaveCardsLeft] = useState<boolean>(false)
    const pileRef = useRef<any[]>([])
    const cardRef = useRef<HTMLDivElement|null>(null)
    const [spentTokenCount, setSpentTokenCount] = useState<number>(0)
    const [countTokensOpacity, setCountTokensOpacity] = useState<number>(0)
    const AbsoluteCardTransform = returnTransformValue({x:props.transformObj.x, y:props.transformObj.y}, false)
    const [cardInputValue, setInputState] = useState<string>('')
    const cardInputRef = useRef<HTMLTextAreaElement|null>(null)
    const [zOffset, setZOffset] = useState<number>(0)
    const [multiCardTopOffset, setMultiCardTopOffset] = useState<number>(window.innerHeight)
    const [returnInputCardToHand, setReturnInputCardToHand] = useState<boolean>(false)
    const [touchDeltaX,setTouchDeltaX] = useState<number>(0)
    const mobilePromptGhostRef = useRef<HTMLDivElement>(null)
    const [isFocusingInputMobile, setIsFocusingInputMobile] = useState<boolean>(false)
    

    useEffect(()=>{
        if (!props.shouldUncenter) return
        
        sendCardBackToHand()
    },[props.shouldUncenter])



    useEffect(()=>{
        if (!returnInputCardToHand) return

        sendCardBackToHand()
    },[returnInputCardToHand])



    function setMobileInputCardTransform() {
        // (margin between text and top of card + prompt text height + prompt translate y + buffer room) * card scale factor
        let topSpacing = ((mobilePromptGhostRef.current?.offsetTop??0) + (mobilePromptGhostRef.current?.offsetHeight??0) + (350*0.7)/2 + 20) * 1.1
        setTransform(returnTransformValue({x:windowWidth/2 - 250/2, y:topSpacing, rotate:0, scale:1.1}, true, true))
    }



    useEffect(()=>{
        if (props.isMultiFillCard) setMultiCardTopOffset(0)

        setTimeout(()=> {
            if (props.areCardsInHand) {
                if (props.isInputCard) setMobileInputCardTransform()
                else setCardInHandTransform()
            }
            else setTransform(returnTransformValue({x:props.transformObj.x, y:props.transformObj.y, rotate:props.startingRotation}))
            
            setTimeout(() => { setTransition(`transform 150ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`) },300)
            setTimeout(() => {
                setIsAnimatedOn(true)
                if (!props.voteResults) setAcceptsPointerEvents(true)
                if (props.isInputCard && !isMobile) cardInputRef.current!.focus()
            }, timeouts.CARD_ANIMATE_ONSCREEN-100)

            // if there are voteResults, wait for the card to animate on
            props.voteResults !== undefined && setTimeout(()=> {
                
                // loop through all votedCardIds
                props.voteResults.forEach((votedCardId, index)=>{

                    // if this card's Id matches the current votedCardId, set a timeout then trigger vote animation
                    props.id === votedCardId && setTimeout(()=>{
                        setVotes(prevVotes => prevVotes + 1)
                        setVoteTextTransforms((prevVoteTextTransforms:any) => {
                            let randomX = getRandomNumberAbsolute(-50,100,false)
                            prevVoteTextTransforms.push(randomX)
                            return ([...prevVoteTextTransforms])
                        })
                        
                        if (matchContextValues.cardsInHandButtonPressed) props.setFocusedCardInHand!(votedCardId)
                        else {
                            setZOffset(100)
                            setTimeout(()=>{setZOffset(0)},timeouts.RESULTS_VOTE_COUNT_WAIT)
                        }
                        console.log(` ===== forcing focus to ${votedCardId}`)
                        
                        // if this was the last vote to display
                        if (index+1 === props.voteResults.length){
                            // if there was a winning card, simulate a click on the winner after a timeout
                            if (props.winningCardId !== undefined)props.setSendClickToWinningCard(true)
                            // else there was no winner, it was a tie between 2 or more cards
                            else setTimeout(()=>localHandleCardClick({id:0, isResult:true, isTie:true, updateFocusedCard:false}),800)
                        } 
                    }, (index+1)*timeouts.RESULTS_VOTE_COUNT_WAIT)
                })
            },timeouts.CARD_ANIMATE_ONSCREEN-800)
        },100) 

        // if this card is the prompt & is counting round -- increase spentTokenCount with same setTimeout as votes are counted in above
        props.type === "prompt" && props.voteResults !== undefined && setTimeout(()=> {
            props.voteResults.forEach((votedCardId, index)=>{
                setTimeout(()=>setSpentTokenCount(prevValue => prevValue + 1), (index+1)*timeouts.RESULTS_VOTE_COUNT_WAIT)
            })
        },timeouts.CARD_ANIMATE_ONSCREEN-800)

        // disable pointer events if its Counting Rounds
        if (props.voteResults !== undefined && props.voteResults.length > 0) setAcceptsPointerEvents(false)

    },[])




    useEffect(() => {
        if (props.type === "fill") return
        if (props.voteResults === undefined) return

        if (spentTokenCount === 0) setTimeout(() => setCountTokensOpacity(1) , 200)
        if (spentTokenCount === props.voteResults.length) setTimeout(() => setCountTokensOpacity(0) ,800)
    },[spentTokenCount])



    useEffect(()=>{
        if (!isAnimatedOn) return
        if (props.areCardsInHand){
            setTransition(`transform 1000ms cubic-bezier(0.32, 0.8, 0, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)
            setCardInHandTransform()
            setTimeout(()=>{setTransition(`transform 150ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)},50)
        }
        else{
            setTransition(`transform 1000ms cubic-bezier(0.32, 0.8, 0, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)
            setTransform(returnTransformValue({x:props.transformObj.x, y:props.transformObj.y, rotate:props.startingRotation}, true))
            setTimeout(()=>{setTransition(`transform 150ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)},50)
        }
    },[props.areCardsInHand])



    useEffect(()=>{
        if (!isAnimatedOn) return

        setCardInHandTransform()
    },[props.focusedCardInHand])



    useEffect(()=> {
        if (!isAnimatedOn) return

        setAcceptsPointerEvents(!props.shouldDim)
    },[props.shouldDim])



    useEffect(()=>{
        if (!props.shouldLeave) return

        setAcceptsPointerEvents(false)
            setTransition(`transform 1000ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)
            let randomOffset = Math.floor(Math.random() * (200 - 0 + 1) + 0)
            setTimeout(()=> {
                setTransform((prevTransform)=>{
                    // grab translate x and y value from string
                    let [x,y] = prevTransform.split('translate(')[1].split(')')[0].split(",")
                    // repalce y and remove rotation and scale from string
                    let newTranslate = prevTransform.replace(y,"-100vh").split(" rotate")[0]
                    return newTranslate
                })
                setTransformObj((prevTransformObj:any)=> {
                    return {x:prevTransformObj.x, y:prevTransformObj.y - (vh+500), rotate:0, scale:1}
                })
                setHaveCardsLeft(true)
            },randomOffset)
    },[props.shouldLeave])

    

    useEffect(()=>{
        if (props.clickWinningCardId && props.id === props.winningCardId) {
            setTimeout(() => {
                setVotes(0)
                localHandleCardClick({id:props.winningCardId!, isResult:true, isTie:false, updateFocusedCard:false})
            },800)
        }
    },[props.clickWinningCardId])




    useEffect(()=>{
        if (!props.shouldForceCardClick) return

        if (cardInputRef.current) cardInputRef.current.blur()
        localHandleCardClick({id:props.id, isResult:false, isTie:false, updateFocusedCard:true})
    },[props.shouldForceCardClick])



    useEffect(() => {
        if (!isAnimatedOn) return
        if (props.type === "prompt") {
            adjustCenterCardPositions()
            return
        }
        if (!props.areCardsInHand) {
            setTransition(`transform 750ms cubic-bezier(0.3, 0.88, 0.25, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)
            setTransform(returnTransformValue({x:props.transformObj.x, y:props.transformObj.y, rotate:props.startingRotation}))
            setTimeout(()=> {setTransition(`transform 150ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)},50)
        }
        if (props.centeredCardIndex === -1 || props.centeredCardCount === 1) return
        
        adjustCenterCardPositions()
    },[props.centeredCardCount])



    useEffect(()=>{
        if (!isAnimatedOn) return
        if (props.type === "prompt") {
            adjustCenterCardPositions()
            return
        }
        if (props.centeredCardIndex === -1 || props.centeredCardCount === 1) return

        adjustCenterCardPositions()
    },[windowWidth,windowHeight])



    function sendCardBackToHand() {
        localHandleCardClick({id:props.id, isResult:false, isTie:false, updateFocusedCard:true})
        
        if (props.isInputCard){
            cardInputRef.current!.focus()
            if (isMobile) {
                setTransition(`transform 750ms cubic-bezier(0.3, 0.88, 0.25, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)
                setMobileInputCardTransform()
            }
        }
        if (returnInputCardToHand) setReturnInputCardToHand(false)
    }



    function setCardInHandTransform(){
        if (wasCardChosen) return
        if (haveCardsLeft) return

        // FOCUSED CARD
        if (props.id === props.focusedCardInHand) setTransform(returnTransformValue({x:windowWidth/2 - 250/2, y:inHandCardHeight, rotate:0, scale:1.1}, true))

        // CARDS TO THE LEFT
        if (props.id < props.focusedCardInHand!){setTransform(returnTransformValue({x:windowWidth/2 - 250/2 - (50 + (props.focusedCardInHand! - props.id)*10), y:inHandCardHeight, rotate:props.startingRotation/3, scale:0.9}, true))}

        // CARDS TO THE RIGHT
        if (props.id > props.focusedCardInHand!)setTransform(returnTransformValue({x:windowWidth/2 - 250/2 + (50 + (props.id - props.focusedCardInHand!)*10), y:inHandCardHeight, rotate:props.startingRotation/3, scale:0.9}, true))
    }



    function adjustCenterCardPositions(){
        setTransition(`transform 750ms cubic-bezier(0.3, 0.88, 0.25, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)

        // if is mobile prompt during TYOF Fill rounds, special case reposition when returning fill card to hand to edit
        if (isMobile && props.type === "prompt" && props.isPairedWithInputCard && props.centeredCardCount === 1) {
            setTransformObj(props.transformObj)
            setTransform(`translate(${props.transformObj.x}px, ${props.transformObj.y}px) rotate(${props.startingRotation}deg)`)
            setTimeout(()=> {setTransition(`transform 150ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)},50)
            return
        }

        let cardWidth = isMobile? 250*0.7 : 250
        let center = window.innerWidth/2 - 250/2
        let padding = 10
        let furthestLeft = isMobile? (cardWidth/2) * Math.min(props.centeredCardCount-1,1) : (cardWidth/2) * (props.centeredCardCount-1)
        let increaseBy = cardWidth + (padding)
        let xVal = isMobile? center - furthestLeft + increaseBy * Math.min(props.centeredCardIndex,1) : center - furthestLeft + increaseBy * props.centeredCardIndex
        let yVal = isMobile? props.transformObj.y * 0.7: props.transformObj.y

        setTransformObj({x:xVal, y:yVal, rotate:isMobile?props.startingRotation/6:props.startingRotation/3, scale:isMobile? 0.7 : 1})
        setTransform(returnTransformValue({x:xVal, y:yVal, rotate:isMobile?props.startingRotation/6:props.startingRotation/3, scale:isMobile? 0.7 : 1},false,true))
        setTimeout(()=> {setTransition(`transform 150ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)},50)
    }



    function returnTransformValue(newTransformObj:{x?:number, y?:number, rotate?:number, scale?:number}, shouldSetTransformObj:boolean = true, usePixels:boolean = false){
        let currentX:string = '0'
        let currentY:string = '0'
        if (transform) [currentX,currentY] = transform.split('translate(')[1].split(')')[0].split(",")
        currentX = parseInt(currentX).toString()
        currentY = parseInt(currentY).toString()
        
        if (usePixels) {
            let newX = newTransformObj.x === undefined ? currentX : `${newTransformObj.x}`
            let newY = newTransformObj.y === undefined ? currentY : `${newTransformObj.y}`
            let newRot = newTransformObj.rotate === undefined ? 0 : newTransformObj.rotate

            if (shouldSetTransformObj) setTransformObj({x:newX, y:newY, rotate:newRot, scale:newTransformObj.scale??1})
            return `translate(${newX}px, ${newY}px) rotate(${newRot}deg) scale(${newTransformObj.scale??1})`
        }
        else {
            let newX = newTransformObj.x === undefined ? currentX : `${(newTransformObj.x/initialWindowDimensions.x)*100}`
            let newY = newTransformObj.y === undefined ? currentY : `${(newTransformObj.y/initialWindowDimensions.y)*100}`
            if (shouldSetTransformObj) setTransformObj({x:newTransformObj.x??0, y:newTransformObj.y??0, rotate:newTransformObj.rotate??0, scale:newTransformObj.scale??1})

            return `translate(${newX}vw, ${newY}vh) rotate(${newTransformObj.rotate??0}deg) scale(${newTransformObj.scale??1})`
        }
    }
    


    interface localHandleCardClickParams {
        id:number,
        isResult?:boolean,
        isTie?:boolean,
        updateFocusedCard?:boolean
    }

    function localHandleCardClick(options:localHandleCardClickParams) {
        if (props.type === 'prompt') return

        if (options.updateFocusedCard === undefined) options.updateFocusedCard = false
        if (options.isTie === undefined) options.isTie = false
        if (options.isResult === undefined) options.isResult = false

        let cardRefHeight = 0
        if (cardRef.current) cardRefHeight = cardRef.current.clientHeight
        if (options.updateFocusedCard){
            if (props.centeredCardIndex !== -1) changeFocusedCardInHand(0, true)
            else changeFocusedCardInHand(1, true)
        }

        setWasCardChosen(prev => !prev)
        setIsHovering(false)
        setAcceptsPointerEvents(false)
        setTimeout(()=> {setAcceptsPointerEvents(true)},500)

        if (!props.handleCardClick) return
        if (options.isTie){
            props.handleCardClick(options.id, options.isResult, options.isTie)
            return
        }

        if (options.isResult) props.handleCardClick(options.id, options.isResult, undefined, cardRefHeight)
        else props.handleCardClick(options.id, undefined, undefined, cardRefHeight, cardInputValue) 
    }



    function handleMOver() {
        if (props.areCardsInHand) return
        if (isMobile) return
        if (!isAnimatedOn) return

        if (props.type !== 'fill') return
        if (props.voteResults !== undefined && props.voteResults.length > 0) return // prevent hover during Counting Rounds
        setIsHovering(true)

        if (props.centeredCardIndex === -1) setTransform(returnTransformValue({x:props.transformObj.x, y:props.transformObj.y, scale:1.05, rotate:0}))
        else setTransform(returnTransformValue({x:undefined, y:undefined, scale:1.05, rotate:0},false,true))
    }



    function handleMOut() {
        if (!isAnimatedOn) return
        if (props.areCardsInHand) return
        if (props.type !== 'fill') return
        if (isHovering) setIsHovering(false)
        if (!acceptsPointerEvents) return

        if (props.centeredCardIndex === -1) setTransform(returnTransformValue({x:props.transformObj.x, y:props.transformObj.y, rotate:props.startingRotation}))  
        else setTransform(returnTransformValue({x:undefined, y:undefined, rotate:isMobile?props.startingRotation/6:props.startingRotation/3},false,true))
    }



    function handleScroll(event:any) {
        if (!props.areCardsInHand) return
        if (event.deltaY > 0){
            // DOWN
            changeFocusedCardInHand(1)
        } else {
            // UP
            changeFocusedCardInHand(-1)
        }
    }



    function changeFocusedCardInHand(changeBy:number, moveFromClick?:boolean){
        props.setFocusedCardInHand!(prevFocusedCard => {
            let nextValue:number
            if (moveFromClick) nextValue = props.validFillCardIds![props.validFillCardIds!.indexOf(prevFocusedCard) + changeBy] ?? props.validFillCardIds![props.validFillCardIds!.indexOf(prevFocusedCard) - changeBy]
            else nextValue = props.validFillCardIds![props.validFillCardIds!.indexOf(prevFocusedCard) + changeBy] ?? prevFocusedCard
            return nextValue 
        })
    }



    function returnBoxShadowValue(){
        let border = ''
        if (props.type === "fill") border = '0 0 0 2px var(--fillBorder)'
        if (isHovering)return `${border}, 0px 5px 5px 0px rgb(0 0 0 /15%),0px 10px 10px 0px rgb(0 0 0 /15%),0px 30px 30px 0px rgb(0 0 0 /15%)`
        if (votes > 0 && !props.hideVoteCount!) return `${border}, 0 0 0 ${votes*10}px var(--highlight)`
        return border
    }


    
    function setMultiCardTransform(leftOffset:number, topOffset:number){
        if (wasCardChosen) return returnTransformValue({x:transformObj.x + leftOffset, y:transformObj.y + topOffset, rotate:transformObj.rotate, scale:transformObj.scale}, false, true)
        return returnTransformValue({x:transformObj.x + leftOffset, y:transformObj.y + topOffset + multiCardTopOffset, rotate:transformObj.rotate, scale:transformObj.scale}, false)
    }



    function handleTouchStart(event:any){
        setTouchStartPosition({x:event.touches[0].clientX, y:event.touches[0].clientY})
        setTransition(`box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)
    }



    function handleTouchMove(event:any){
        if (props.isInputCard) return
        if (props.type === "prompt") return
        setTouchDeltaX(returnTouchMoveDelta(event, touchStartPosition.x, swipeDeltaNeeded))
        setTransform(returnTransformValue({x:windowWidth/2 - 250/2 + touchDeltaX, y:inHandCardHeight, rotate:0, scale:1.1}, true))
        return
    }



    function handleTouchEnd(event:any) {
        if (props.isInputCard) return
        if (props.type === "prompt") return
        setTransition(`transform 150ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 500ms cubic-bezier(0.25, 2.13, 0.38, 0.73) 0s`)
        setTouchDeltaX(0)
        let delta = event.changedTouches[0].clientX - touchStartPosition.x

        if (Math.abs(delta) < swipeDeltaNeeded) {
            setTransform(returnTransformValue({x:windowWidth/2 - 250/2, y:inHandCardHeight, rotate:0, scale:1.1}, true))
            return
        }
        
        if (delta < 0) {
            console.log('NEXT >>')
            // if the next card isn't a valid id in the validFillCardIds list, don't focus it.
            if (props.validFillCardIds![props.validFillCardIds!.indexOf(props.focusedCardInHand!) + 1] === undefined){
                setTransform(returnTransformValue({x:windowWidth/2 - 250/2, y:inHandCardHeight, rotate:0, scale:1.1}, true))
            }
            else changeFocusedCardInHand(1)
        }
        else {
            console.log('<< PREV')
            // if the prev card isn't a valid id in the validFillCardIds list, don't focus it.
            if (props.validFillCardIds![props.validFillCardIds!.indexOf(props.focusedCardInHand!) - 1] === undefined) {
                setTransform(returnTransformValue({x:windowWidth/2 - 250/2, y:inHandCardHeight, rotate:0, scale:1.1}, true))
            }
            else changeFocusedCardInHand(-1)
        }
        
    }



    if (props.isMultiFillCard) {
        let multiSplit:string[] = props.text.split('----')
        
        return (
            <div>
                {showVoteAddedText()}

                {multiSplit.map((text, index) => {
                    let topOffset = 0
                    let leftOffset = 0

                    if (index>= 1){
                        for (let i = 0; i < index; i++) {
                            topOffset += (pileRef.current[i]?? 0) + 35
                            leftOffset += 10  
                        }
                    }

                    return(
                        <div>
                            <div key={index} className={props.type==="fill" ? "FMP-card-master--div": "FMP-card-master--div FMP-prompt-card"}
                                style={{
                                    transform: setMultiCardTransform(leftOffset, topOffset),
                                    zIndex: isHovering ? '200' : props.z,
                                    transition:transition,
                                    pointerEvents: acceptsPointerEvents ? 'auto' : 'none',
                                    boxShadow: returnBoxShadowValue()
                                }}
                                onMouseOver={handleMOver}
                                onMouseOut={handleMOut}
                                onWheel={handleScroll}
                                onClick={()=>localHandleCardClick({id:props.id, isResult:false, isTie:false, updateFocusedCard:true})}
                                onTouchStart={handleTouchStart}
                                onTouchMove={handleTouchMove}
                                onTouchEnd={handleTouchEnd}
                                >
                                    <CalculatedCardTextbox text={text} pileRef={(height)=>pileRef.current![index] = height} shouldDim={props.shouldDim}/>
                                </div>
                        </div>
                    )
                })}
            </div>
        )
    }



    function returnZIndexValue(){
        if (isHovering) return '200'
        if (props.isInputCard) return 101
        if (props.isPairedWithInputCard && isMobile) {
            if (props.centeredCardCount === 1) return 0
            else return 100
        }

        return props.z + zOffset
    }

    

    function showCountTokens() {
        return (
            <div style={{backgroundColor:'rgb(255 255 255 /0%)', width:'100%', height:'20px', position:'absolute', display:'flex',columnGap:'20px', justifyContent:'center', transform:'translate(0px, -30px)'}}>
                {props.voteResults.map((vote, index) => {
                    return (
                        <div key={`${vote}-${index}`} className="countToken" style={{backgroundColor:index < spentTokenCount ? 'transparent' : '', opacity: countTokensOpacity}}/>
                    )
                })}
            </div>
        )
    }



    function showVoteAddedText() {
        if (props.type === "prompt") return
        if (voteTextTransforms.length === 0) return

        return (
            <div style={{position:'absolute',transform:AbsoluteCardTransform, width:'250px', zIndex:100, top:0}}>
                {voteTextTransforms.map((randomX:number, index:number)=> {
                    return (
                        <div 
                        key={`${randomX}-${index}`}
                        style={{
                            backgroundColor:'rgb(255 255 255 /0%)',
                            height:'2rem',
                            position:'absolute',
                            transform:`translate(${randomX}%, 0px)`,
                            fontSize:'2rem',
                            color:'var(--highlight)'
                        }}
                        >
                            <p style={{animationName:'voteTextFloatAnimation',animationDuration:'1s',animationFillMode:'forwards', animationTimingFunction:'linear', fontWeight:'bold'}}>+1 vote</p>
                        </div>
                    )
                })}
            </div>
        )
    }



    function handleInputChange(e: any) {
        let rawInputValue:string = e.target.value
        if (rawInputValue.length > 150) return

        setInputState(rawInputValue)
    }



    if (props.isInputCard) {

        return (
            <>
                {props.promptText &&
                    <div className="FMP-card-master--div" style={{position:'absolute', opacity:0, transform:`translate(0px,${(350*0.7)/2}px)`, pointerEvents:'none'}}>
                        <div ref={mobilePromptGhostRef} className="FMP-card-textbox--div">
                            <p>{props.promptText}</p>
                        </div>
                    </div>
                }

                {isFocusingInputMobile &&
                    <div 
                        style={{position:'absolute', top:0, backgroundColor:'rgb(255 0 0 /10%)', width:'100%', height:'100%',opacity:0}}
                        onTouchMove={()=> cardInputRef.current!.blur()}
                    />
                }
                

                <div
                    className="FMP-card-master--div"
                    style={{
                        transform: transform,
                        zIndex: returnZIndexValue(),
                        transition:transition,
                        pointerEvents: acceptsPointerEvents ? 'auto' : 'none',
                        boxShadow: returnBoxShadowValue(),
                        cursor:'auto'
                    }}
                    onWheel={handleScroll}
                    onClick={()=> {
                        if (props.centeredCardIndex !== 1) return
                        cardInputRef.current!.focus()
                        setTimeout(()=>{setReturnInputCardToHand(true)},100)                 
                    }}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
        
                        <div
                            ref={cardRef}
                            style={{
                                transition:'color 250ms ease-in-out',
                                margin:'20px',
                                textAlign:'left',
                                display:'flex',
                                flexDirection:'column',
                                height:`${350 - 40}px`,
                                color:props.shouldDim?'var(--secondaryText)':''
                            }}
                        >
                            <textarea
                                className="input-unset" 
                                style={{backgroundColor:'transparent', flexGrow:1, overflow:'hidden', overflowWrap:'break-word', pointerEvents:props.centeredCardIndex===1? 'none': 'auto'}}
                                placeholder="type your fill here"
                                onChange={handleInputChange}
                                value={cardInputValue}
                                onKeyPress={e => {
                                    if(e.key !== 'Enter') return

                                    e.preventDefault()
                                    if (cardInputValue.length > 0) {
                                        console.log('ENTER')
                                        cardInputRef.current!.blur()
                                        if (!isMobile) localHandleCardClick({id:props.id, isResult:false, isTie:false, updateFocusedCard:true})
                                    }
                                }}
                                onFocus={() => {
                                    if (!isMobile) return

                                    setIsFocusingInputMobile(true)
                                    setTimeout(() => {
                                        window.scrollTo(0,0)
                                        window.scroll(0,-10)
                                    }, 100)
                                }}
                                onBlur={()=> {
                                    if (!isMobile) return

                                    setIsFocusingInputMobile(false)

                                    if (cardInputValue.length <= 0) return

                                    localHandleCardClick({id:props.id, isResult:false, isTie:false, updateFocusedCard:true})
                                    setTimeout(() => {
                                        window.scrollTo(0,0)
                                        document.body.scrollTop = 0
                                    }, 100)
                                }}
                                autoComplete="off"
                                autoCorrect="off"
                                aria-autocomplete="list"
                                spellCheck="false"
                                enterkeyhint="done"
                                ref={cardInputRef}>
                            </textarea>

                            {props.text}
                            
                            <p style={{transform:'translate(0px,10px)', textAlign:'right', color:'#404040', opacity: wasCardChosen ? 0 : 1}}>{cardInputValue.length}/150</p>
                            
                        </div>

                        {!isMobile && 
                            <p style={{transform:'translate(0px,20px)', color:'#404040', opacity:cardInputValue.length > 0 && !wasCardChosen ? 1 : 0, transition:'opacity 200ms ease-in-out'}}>
                                press enter to submit
                            </p>
                        }
                </div>
            </>
        )
    }
    else {
        return (
            <>
                {showVoteAddedText()}

                <div
                    className={props.type==="fill" ? "FMP-card-master--div": "FMP-card-master--div FMP-prompt-card"}
                    style={{
                        transform: transform,
                        zIndex: returnZIndexValue(),
                        transition:transition,
                        pointerEvents: acceptsPointerEvents ? 'auto' : 'none',
                        cursor: acceptsPointerEvents ? 'cursor' : 'normal',
                        boxShadow: returnBoxShadowValue(),
                    }}
                    onMouseOver={handleMOver}
                    onMouseOut={handleMOut}
                    onWheel={handleScroll}
                    onClick={()=>localHandleCardClick({id:props.id, isResult:false, isTie:false, updateFocusedCard:true})}
                    onTouchStart={handleTouchStart}
                    onTouchMove={handleTouchMove}
                    onTouchEnd={handleTouchEnd}
                >
    
                    {props.type==="prompt" && props.voteResults !== undefined &&
                        showCountTokens()
                    }
                    
                    <div
                        ref={cardRef}
                        className="FMP-card-textbox--div"
                        style={{
                            transition:'color 250ms ease-in-out',
                            pointerEvents:'none',
                            color:props.shouldDim?'var(--secondaryText)':''
                        }}
                    >
                        {props.text}
                        
                    </div>
                </div>
            </>
        )
    }
}

export default Card