import {useLayoutEffect, useRef} from 'react';


interface CalculatedCardTextboxProps {
    text: string,
    pileRef:(height:number) => number | null,
    cardIndex?: number,
    shouldDim: boolean
}

export default function CalculatedCardTextbox(props:CalculatedCardTextboxProps) {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (ref.current){
        props.pileRef(ref.current.clientHeight)
    }
  }, []);

  return (
    <div ref={ref} className="FMP-card-textbox--div" style={{transition:'color 250ms ease-in-out',pointerEvents:'none',color:props.shouldDim?'var(--secondaryText)':''}}>
        {props.text}
    </div>
  );
}
