import { useContext, useEffect, useState } from "react"
import PrematchPlayerCard from "./PrematchPlayerCard"
import { useWindowDimension } from "../fill-my-prompt/utils"
import { isMobile } from 'react-device-detect';
import { matchContext } from "../../App";

interface PrematchPlayerCardsProps {
    cardTransforms: any,
    playersInRoom: any,
    playersInRoomArray: string[],
    newestPlayerToJoin: string,
    playerId: string,
    isCreator:boolean,
    havePlayerCardsAnimatedOn: boolean,
    setHavePlayerCardsAnimatedOn: React.Dispatch<React.SetStateAction<boolean>>,
    showMobilePlayerCards:boolean,
    kickPlayer:(playerId:string) => void
}


export default function PrematchPlayerCards(props:PrematchPlayerCardsProps) {

    const [layoutType, setLayoutType] = useState<string>("")
    const windowSize = useWindowDimension(true)
    const matchContextValues = useContext(matchContext)

    useEffect(() => {
        if (window.innerWidth < 1300 && window.innerWidth > 1000) {
            if (layoutType !== "two-stack") setLayoutType("two-stack")
            return
        }

        if (window.innerWidth < 1000) {
            if (layoutType !== "stack") setLayoutType("stack")
            return
        }

        if (layoutType !== "grid") {
            setLayoutType("grid")
            return
        }

    },[windowSize])
    


    function showStackStyle() {
        let stackStyle: React.CSSProperties = {
            position:'fixed',
            inset:0,
            margin: isMobile? "70px 30px clamp(30px, 400px, 30vh) 0px" : '120px 30px clamp(30px, 400px, 30vh) 470px', 
            display:'grid',
            gridTemplateRows:'repeat(auto-fit,minmax(20px, max-content)',
            placeContent:'center',
            width:isMobile? '100vw' : '',
            transition: 'transform 1200ms cubic-bezier(0.18, 0.58, 0.34, 1.0)'
        }



        if (isMobile) {
            if (props.showMobilePlayerCards) stackStyle.transform = 'translate(0vw , 0vh)'
            else stackStyle.transform = 'translate(100vw , 0vh)'
        }



        return (

            <div style={stackStyle}>
                
                {props.cardTransforms && props.cardTransforms.map((card:any, index:number) => {
                    let keys = Object.keys(props.playersInRoom)
                    let nickname = ""
                    if (props.playersInRoomArray[index]) nickname = props.playersInRoomArray[index]
                    let isNewest = false
                    if (keys[index] === props.newestPlayerToJoin) isNewest = true

                    let stackCard = {
                        y: isMobile? (card.y / 3) : (card.y / 2),
                        x: card.x / 2,
                        rot: card.rot / 2
                    }

                    return(
                        <PrematchPlayerCard
                            key={`${nickname}-${index}`}
                            kickPlayer={props.kickPlayer}
                            isCreator={props.isCreator}
                            placement={stackCard}
                            playerName={nickname}
                            playerId={props.playerId}
                            cardPlayerId={keys[index]}
                            isNewest={isNewest}
                            isPlayer={keys[index] === props.playerId}
                            setHavePlayerCardsAnimatedOn={props.setHavePlayerCardsAnimatedOn}
                            havePlayerCardsAnimatedOn={props.havePlayerCardsAnimatedOn}
                            raiseZIndex={false}
                            showMobilePlayerCards={props.showMobilePlayerCards}
                        />
                    )
                })}
                
            </div>
        )
    }




    function showTwoStackStyle() {
        let stackStyle: React.CSSProperties = {
            position:'fixed',
            inset:0,
            margin: isMobile? "120px 30px clamp(30px, 400px, 30vh) 0px" : '120px 30px clamp(30px, 400px, 30vh) 470px', 
            display:'grid',
            gridTemplateRows:'repeat(auto-fit,minmax(20px, max-content)',
            gridTemplateColumns:'300px 300px',
            placeContent:'center',
            justifyItems:'center'
        }

        return (

            <div style={stackStyle}>
                {props.cardTransforms && props.cardTransforms.map((card:any, index:number)=>{

                    let keys = Object.keys(props.playersInRoom)
                    let nickname = ""
                    if (props.playersInRoomArray[index]) nickname = props.playersInRoomArray[index]
                    let isNewest = false
                    if (keys[index] === props.newestPlayerToJoin) isNewest = true
                    let stackCard = {
                        y: card.y / 2,
                        x: card.x,
                        rot: card.rot
                    }
                    return(
                        <PrematchPlayerCard
                            key={`${nickname}-${index}`}
                            kickPlayer={props.kickPlayer}
                            isCreator={props.isCreator}
                            placement={stackCard}
                            playerName={nickname}
                            playerId={props.playerId}
                            cardPlayerId={keys[index]}
                            isNewest={isNewest}
                            isPlayer={keys[index] === props.playerId}
                            setHavePlayerCardsAnimatedOn={props.setHavePlayerCardsAnimatedOn}
                            havePlayerCardsAnimatedOn={props.havePlayerCardsAnimatedOn}
                            raiseZIndex={false}
                            showMobilePlayerCards={props.showMobilePlayerCards}
                        />
                    )
                })}
                
            </div>
        )
    }



    function showGridStyle() {
        let gridStyle: React.CSSProperties = {
            position:'fixed',
            inset:0,
            margin:'30px 30px 30px 470px',
            display:'grid',
            gridTemplateColumns: '250px 250px 250px',
            gridAutoRows: '350px',
            justifyContent: 'center',
            alignContent: 'center',
            justifyItems: 'center',
            rowGap:'1rem',
            columnGap:'1rem',
            transition: "transform 1500ms cubic-bezier(0.63, 0, 0.34, 1.01)"
        }

        return (
            <div style={gridStyle}>
                {props.cardTransforms && props.cardTransforms.map((card:any, index:number)=>{
                    let keys = Object.keys(props.playersInRoom)
                    let nickname = ""
                    if (props.playersInRoomArray[index]) nickname = props.playersInRoomArray[index]
                    let isNewest = false
                    if (keys[index] === props.newestPlayerToJoin) isNewest = true
                    return(
                        <PrematchPlayerCard
                            key={`${nickname}-${index}`}
                            kickPlayer={props.kickPlayer}
                            isCreator={props.isCreator}
                            placement={card}
                            playerName={nickname}
                            playerId={props.playerId}
                            cardPlayerId={keys[index]}
                            isNewest={isNewest}
                            isPlayer={keys[index] === props.playerId}
                            setHavePlayerCardsAnimatedOn={props.setHavePlayerCardsAnimatedOn}
                            havePlayerCardsAnimatedOn={props.havePlayerCardsAnimatedOn}
                            raiseZIndex={true}
                            showMobilePlayerCards={props.showMobilePlayerCards}
                        />
                    )
                })}
            </div>
        )
    }



    function showPlayersHeader() {
        if (!isMobile) return

        let translateX = 0
        let transition = 'transform 1200ms cubic-bezier(0.18, 0.58, 0.34, 1.0)'
        if (props.showMobilePlayerCards) {
            if (matchContextValues.isInMatch) {
                translateX = -100
                transition = 'transform 1500ms cubic-bezier(0.63, 0, 0.34, 1.01)'
            }
            else translateX = 0
        }
        else translateX = 100
        
        return (
            <h2 className="settingsPanel-header" style={{position:'fixed', top: 0, marginTop:'20px', width:'100vw', textAlign:'center', transform:props.showMobilePlayerCards? `translate(${translateX}vw, 0vh)` : "translate(100vw, 0vh)", transition:transition}}>PLAYERS</h2>
        )
    }



    return (
        <>
            {showPlayersHeader()}

            {layoutType === "stack" && showStackStyle()}
            {layoutType === "two-stack" && showTwoStackStyle()}
            {layoutType === "grid" && showGridStyle()}
        </>
    )
}

