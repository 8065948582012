import {useState, useContext, useEffect} from 'react';
import { useParams, useLocation, useNavigate } from "react-router-dom";
import FillMyPromptRoom from './FillMyPromptRoom'
import {ref, get, update, child, onDisconnect, remove} from "firebase/database";
import {database} from '../../firebase-connect'
import { getRandomNicknameAdjective, getRandomNicknameNoun, isOnlyLetters } from '../fill-my-prompt/utils';
import { matchContext } from '../../App'



interface MatchRoomGatekeeperProps{
    playerId:string,
}

interface LocationState{
    nickname:string,
    isCreator:boolean,
}


function MatchRoomGatekeeper(props:MatchRoomGatekeeperProps){
    let matchContextValues = useContext(matchContext)
    const locationState = useLocation().state as LocationState
    const [isRoomCreated, setIsRoomCreated] = useState<boolean>(false)
    const [hasCheckedRoomCreated, setHasCheckedRoomCreated] = useState<boolean>(false)
    const [nickname, setNickname] = useState<string>(locationState ? locationState.nickname : "")
    const [isCreator, setIsCreator] = useState<boolean>(locationState ? locationState.isCreator : false)
    const navigate = useNavigate()

    var { roomId } = useParams() || ""
    roomId = roomId ?? ""
    roomId = roomId.toUpperCase()

    var isRoomIdValid = false
    if (roomId.length === 4 && isOnlyLetters(roomId)) isRoomIdValid = true
    


    useEffect(() => {
        if (!isRoomCreated) return
        if (matchContextValues.roomId === ''){

            // GATEKEEPER CLEARING PLAYER DATA FROM ROOM
            console.log('CLEAR ')

            setTimeout(()=>{
                remove(ref(database,`matchesData/${roomId}/playerProgress/${props.playerId}`))
                remove(ref(database,`matchesData/${roomId}/playerList/${props.playerId}`))
                remove(ref(database,`matchesData/${roomId}/playerList/${props.playerId}/nickname`))
                remove(ref(database,`matchesData/${roomId}/didVote/${props.playerId}`))
                remove(ref(database,`matchesData/${roomId}/players/${props.playerId}/nickname`))
                if (isCreator) remove(ref(database,`matchesData/${roomId}/creator/${props.playerId}`))
            },1000)

        }
    },[matchContextValues.roomId])

    useEffect(()=> {
        if (!isRoomIdValid) navigate('/', { state: { messagePopup: `u wot m8`} })
        window.scrollTo({ top: 0, behavior: 'smooth' });
        setTimeout(()=> window.scroll(0,-10),50)
        

        if (isRoomIdValid && !hasCheckedRoomCreated) {
            document.body.style.overflow = "hidden"
            document.body.style.position = "inherit"


            get(child(ref(database), `matchesMeta/${roomId}`))
                .then((snapshot) => {
                    if (!snapshot.exists()) {
                        navigate('/', { state: { messagePopup: `no room called: ${roomId}`} })
                        return
                    }
                    if (snapshot.val() === true) {
                        navigate('/', { state: { messagePopup: `unable to join a match in progress`} })
                        return
                    }
                    

                    matchContextValues.setRoomId!(roomId!)
                    const updates: any = {}
                    
                    if (nickname !== "") {
                        let adjective = getRandomNicknameAdjective()
                        updates[`players/${props.playerId}/nickname`] = `${adjective} ${nickname}`
                        updates[`players/${props.playerId}/room`] = roomId
                        populateDB(updates, `${adjective} ${nickname}`)
                        return
                    }
    
                    let nicknameToSave = nickname
                    get(child(ref(database), `players/${props.playerId}`))
                        .then((snapshot) => {
                            if (snapshot.exists()) nicknameToSave = `${getRandomNicknameAdjective()} ${snapshot.val().nickname.split(" ").at(-1)}`
                            else nicknameToSave = `${getRandomNicknameAdjective()} ${getRandomNicknameNoun()}`

                            setNickname(nicknameToSave)
                            updates[`players/${props.playerId}/nickname`] = nicknameToSave
                            updates[`players/${props.playerId}/room`] = roomId
                            populateDB(updates, nicknameToSave)
                        })
                    
                    
                })
                .catch((error) => {
                    console.error(error)
                })
        }
    },[])



    function populateDB(updates:any, nickNameToSave:string) {
        setIsRoomCreated(true)

        updates[`matchesData/${roomId}/players/${props.playerId}/nickname`] = nickNameToSave
        update(ref(database),updates)
        update(ref(database,`matchesData/${roomId}/playerProgress`),{[props.playerId]:0})
        update(ref(database,`matchesData/${roomId}/playerList/${props.playerId}`),{nickname:nickNameToSave})

        onDisconnect(ref(database,`matchesData/${roomId}/playerProgress/${props.playerId}`)).remove()
        onDisconnect(ref(database,`matchesData/${roomId}/playerList/${props.playerId}`)).remove()
        onDisconnect(ref(database,`matchesData/${roomId}/players/${props.playerId}/nickname`)).remove()

        setHasCheckedRoomCreated(true)
    }

    
    

    function gatekeep(){
        if (!isRoomIdValid) return
        if (!hasCheckedRoomCreated) return


        return (
            <FillMyPromptRoom
                roomId={roomId!}
                playerId={props.playerId}
                isRoomCreated={isRoomCreated}
                isCreator={isCreator}
            />
        )
    }



    return(
        <div>
            {gatekeep()}
        </div>
    )
}

export default MatchRoomGatekeeper


