export const timeouts = {
    HOMEPAGE_TO_ROOM: 500,
    ROOM_TO_MATCH:1000,
    SHOULD_LEAVE : 1000,
    ADVANCE_ROUND_NUMBER : 1500,
    CARD_ANIMATE_ONSCREEN : 1000,
    RESULTS_VOTE_COUNT_WAIT : 1500
    // LINGER_ON_WINNING_CARD_BEFORE_NEXT_TIMEOUT
}

export const themes:any = {
    'classic':{
        // '--bgColor':'#171717',
        // '--fillColor':'#1A1A1A',
        '--bgColor':'#1a1a1a',
        '--fillColor':'#1f1f1f',
        '--fillTextColor':'#E7E7E7',
        '--fillBorderColor':'#444444',
        '--promptColor':'#080808',
        '--promptTextColor':'#E7E7E7',
        '--disabledColor':'#333333',
        '--highlightColor':'#FF863F',
        '--secondaryTextColor':'#444444'
    },
    'blackAndWhite':{
        '--bgColor':'white',
        '--fillColor':'white',
        '--fillTextColor':'black',
        '--fillBorderColor':'black',
        '--promptColor':'black',
        '--promptTextColor':'white',
        '--disabledColor':'grey',
        '--highlightColor':'#ffb100'
    },
    'blue':{
        '--bgColor':'#001222',
        '--fillColor':'#001222',
        '--fillTextColor':'#4A82B6',
        '--fillBorderColor':'#4A82B6',
        '--promptColor':'#4A82B6',
        '--promptTextColor':'#001222',
        '--disabledColor':'#416280',
        '--highlightColor':'#75A8D7'
    },
}