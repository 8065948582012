import { useContext } from "react"
import { matchContext, matchContextInterface } from "../../App"
import PlayerResultTile from "./PlayerResultTile"


interface ResultsPanelProps {
    translate: string,
    transition:string,
    winners:any,
    losers:any,
    playerPlacement:any
    playerId:string,
    shouldViewScores:boolean,
    setShouldViewScores: React.Dispatch<React.SetStateAction<boolean>>
}




export default function ResultsPanel(props:ResultsPanelProps) {

    const matchContextValues:matchContextInterface = useContext(matchContext)

    function showPlayerTileGroup(group:any) {
        if (!group) return
        return (
            <div style={{display:'flex', flexDirection:'column', rowGap:'10px'}}>
                {Object.keys(group).map((currentPlayerId)=> {
                    return <PlayerResultTile key={currentPlayerId} playerResult={group[currentPlayerId]} isCurrentPlayer={props.playerId === currentPlayerId}/>
                })}
            </div>
        )
    }



    function returnPlacementText() {
        switch(props.playerPlacement.place) {
            case 1:
                return '1st'
            case 2:
                return '2nd'
            case 3:
                return '3rd'
            default:
                return`${props.playerPlacement.place}th`
        }
    }



    return (
        <div className="settingsPanel-master" style={{transform:props.translate, 'transition': props.transition, zIndex:'2', width:'100%', margin:'0px', height:'100vh', backgroundColor:'transparent'}}>
            <div style={{position:'absolute', top:'80px', width:'100%', zIndex:1, fontSize:'20px', fontWeight:'bold'}}>
                {props.playerPlacement.didTie ? 
                <span>you tied for </span>
                : <span>you came in </span>
                }

                <span style={{color:'var(--highlight)'}}>{returnPlacementText()} place!</span>
            </div>
            {props.shouldViewScores && <div className="settingsPanel-content">

                <div className="settingsPanel-settings" style={{overflowY:'scroll'}}>
                    <div style={{height:'80px', width:'10px', flex:'none'}}>
                    </div>

                    <h4 className="settingsPanel-subHeader">{Object.keys(props.winners).length > 1? 'winners' : 'winner'}</h4>
                    <div className="settingsPanel-settingsSection" style={{paddingBottom:'10px'}}>
                        {showPlayerTileGroup(props.winners)}
                    </div>

                    {Object.keys(props.losers).length > 0 &&
                        <>
                            <h4 className="settingsPanel-subHeader" >{Object.keys(props.losers).length > 1? 'losers' : 'loser'}</h4>
                            <div className="settingsPanel-settingsSection" style={{}}>
                                    {showPlayerTileGroup(props.losers)}

                                    {/* below is used to test scroll / overflow behaviour with multiple losers */}
                                    {/* {showLosers()}
                                    {showLosers()}
                                    {showLosers()}
                                    {showLosers()}
                                    {showLosers()}
                                    {showLosers()}
                                    {showLosers()}  */}
                                
                                    <div>
                                        <div style={{height:'10rem'}}></div>
                                    </div>
                            </div>
                        </>
                    }

                </div>
            </div>}
            
            <div style={{position:'absolute', bottom:0, height:'30vh', width:'100%', background: 'linear-gradient(0deg, var(--bg) 40%, transparent)', borderRadius:'10px', pointerEvents:'none'}}/>
            <div style={{position:'absolute', top:0, height:'120px', width:'100%', background: 'linear-gradient(180deg, var(--bg) 40%, transparent)', borderRadius:'10px', pointerEvents:'none'}}/>

            <div style={{position:'absolute', bottom:0, width:'calc(100% - 60px)', margin:'0px 30px 30px 30px'}}>
                <div className="settingsPanel-optionRow">
                    <button className="settingsPanel-optionRow-ActionButton" style={{backgroundColor:'var(--fill)'}} onClick={()=>matchContextValues.setShouldDoHomeWipe!({shouldWipe:true, reason:""})}>leave</button>
                </div>
            </div>

        </div>
    )
}