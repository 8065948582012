// // Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";

// console.log('0000000000000000-----------------------00000000000000000')
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signInAnonymously, onAuthStateChanged, connectAuthEmulator  } from "firebase/auth";
import { getDatabase, ref, set, get, onDisconnect, onValue, DataSnapshot  } from "firebase/database";

// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
let firebaseConfig:any = {
  apiKey: "AIzaSyDiXNNHuc9vyTxolK3eIRsfZ0OvH7A2Pi0",
  authDomain: "webgameproject-a3f56.firebaseapp.com",
  databaseURL: "https://webgameproject-a3f56-default-rtdb.firebaseio.com",
  projectId: "webgameproject-a3f56",
  storageBucket: "webgameproject-a3f56.appspot.com",
  messagingSenderId: "651173390245",
  appId: "1:651173390245:web:a15313fcfbf4d0fdedea4c",
  measurementId: "G-LYQ2FYP00Q"
};

if (window.location.hostname === 'localhost'){
  firebaseConfig = {
    databaseURL:"http://localhost:9000?ns=webgameproject-a3f56-default-rtdb",
    // apiKey: "AIzaSyDiXNNHuc9vyTxolK3eIRsfZ0OvH7A2Pi2",
    authDomain: "webgameproject-a3f56.firebaseapp.com",

    projectId: "webgameproject-a3f56",
    appId: "1:651173390245:web:a15313fcfbf4d0fdedea4c",
    apiKey: "AIzaSyDiXNNHuc9vyTxolK3eIRsfZ0OvH7A2Pi0",
  }
}

// // Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);
export const analytics = getAnalytics(firebaseApp);




export const auth = getAuth(firebaseApp);
if (window.location.hostname === 'localhost'){
  connectAuthEmulator(auth, 'http://localhost:9099')
}






// export const auth = auth1

export const database = getDatabase(firebaseApp);



// var promptFillOptions:string[][] = []
// var matchPrompts:string[] = []


// function getRandom(arr:string[], n:number) {
//     var result = new Array(n),
//         len = arr.length,
//         taken = new Array(len);
//     if (n > len)
//         throw new RangeError("getRandom: more elements taken than available");
//     while (n--) {
//         var x = Math.floor(Math.random() * len);
//         result[n] = arr[x in taken ? taken[x] : x];
//         taken[x] = --len in taken ? taken[len] : len;
//     }
//     return result;
// }
// var allFillsSnapshot:DataSnapshot
// var matchPromptsSnapshot:DataSnapshot
// async function populatePlayerMatchData(playerId:string){
//     let playerRef = ref(database, `matches/aaaa/players/${playerId}`)
//     let matchPromptsRef = ref(database, `matches/aaaa/prompts`)
//     let allFillsRef = ref(database, `fills/0`)
//     console.log(playerId)

    

//     let allFills:string[] = []
//     allFillsSnapshot = await get(allFillsRef).catch(e => console.log(e)) as DataSnapshot
//     allFills = allFillsSnapshot.val();
//     console.log('allFills:')
//     console.log(allFills)
    

    
//     var matchPromptsSnapshot = await get(matchPromptsRef).catch(e => console.log(e)) as DataSnapshot
//     matchPrompts = matchPromptsSnapshot.val()
//     console.log('matchPrompts:')
//     console.log(matchPrompts)

//     let fillsToUse = getRandom(allFills,4*matchPrompts.length)
//     let num = 0
//     let tempFillContainer:string[] = []

//     fillsToUse.forEach((fill, index)=>{
//         num = num + 1
//         tempFillContainer.push(fill)

//         if (num == 4){
//             promptFillOptions.push(tempFillContainer)
//             tempFillContainer = []
//             num = 0
//         }

        
//     })

//     console.log(promptFillOptions)
    
    
//     set(playerRef, {
//         points: 0,
//         promptFillOptions
//     });

//     onDisconnect(playerRef).remove();
// }


// signInAnonymously(auth).catch(error => {
//     console.log(error)
// });

// onAuthStateChanged(auth, (user) => {
//     // console.log(`user: ${user}`)
//     console.log(user)
//     if (user) {
//         let playerId = user.uid
        
//         populatePlayerMatchData(playerId)
        
        
        
//         // User is signed in, see docs for a list of available properties
//         // https://firebase.google.com/docs/reference/js/firebase.User
        
//         // ...
//         } else {
//         // User is signed out
//         // ...
//         }
        
// });

// // let promptFillOptionsPromise = new Promise((resolve, reject) => {
// //     if (promptFillOptions.length > 1){
// //         resolve('ok')
// //     }
// //     else{
// //         reject('nope')
// //     }
// // })


// export {populatePlayerMatchData, matchPromptsSnapshot}


