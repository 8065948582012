/* eslint-disable react-hooks/exhaustive-deps */
import {useContext, useEffect, useRef, useState} from 'react';
import {analytics, database} from '../../firebase-connect'
import {ref, set, get, push, update, child, onDisconnect, onValue} from "firebase/database";
import FillMyPrompt, {FillMyPromptsProps} from '../Match/FillMyPrompt'
import {shuffle} from 'lodash'
import { matchContext } from '../../App'
import { timeouts } from '../fill-my-prompt/config';
import { getRandomFromArray, getRandomNumberAbsolute} from '../fill-my-prompt/utils';
import { isMobile } from 'react-device-detect';
import SettingsPanel from '../Prematch/SettingsPanel';
import PrematchPlayerCards from '../Prematch/PrematchPlayerCards';
import SettingsPanelMobile from '../Prematch/SettingsPanelMobile';
import { logEvent } from 'firebase/analytics';



interface FMPRoomProps{
    roomId:string,
    playerId:string,
    isRoomCreated:boolean,
    isCreator:boolean,
}

export interface MatchSettings{
    promptCount:number,
    fillOptionCount:number,
    matchTimeLimit:number,
    contentRatingIndex: number,
    gameMode: number
}



function FillMyPromptRoom(props:FMPRoomProps){

    let matchContextValues = useContext(matchContext)
    const maxPlayerAmount = 6
    const [playersInRoom,setPlayersInRoom] = useState<any>({})
    const [storedPlayersInRoom,setStoredPlayersInRoom] = useState<any>({})
    const playersInRoomRef = useRef()
    playersInRoomRef.current = playersInRoom
    const [matchSettings, setMatchSettings] = useState<MatchSettings>({promptCount:5, fillOptionCount:5, matchTimeLimit:0, contentRatingIndex:0, gameMode:0})
    const [isRoomAnimatedIn, setIsRoomAnimatedIn] = useState<boolean>(false)
    const isRoomAnimatedInRef = useRef<boolean>(false)
    const [shouldStartMatch, setShouldStartMatch] = useState<boolean>(false)
    const [isMatchStarted, setIsMatchStarted] = useState<boolean>(false)
    const [matchPrompts, setMatchPrompts] = useState<string[]>([])
    const [matchFills, setMatchFills] = useState<string[][]>([])
    const [otherPlayersProgress, setOtherPlayersProgress] = useState<object>()
    const [canStartVoting, setCanStartVoting] = useState<boolean>(false)
    const [allPlayerFillData, setAllPlayerFillData] = useState<Object>({})
    const [playersVoted, setPlayersVoted] = useState<string[]>([])
    const [voteResults, setVoteResults] = useState<any>()
    const [settingsPanelTranslate, setSettingsPanelTranslate] = useState<any>({x:0,y:100})
    const [settingsPanelTransition, setSettingsPanelTransition] = useState<string>("transform 1200ms cubic-bezier(0.18, 0.58, 0.34, 1.01)")
    const [showMobilePlayerCards, setShowMobilePlayerCards] = useState<boolean>(false)
    const [cardTransforms, setCardTransforms] = useState<any>()
    const [newestPlayerToJoin, setNewestPlayerToJoin] = useState<string>("")
    const [havePlayerCardsAnimatedOn, setHavePlayerCardsAnimatedOn] = useState<boolean>(false)
    const [playersInRoomArray, setPlayersInRoomArray] = useState<string[]>([])
    const [hidePrematchPlayerCards, setHidePrematchPlayerCards] = useState<boolean>(false)
    const isRoomFullRef = useRef<boolean>(false)
    


    useEffect(() =>{
        if (!props.isRoomCreated) return

        setTimeout(()=>{setSettingsPanelTranslate({x:0,y:0})},250)
        setCardTransforms(generateCardTransforms())
        setTimeout(()=> {
            setIsRoomAnimatedIn(true)
            isRoomAnimatedInRef.current = true
            console.log('ANIMTED IN NOW')
        },1550)
    
        window.onpopstate = () => {
            clearDatabaseData()
        }

        const playersRef = ref(database, `matchesData/${props.roomId}/playerList/`);

        onValue(playersRef, (snapshot) => {
            const databasePlayers = snapshot.val();
            setPlayersInRoom( (prevPlayersInRoom:any) => {
                let updatedPlayers = {...prevPlayersInRoom}
                let prevPlayersList = Object.keys(prevPlayersInRoom)
                let updatedPlayersList = Object.keys(databasePlayers)
                
                // a player left the room
                if (prevPlayersList.length > updatedPlayersList.length) {
                    let playerWhoLeft = prevPlayersList.filter(x => !updatedPlayersList.includes(x));
                    setPlayersInRoomArray(prevPlayersInRoomArray => {
                        
                        playerWhoLeft.forEach((playerId)=> {
                            let indexToRemove = prevPlayersInRoomArray.indexOf(prevPlayersInRoom[playerId].nickname)
                            if (indexToRemove !== -1) prevPlayersInRoomArray.splice(indexToRemove, 1)
                        })

                        let newPlayersInRoomArray = [...prevPlayersInRoomArray]
                        console.log('a')
                        console.log(props.isCreator, isRoomFullRef.current, newPlayersInRoomArray.length, maxPlayerAmount)
                        if (props.isCreator && isRoomFullRef.current && newPlayersInRoomArray.length < maxPlayerAmount) {
                            isRoomFullRef.current = false
                            if (!isMatchStarted){
                                unlockRoom()
                                console.log("unlocked room!")
                            }
                        }
                        return newPlayersInRoomArray
                    })
                    playerWhoLeft.forEach((playerId) => delete updatedPlayers[playerId])
                    console.debug(`${playerWhoLeft} LEFT!`)
                }

                // a player joined the room
                else {
                    let newPlayers = updatedPlayersList.filter(x => !prevPlayersList.includes(x))
                    let newPlayerNicknames:string[] = []
                    newPlayers.forEach((playerId)=>{
                        updatedPlayers[playerId] = databasePlayers[playerId]
                        newPlayerNicknames.push(databasePlayers[playerId].nickname)
                    })
                    let newest = newPlayers.at(-1)
                    if (newest) setNewestPlayerToJoin(newest)
                    setStoredPlayersInRoom(updatedPlayers)
                    setPlayersInRoomArray(prevPlayersInRoomArray => {
                        let newPlayersInRoomArray = [...prevPlayersInRoomArray, ...newPlayerNicknames]
                        if (props.isCreator && newPlayersInRoomArray.length >= maxPlayerAmount) {
                            isRoomFullRef.current = true
                            if (!isMatchStarted){
                                lockRoom()
                                console.log("locked room!")
                            }
                        }

                        return newPlayersInRoomArray
                    })
                    console.debug(`${newPlayers} JOINED!`)
                }

                return updatedPlayers
            })

        });

        if (!props.isCreator) {

            onValue(ref(database, `matchesData/${props.roomId}/players/${props.playerId}/fills`), (snapshot) => {
                let snapshotVal = snapshot.val();
                if (snapshotVal) setMatchFills(snapshotVal)
            });

            onValue(ref(database, `matchesData/${props.roomId}/prompts`), (snapshot) => {
                let snapshotVal = snapshot.val();
                if (snapshotVal){
                    // set state for prompts
                    setMatchPrompts(snapshotVal) 
                    if (isRoomAnimatedInRef.current) {
                        setSettingsPanelTransition("transform 1500ms cubic-bezier(0.63, 0, 0.34, 1.01)")
                        setSettingsPanelTranslate({x:-100, y:0})
                        setTimeout(()=>{setIsMatchStarted(true)},timeouts.ROOM_TO_MATCH)
                        matchContextValues.setIsInMatch!(true)
                    }
                    else {
                        console.log("ok", props.isCreator)
                    }
                }
            });

            onValue(ref(database, `matchesData/${props.roomId}/matchSettings`), (snapshot) => {
                let snapshotVal = snapshot.val();
                if (snapshotVal) setMatchSettings({...matchSettings, ...snapshotVal})
            });

            get(child(ref(database),`matchesData/${props.roomId}/matchSettings`)).then((snapshot) => {
                if (!snapshot.exists()) return console.log("No data available");
                setMatchSettings({...matchSettings, ...snapshot.val()})

            })
        }

        onValue(ref(database, `matchesData/${props.roomId}/playerProgress`), (snapshot) => {
            let snapshotVal = snapshot.val();
            if (snapshotVal) setOtherPlayersProgress(snapshotVal)
        });

        onValue(ref(database, `matchesData/${props.roomId}/didVote`), (snapshot) => {
            let snapshotVal = snapshot.val();
            if (snapshotVal) setPlayersVoted(Object.keys(snapshotVal))
        });
    },[])



    useEffect(()=> {
        if (!isRoomAnimatedIn) return
        if (!shouldStartMatch) return

        setSettingsPanelTransition("transform 1500ms cubic-bezier(0.63, 0, 0.34, 1.01)")
        setSettingsPanelTranslate({x:-100, y:0})
        setTimeout(()=>{setIsMatchStarted(true)},timeouts.ROOM_TO_MATCH)
        matchContextValues.setIsInMatch!(true)

    },[isRoomAnimatedIn])



    useEffect(()=> {
        if (!matchContextValues.shouldDoHomeWipe?.shouldWipe) return
        setTimeout(()=> clearDatabaseData() ,500)
    },[matchContextValues.shouldDoHomeWipe])



    function clearDatabaseData() {
        if (Object.keys(playersInRoomRef.current??{}).length === 1) {
            console.debug("you're the last player in the room!")

            // remove all remaining data from room
            update(ref(database,`matchesData/${props.roomId}`),{"prompts":null})
            update(ref(database,`matchesData/${props.roomId}`),{"votes":null})
            update(ref(database,`matchesData/${props.roomId}`),{"playerProgress":null})
            update(ref(database,`matchesData/${props.roomId}`),{"didVote":null})
            update(ref(database,`matchesData/${props.roomId}`),{"matchSettings":null})
            update(ref(database,`matchesMeta`),{[props.roomId]:null})
        }

        // remove self from playerList and players
        if (props.isCreator) update(ref(database,`matchesData/${props.roomId}/creator`),{[props.playerId]:null})
        update(ref(database,`matchesData/${props.roomId}/playerProgress`),{[props.playerId]:null})
        update(ref(database,`matchesData/${props.roomId}/playerList`),{[props.playerId]:null})
        update(ref(database,`matchesData/${props.roomId}/players`),{[props.playerId]:null})
        update(ref(database,`players/${props.playerId}`),{"room":""})

        matchContextValues.setRoomId!('')
    }



    function kickPlayer(playerId:string) {
        if (!props.isCreator) return
        console.debug('SHOULD KICK', playerId)

        update(ref(database,`matchesData/${props.roomId}/playerProgress`),{[playerId]:null})
        update(ref(database,`matchesData/${props.roomId}/playerList`),{[playerId]:null})
        update(ref(database,`matchesData/${props.roomId}/players`),{[playerId]:null})
    }



    useEffect(()=>{
        if (Object.keys(playersInRoom).length > 0 && !Object.keys(playersInRoom).includes(props.playerId)) { 
            console.debug(playersInRoom, 'WAS KICKED')
            update(ref(database,`players/${props.playerId}`),{"room":""})
            matchContextValues.setShouldDoHomeWipe!({shouldWipe:true, reason:"the host kicked you"})
        }
    },[playersInRoom])



    useEffect(()=> {
        console.debug('UPDATE THE MATCH SETTINGS DB')

        update(ref(database,`matchesData/${props.roomId}/matchSettings`), matchSettings)
    },[matchSettings])



    useEffect(()=>{
        if (!isMatchStarted) return
        if (!otherPlayersProgress) return

        checkEveryonesProgress(otherPlayersProgress)
    },[otherPlayersProgress])



    useEffect(()=>{
        if (playersVoted.length === 0) return

        if (playersVoted.length === Object.keys(playersInRoom).length) {
            console.debug('EVERYONE IS DONE VOTING! SHOW RESULTS')

            get(child(ref(database),`matchesData/${props.roomId}/votes`)).then((snapshot) => {
                if (!snapshot.exists()) return console.log("No data available");
                
                let snapshotVal = snapshot.val()
                let voteResults:any = {}

                Object.keys(snapshotVal).forEach(roundIndex => {
                    let roundObj = snapshotVal[roundIndex]
                    let votes:any = {}

                    Object.keys(roundObj).forEach(voteKey=>{
                        if (roundObj[voteKey].vote in votes) votes[roundObj[voteKey].vote] = votes[roundObj[voteKey].vote] + 1
                        else votes[roundObj[voteKey].vote] = 1
                    })
                    voteResults[roundIndex] = votes 
                })

                setVoteResults(voteResults)

            }).catch((error) => {
                console.error(error);
            });
        }
    },[playersVoted])



    useEffect(()=> {
        if (!matchContextValues.isInMatch) return

        setTimeout(()=>{setHidePrematchPlayerCards(true)}, 1500) 
    },[matchContextValues.isInMatch])



    function lockRoom() {
        set(ref(database,`matchesMeta/${props.roomId}`), true)
    }

    function unlockRoom() {
        set(ref(database,`matchesMeta/${props.roomId}`), false)
    }



    function checkEveryonesProgress(progObj:any){
        let allReadyToVote = true
        Object.values(progObj).forEach((round:any) => {
            if (round < matchPrompts.length) allReadyToVote = false
        })

        if (allReadyToVote) {
            console.log('------- everyone else is at voting stage')
            setCanStartVoting(true)
        }
    }



    function generateAllCards(){
        const cardDistributionUpdates:any = {};
        let allPrompts:any = []

        get(child(ref(database), "prompts")).then((snapshot) => {
            if (!snapshot.exists()) return console.log("No data available");

            allPrompts = snapshot.val()
            let rawPromptsToSubmit:string[] = []

            // if playing standard pick-fills game mode
            if (matchSettings.gameMode === 0) {
                if (matchSettings.contentRatingIndex === 0) allPrompts = allPrompts['sfw']
                else allPrompts = allPrompts['sfw'].concat(allPrompts['nsfw']) 
            }
            // if playing type-your-own-fills game mode
            else {
                if (matchSettings.contentRatingIndex === 0) allPrompts = allPrompts['sfw'].concat(allPrompts['typeyourown']['sfw'])
                else allPrompts = allPrompts['sfw'].concat(allPrompts['nsfw']).concat(allPrompts['typeyourown']['sfw']).concat(allPrompts['typeyourown']['nsfw'])
            }

            
            if (matchSettings.gameMode === 0) {
                rawPromptsToSubmit = getRandomFromArray(allPrompts, matchSettings.promptCount)
            }
            else {
                let shuffledPrompts = shuffle(allPrompts)
                for (let i = 0; i < shuffledPrompts.length; i++) {
                    if (shuffledPrompts[i].split('_').length < 3) {
                        rawPromptsToSubmit.push(shuffledPrompts[i])
                        if (rawPromptsToSubmit.length === matchSettings.promptCount) break
                    }
                    
                }
            }

           
            let promptsToSubmit:any = []
            let totalBlankCount = 0
            rawPromptsToSubmit.forEach(prompt => {
                let tagsRaw:string[] = prompt.match(/\[(.*?)\]/g) ?? []
                let promptTags = tagsRaw.map((tag)=>{
                    prompt = prompt.replace(tag,'')
                    return tag.slice(1,-1).split(',')
                })

                let fillsNeeded:number = (prompt.match(/_/g) || ['']).length
                totalBlankCount += fillsNeeded
                prompt = prompt.replace(/_/g, '________')
                promptsToSubmit.push({prompt, blanks:fillsNeeded, tags:promptTags})
            })

            cardDistributionUpdates[`matchesData/${props.roomId}/prompts/`] = promptsToSubmit
            
            // set state for prompts
            setMatchPrompts(promptsToSubmit)



            if (matchSettings.gameMode === 0) {
                let fillPath = matchSettings.contentRatingIndex === 0 ? "fills/sfw" : "fills"
                get(child(ref(database), fillPath))
                    .then((snapshot) => {
                        if (!snapshot.exists()) return console.log("No data available");

                        let allFills = snapshot.val()

                        // if content rating is nsfw, combine the sfw and nsfw fill arrays
                        if (matchSettings.contentRatingIndex === 1) {
                            let tempCombinedAllFills:any = []
                            Object.keys(allFills['sfw']).forEach((fillCategory) => {
                                tempCombinedAllFills[fillCategory] = allFills['sfw'][fillCategory].concat(allFills['nsfw'][fillCategory])
                            })
                            allFills = tempCombinedAllFills
                        }
                        
                        
                        let allFillsBackup = {...allFills}
                        Object.keys(allFills).forEach((fillCategory) => allFills[fillCategory] = shuffle(allFills[fillCategory]))

                        // distribute fills for each player
                        Object.keys(playersInRoom).forEach((_playerId, index) =>{

                            let matchFills:string[][] = []

                            // distribute based on how many prompts are set for the match
                            for (let i = 0; i < promptsToSubmit.length; i += 1) {
                                let promptBlankCount = promptsToSubmit[i].blanks
                                let roundFills:string[] = []

                                // assign fills required per round (depending on how many blanks the prompt has)
                                for (let blankIndex = 0; blankIndex < promptBlankCount; blankIndex += 1) {

                                    // randomly assign viable fills that fit the blank's tag to roundFill list
                                    for (let x = 0; x < matchSettings.fillOptionCount; x += 1) {
                                        let typeToAdd:string = getRandomFromArray(promptsToSubmit[i].tags[blankIndex],1)[0]
                                        let modifier:string[]|undefined = undefined
                                        if (typeToAdd.includes("-")) {
                                            let tempTypeToAdd = typeToAdd.split("-")
                                            typeToAdd = tempTypeToAdd[0]
                                            tempTypeToAdd.splice(0,1)
                                            modifier = tempTypeToAdd
                                            // [typeToAdd,modifier] = typeToAdd.split("-")
                                        }
                                        let fillToAdd:string = allFills[typeToAdd].pop()
                                        


                                        if (fillToAdd === undefined){

                                            allFills[typeToAdd] = [...allFillsBackup[typeToAdd]]
                                            fillToAdd = allFills[typeToAdd].pop()                                            
                                        }

                                        if (modifier) {

                                            fillToAdd = fillToAdd.split(" ")
                                                .map((word) => {
                                                    if (modifier?.includes('he')) {
                                                        if (word === "i") return "he"
                                                        if (word === "my") return "his"
                                                    }
                                                    else if (modifier?.includes('she')) {
                                                        if (word === "i") return "she"
                                                        if (word === "my") return "her"
                                                    }
                                                    else if (modifier?.includes('you')) {
                                                        if (word === "i") return "you"
                                                        if (word === "my") return "your"
                                                    }
                                                    else if (modifier?.includes('they')) {
                                                        if (word === "i") return "they"
                                                        if (word === "my") return "their"
                                                    }
                                                    else if (word.includes("[")) {
                                                        if (modifier?.includes('p')) {
                                                            if (word.includes("[]")) return ""
                                                            if (word.includes("[")) return word.split("[")[1].split("]")[0]
                                                        } else {
                                                            return word.split("[")[0]
                                                        }
                                                    }
                                                    return word
                                                })
                                                .join(" ")
                                        }

                                        if (fillToAdd.includes("[")) {
                                            fillToAdd = fillToAdd.split(" ")
                                            .map((word) => {
                                                if (word.includes("[")) return word.split("[")[0]
                                                return word
                                            })
                                            .join(" ")
                                        }

                                        roundFills.push(fillToAdd)
                                    }
                                }
                                matchFills.push(roundFills)
                            }

                            // if round fills are mine, set state
                            if (_playerId === props.playerId) setMatchFills(matchFills)
                            
                            cardDistributionUpdates[`matchesData/${props.roomId}/players/${_playerId}/fills`] = matchFills
                            onDisconnect(ref(database,`matchesData/${props.roomId}/players/${_playerId}/fills`)).remove()
                        })

                        // ok push card distribution updates for match prompts and player fills
                        update(ref(database),cardDistributionUpdates)
                        

                    }).catch((error) => {
                        console.error(error);
                    });
            } else {
                // ok push card distribution updates for match prompts and player fills
                update(ref(database),cardDistributionUpdates)
            }
        }).catch((error) => {
            console.error(error);
        });
    }
    


    function getAllSubmittedFills(){
        get(child(ref(database,`matchesData/${props.roomId}`),`players`))
            .then((snapshot) => {
                if (!snapshot.exists()) return console.log("No data available");
                
                let result = snapshot.val()
                setAllPlayerFillData(result)
            }).catch((error) => {
            console.error(error);
        });
    }



    function submitVotesToDatabase(votes:string[], roundNumber:number){
        votes.forEach((vote, index) => {
            set(push(ref(database,`matchesData/${props.roomId}/votes/${index}`)),{vote})
        })
        update(ref(database,`matchesData/${props.roomId}/didVote`),{[props.playerId]:true})
        update(ref(database,`matchesData/${props.roomId}/playerProgress`),{[props.playerId]:roundNumber+1})
        onDisconnect(ref(database,`matchesData/${props.roomId}/didVote/${props.playerId}`)).remove()
        onDisconnect(ref(database,`matchesData/${props.roomId}/votes`)).remove()
    }



    function startMatch() {
        console.log('start Match!')

        generateAllCards()

        if (matchSettings.matchTimeLimit !== 0) {
            set(ref(database,`matchesData/${props.roomId}/matchSettings`), {
                matchTimeLimit:matchSettings.matchTimeLimit
            })
        }

        setSettingsPanelTransition("transform 1500ms cubic-bezier(0.63, 0, 0.34, 1.01)")
        setSettingsPanelTranslate({x:-100, y:0})
        setTimeout(()=>{setIsMatchStarted(true)},timeouts.ROOM_TO_MATCH)
        matchContextValues.setIsInMatch!(true)
        set(ref(database,`matchesMeta/${props.roomId}`), true)

        logEvent(analytics, `matchSettings - content rating - ${matchSettings.contentRatingIndex}`)
        logEvent(analytics, `matchSettings - fill option count - ${matchSettings.fillOptionCount}`)
        logEvent(analytics, `matchSettings - game mode - ${matchSettings.gameMode}`) 
        logEvent(analytics, `matchSettings - match time limit - ${matchSettings.matchTimeLimit}`) 
        logEvent(analytics, `matchSettings - round count - ${matchSettings.promptCount}`) 
        logEvent(analytics, `startMatch - player count - ${playersInRoomArray.length}`)
    }



    function generateCardTransforms() {
        let placedTransforms = []

        for (let i = 0; i < 6; i++) {
            let randX = getRandomNumberAbsolute(0,30,true)
            let randY = getRandomNumberAbsolute(0,30,true)
            let randRot = getRandomNumberAbsolute(0,5,true)

            placedTransforms.push({
                x:randX,
                y:randY,
                rot:randRot
            })
        }

        return placedTransforms
    }



    async function submitChosenCards(fills:string[], roundNumber:number){
        let playerSubmittedRef = ref(database, `matchesData/${props.roomId}/players/${props.playerId}/submittedFills/${roundNumber}`)
        update(ref(database,`matchesData/${props.roomId}/playerProgress`),{[props.playerId]:roundNumber+1})
        set(playerSubmittedRef, {...fills});
        onDisconnect(ref(database,`matchesData/${props.roomId}/players/${props.playerId}/submittedFills/`)).remove()
    }



    let fmpProps:FillMyPromptsProps = {
        matchPrompts,
        matchFills,
        submitChosenCards,
        matchRoundCount:matchPrompts.length,
        canStartVoting,
        getAllSubmittedFills,
        allPlayerFillData,
        submitVotesToDatabase,
        voteResults,
        playerId:props.playerId,
        playersInRoom:storedPlayersInRoom,
        matchTimeLimit:matchSettings.matchTimeLimit,
        otherPlayersProgress:otherPlayersProgress,
        matchSettings
    }




    if (!props.isRoomCreated) return <div>{`Room with id: ${props.roomId} has been closed or doesn't exist`}</div>
    else {

        return(
            <div>
                <div className="fmpRoom-prematchDisplay-master--div">
                    <div className="fmpRoom-prematchDisplay--div">
                        {!isMobile && <SettingsPanel matchSettings={matchSettings} setMatchSettings={setMatchSettings} startMatch={startMatch} isCreator={props.isCreator} translate={settingsPanelTranslate} transition={settingsPanelTransition}/>}
                        
                        {isMobile && 
                            <>
                                <SettingsPanelMobile matchSettings={matchSettings} setMatchSettings={setMatchSettings} startMatch={startMatch} isCreator={props.isCreator} translate={settingsPanelTranslate} transition={settingsPanelTransition} setShowMobilePlayerCards={setShowMobilePlayerCards} showMobilePlayerCards={showMobilePlayerCards}/>
                            </>
                        }

                        {!hidePrematchPlayerCards && <PrematchPlayerCards kickPlayer={kickPlayer} isCreator={props.isCreator} cardTransforms={cardTransforms} playersInRoom={playersInRoom} playersInRoomArray={playersInRoomArray} newestPlayerToJoin={newestPlayerToJoin} playerId = {props.playerId} havePlayerCardsAnimatedOn={havePlayerCardsAnimatedOn} setHavePlayerCardsAnimatedOn={setHavePlayerCardsAnimatedOn} showMobilePlayerCards={showMobilePlayerCards}/> } 
                    </div>
                </div>
                {matchPrompts.length > 0 && (matchFills.length > 0 || matchSettings.gameMode === 1) && isMatchStarted && <FillMyPrompt {...fmpProps}/>}
            </div>
        )
    }
}

export default FillMyPromptRoom