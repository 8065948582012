import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { getRandomNumberAbsolute } from "../fill-my-prompt/utils";

interface ResultsCardProps {
    type:string,
    text:string,
    clusterIndex:number,
    x:number,
    y:number,
    pileRef?:(height:number) => number | null,
}


export default function ResultsCard(props:ResultsCardProps){
    let cardRotation = useMemo(()=> {
        if (props.type === 'prompt') return getRandomNumberAbsolute(0,0.5, true)
        return getRandomNumberAbsolute(0,2, true)
    },[])

    const ref = useRef<HTMLDivElement>(null);
    const [transformTransitionTime, setTransformTransitionTime] = useState<number>(1000 + ((props.clusterIndex+1) * 400))



    useEffect(() => {
        setTimeout(()=>{
            if (props.type === "fill") setTransformTransitionTime(750)
            else setTransformTransitionTime(700)
        }, 10)
    }, [])



    useLayoutEffect(() => {
        if (ref.current){
            props.pileRef!(ref.current.clientHeight)
        }
    }, []);



    return(
        <>
            <div
                className = {props.type === 'prompt' ? "FMP-card-master--div FMP-prompt-card" : "FMP-card-master--div"}
                style={{
                    width: '250px',
                    height: '350px',
                    transform:`translate(${props.x}px, ${props.y}px) rotate(${cardRotation}deg)`,
                    transition: `transform ${transformTransitionTime}ms cubic-bezier(0.21, 0.84, 0.16, 1)`,
                    boxShadow:props.type==="fill"?'0 0 0 2px var(--fillBorder)':'',
                    zIndex:props.type==="prompt"?-1:0,
                    pointerEvents:'none'
                }}
            >
                <div ref={ref} className="FMP-card-textbox--div" >{props.text}</div>
                
            </div>
        </>
    )
}