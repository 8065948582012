import { useEffect, useState } from "react"
import { isMobile } from "react-device-detect"
import { MatchSettings } from "../MatchRoom/FillMyPromptRoom"

interface RoundInfoTextProps{
    roundNumber:number,
    matchRoundCount:number,
    canStartVoting:boolean,
    canStartResults:boolean,
    matchTimeLimit:number,
    forceNextRound:() => void,
    otherPlayersProgress: any,
    playersInRoom: any,
    playerId: string,
    matchSettings:MatchSettings
}

const timerArray = [0,10,20,30]


function RoundInfoText(props:RoundInfoTextProps){
    
    const [translation,setTranslation] = useState<string>('translate(0px, 120vh)')
    const [roundTimeCounter, setRoundTimeCounter] = useState<number>(timerArray[props.matchTimeLimit])
    const [activeRoundTimer, setActiveRoundTimer] = useState<NodeJS.Timeout>()
    const [roundTextOpacity, setRoundTextOpacity] = useState<number>(1)
    const [roundText, setRoundText] = useState<string>("")
    const [isWaiting, setIsWaiting] = useState<boolean>(false)
    const [roundTypeSlidingTransformY, setRoundTypeSlidingTransformY] = useState<number>(0)
    const [roundNames, setRoundNames] = useState<string[]>([])



    useEffect(()=>{
        setTimeout(()=>{setTranslation('translate(0px, 0px)')},50)

        if (isMobile) setRoundNames(["FILL","VOTE","COUNT","RESULTS"])
        else {
            if (props.matchSettings.gameMode === 0) setRoundNames(["CHOOSE YOUR FILL","VOTE FOR THE BEST","COUNTING VOTES","MATCH RESULTS"])
            else setRoundNames(["TYPE YOUR FILL","VOTE FOR THE BEST","COUNTING VOTES","MATCH RESULTS"])
        }
    },[])



    useEffect(()=>{
        if (!props.canStartVoting) return

        setIsWaiting(false)
        setRoundTextOpacity(1)
        setRoundTimeCounter(timerArray[props.matchTimeLimit])
        if (props.matchTimeLimit !== 0) setRoundText(`prompt ${(props.roundNumber+1) - props.matchRoundCount} of ${props.matchRoundCount}`)
        else setRoundText(`prompt ${(props.roundNumber+1) - props.matchRoundCount} of ${props.matchRoundCount}`)
        advanceRoundTypeSlide()
    },[props.canStartVoting])



    useEffect(()=>{
        if (!props.canStartResults) return

        setIsWaiting(false)
        setRoundTextOpacity(1)
        setRoundText(`prompt ${(props.roundNumber+1) - (props.matchRoundCount*2)} of ${props.matchRoundCount}`)
        advanceRoundTypeSlide()
    },[props.canStartResults])



    useEffect(() => {

        // FILL ROUNDS
        if (props.roundNumber + 1 <= props.matchRoundCount){
            if (props.matchTimeLimit !== 0) setRoundText(`prompt ${props.roundNumber+1} of ${props.matchRoundCount}`)
            else setRoundText(`prompt ${props.roundNumber+1} of ${props.matchRoundCount}`)
        }

        // VOTE ROUNDS
        if (props.roundNumber + 1 > props.matchRoundCount && props.roundNumber + 1 <= props.matchRoundCount*2){
            if (props.canStartVoting) {
                setIsWaiting(false)
                if (props.matchTimeLimit !== 0) setRoundText(`prompt ${(props.roundNumber+1) - props.matchRoundCount} of ${props.matchRoundCount}`)
                else setRoundText(`prompt ${(props.roundNumber+1) - props.matchRoundCount} of ${props.matchRoundCount}`)
            }
            else setIsWaiting(true)
        }

        // VOTE COUNT ROUNDS
        if (props.roundNumber + 1 > props.matchRoundCount*2 && props.roundNumber + 1 <= props.matchRoundCount*3){
            if (props.canStartResults) {
                setIsWaiting(false)
                setRoundText(`prompt ${(props.roundNumber+1) - (props.matchRoundCount*2)} of ${props.matchRoundCount}`)
            }
            else setIsWaiting(true)
        }

        // RESULTS
        if (props.roundNumber + 1 > props.matchRoundCount*3){
            advanceRoundTypeSlide()
            setRoundTextOpacity(0)
            setRoundText(`prompt ${(props.roundNumber) - (props.matchRoundCount*2)} of ${props.matchRoundCount}`)
        }

        if (props.matchTimeLimit === 0) return
        
        clearTimeout(activeRoundTimer)

        if (!props.canStartVoting && props.roundNumber >= props.matchRoundCount) return
        
        setRoundTimeCounter(timerArray[props.matchTimeLimit])
    },[props.roundNumber])



    useEffect(()=> {
        if (!isWaiting) return

        let playersWeWaitFor:string[] = []
        Object.keys(props.otherPlayersProgress).forEach((otherPlayerId:string) => {
            if (otherPlayerId === props.playerId) return
            if (props.otherPlayersProgress[otherPlayerId] < props.roundNumber) playersWeWaitFor.push(props.playersInRoom[otherPlayerId].nickname)
        })

        if (playersWeWaitFor.length === 1) setRoundText(`waiting for ${playersWeWaitFor[0].toUpperCase()} to finish`)
        else if (playersWeWaitFor.length === 2) setRoundText(`waiting for ${playersWeWaitFor[0].toUpperCase()} & ${playersWeWaitFor[1].toUpperCase()} to finish`)
        else if (playersWeWaitFor.length > 2) {
            let outputString = ""
            for (let i = 0; i < playersWeWaitFor.length; i++) {
                if (i === playersWeWaitFor.length - 1){
                    outputString += `& ${playersWeWaitFor[i].toUpperCase()}`
                    return
                }
                outputString += `${playersWeWaitFor[i].toUpperCase()}, `
            }
            setRoundText(`waiting for ${outputString} to finish`)
        }
    },[props.otherPlayersProgress, isWaiting])



    useEffect(()=>{
        if (props.matchTimeLimit === 0) return
        if (props.matchRoundCount === 0) return
        if (props.canStartResults) return
        if (roundTimeCounter === 0){
            props.forceNextRound()
            return
        }
        const timer = setTimeout(()=>{
            setRoundTimeCounter((prevTime)=>{
                return prevTime - 1 
            })
        },1000)

        setActiveRoundTimer(timer)
    },[roundTimeCounter])



    function advanceRoundTypeSlide() {
        setTimeout(()=> {
            setRoundTypeSlidingTransformY(prev => prev - 3)
        },200)
    }



    function showTimerText() {
        if (props.matchTimeLimit === 0) return ''
        if (props.roundNumber + 1 > props.matchRoundCount*2) return ''
        if (props.roundNumber + 1 > props.matchRoundCount && !props.canStartVoting) return ''

        if (isMobile) {
            return <h1 style={{fontSize:'2rem',lineHeight: '2rem', position:'absolute', top:0, right:0, marginRight:'30px', transformOrigin:'top right'}}>{roundTimeCounter}</h1>
        }

        return (
            <h1 style={{lineHeight: '3rem', position:'absolute', top:0, right:0, marginRight:'30px', transform:`scale(${1 + scaleTimerSize()})`, transformOrigin:'top right'}}>{roundTimeCounter}</h1>
        )
    }



    function returnTransition() {
        if (props.roundNumber === 0) {
            return 'transform 1000ms cubic-bezier(0.18, 0.38, 0.05, 1), margin 1000ms cubic-bezier(0.18, 0.38, 0.05, 1)'
        }

        return 'transform 1200ms cubic-bezier(0.18, 0.58, 0.34, 1.01), margin 1200ms cubic-bezier(0.34, 0.01, 0.21, 1)'
    }



    function returnRoundTextOpacityTransition() {
        if (props.roundNumber + 1 > props.matchRoundCount*3) {
            return "opacity 500ms cubic-bezier(0.18, 0.38, 0.05, 1)"
        }

        return "opacity 0ms cubic-bezier(0.18, 0.38, 0.05, 1)"
    }



    function returnMargin() {
        if (props.roundNumber + 1 > props.matchRoundCount*3 && !isMobile) {
            return '60px 30px 0px 470px'
        }

        return "30px 0px 0px 0px"
    }



    function scaleTimerSize() {
        let startScalingSeconds = 10
        let scale = Math.max(0,startScalingSeconds - roundTimeCounter) / 10
        return scale/2
    }
 


    let roundTypeSlidingStyle:React.CSSProperties = {
        margin:0,
        fontSize: isMobile? '3rem' : '4rem',
        lineHeight: '3rem',
        transform:`translate(0px,${roundTypeSlidingTransformY}rem)`,
        whiteSpace: 'nowrap',
        transition:'transform 600ms cubic-bezier(0.14, 0.1, 0, 1)'
    }

    
    
    return(
        <div className="FMP-roundInfo--div" style={{transform:translation, transition:returnTransition(), margin: returnMargin()}}>
            <div style={{margin:'auto',width:'100%', height:'3rem', backgroundColor:'', overflow:'hidden'}}>
                <h1 style={roundTypeSlidingStyle}>{roundNames[0]}</h1>
                <h1 style={roundTypeSlidingStyle}>{roundNames[1]}</h1>
                <h1 style={roundTypeSlidingStyle}>{roundNames[2]}</h1>
                <h1 style={roundTypeSlidingStyle}>{roundNames[3]}</h1>
            </div>

            {showTimerText()}

            <h3 style={{opacity:roundTextOpacity, transition:returnRoundTextOpacityTransition(), marginTop:isMobile?'':'1rem', fontSize:isMobile?'':'1.5rem'}}>{roundText}</h3>
        </div>
    )

}

export default RoundInfoText



