import React, {useEffect,useState, useContext, useRef} from 'react'
import ResultsCard from './ResultsCard';
import { matchContext, matchContextInterface } from '../../App';
import ResultsPanelMobile from './ResultsPanelMobile';

function getRoundWinnersFromVotes(votes: any) {
	let winners: any[] = []
	Object.keys(votes).forEach((roundIndex) => {
		let roundObj: any = votes[roundIndex]
		const mostVotes = Object.entries(roundObj).reduce((bestEntry: any, thisEntry: any) =>
			thisEntry[1] > bestEntry[1] ? thisEntry : bestEntry
		)[1]

		let userWithMostVotes = Object.keys(roundObj).filter((key) => roundObj[key] === mostVotes)
		winners.push(userWithMostVotes)
	})

	return winners
}



interface resultsProps{
	playerId:string,
	votes:any,
	players:any,
	playerNames:any,
	prompts:any,

}

function Results(props:resultsProps){
	const [currentRoundIndex, setCurrentRoundIndex] = useState<number>(0)
	const [roundWinners, setRoundWinners] = useState<string[]>()
	const [roundCards, setRoundCards] = useState<any>()
	const [cardsYOffset, setCardsYOffset] = useState<number>(window.innerHeight + 100)
	const [losers, setLosers] = useState<any>({})
	const [winners, setWinners] = useState<any>({})
	const [playerPlacement, setPlayerPlacement] = useState({didTie:false, place:0})
	const matchContextValues:matchContextInterface = useContext(matchContext)
	const [resultsPanelXOffset, setResultsPanelXOffset] = useState<number>(-50)
	const [shouldViewScores, setShouldViewScores] = useState<boolean>(true)

	const renderCounter  = useRef(0);
	renderCounter.current = renderCounter.current + 1;
    console.log('>>>>>> ', renderCounter.current)


	const pileRef = useRef<number[][][] | null>([])
    console.log(pileRef)


	useEffect(() => {
		setRoundWinners(()=>{
			let roundWinners = getRoundWinnersFromVotes(props.votes)
			createCardClusterData(roundWinners)
			return roundWinners
		});

		setTimeout(() => {setCardsYOffset(0) }, 5)
		setTimeout(() => {setResultsPanelXOffset(0)},50)

		matchContextValues.setIsInMatch!(false)
	}, []);



	function createCardClusterData(roundWinners:string[][]){
        if (!roundWinners) return
        console.log(props.playerNames)
        let allToShow:any = []

        let playerScores:any = []
		let playerScoresObject:any = {}
		let topPoints = 0
		let winningPlayerIds:string[] = []
        Object.keys(props.playerNames).forEach((playerId:string) => {

            let nickname = props.playerNames[playerId].nickname
            let points = 0
			let wins = 0
			let ties = 0
			let votes = 0

			Object.keys(props.votes).forEach((round:any)=> {
				if (playerId in props.votes[round]) votes += props.votes[round][playerId]
			})
                
            for (let i = 0; i < roundWinners.length; i++) {
                if (roundWinners[i].length === 1 && roundWinners[i][0] === playerId) {
					wins += 1
				}
                else if (roundWinners[i].includes(playerId)){ 
					ties += 1
				}
            }

			points = (votes*1) + (ties*5) + (wins*10)

            playerScores.push([nickname,points])
			playerScoresObject[playerId] = {
				nickname:nickname,
				points:points,
				wins:wins,
				ties:ties,
				votes:votes
			}


			if (points === topPoints) {
				winningPlayerIds.push(playerId)
			}

			else if (points > topPoints) {
				winningPlayerIds = []
				winningPlayerIds.push(playerId)
				topPoints = points
			}
            
        })

		let tempPlayerPlacement = {
			didTie:false,
			place:0
		}

        playerScores.sort((first:any, second:any) => second[1] - first[1])

		
		let winners:any = {}
			winningPlayerIds.forEach((winningPlayerId:string)=>{
				winners[winningPlayerId] = playerScoresObject[winningPlayerId]
				delete playerScoresObject[winningPlayerId]
			})
		setWinners(winners) 
		
		setLosers(playerScoresObject)

		if (Object.keys(winners).includes(props.playerId)) {
			if (Object.keys(winners).length >= tempPlayerPlacement.place) tempPlayerPlacement.place = 1
			if (Object.keys(winners).length > 1) {
				if (tempPlayerPlacement.place === 1) tempPlayerPlacement.didTie = true
				else {
					tempPlayerPlacement.place = (tempPlayerPlacement.place + 1) - Object.keys(winners).length
				}
			}
		}
		else {
			let tempPointAmount = -1
			let tempPlacesToAdd = 1
			Object.keys(playerScoresObject).forEach((id, index)=> {
				if (playerScoresObject[id].points === tempPointAmount) {
					if (id === props.playerId || playerScoresObject[id].points === playerScoresObject[props.playerId].points) tempPlayerPlacement.didTie = true
				}
				else {
					tempPlacesToAdd += 1
					tempPointAmount = playerScoresObject[id].points
				}
				if (id === props.playerId) tempPlayerPlacement.place += tempPlacesToAdd 
			})
			
		}
		setPlayerPlacement(tempPlayerPlacement)
		
		let initialRoundCards: any[] = []
		let initialTiePromptClusterIndicies:any = {}

        roundWinners.forEach((roundWinnerArray, roundIndex)=>{
			if (roundWinners[roundIndex].length > 1) {
				initialTiePromptClusterIndicies[roundIndex] = allToShow.length
			}

			initialRoundCards.push([])
            roundWinners[roundIndex].forEach((user, index)=>{
				initialRoundCards[roundIndex].push({
                    key:`${roundIndex}-${user}`,
                    round:roundIndex,
                    prompt:props.prompts[roundIndex],
                    fills:props.players[user].submittedFills[roundIndex],
                    user:user,
                    nickname:props.playerNames[user].nickname,
                    didUserWin:roundWinners[roundIndex].length === 1
                })

            })
			setRoundCards(initialRoundCards)
        })
    }



	function handleScroll(event:any) {
		if (event.deltaY > 0) {
			console.debug('NEXT >>')
			setCurrentRoundIndex(prev => {
				if (roundWinners?.length === undefined) return prev
				if (prev + 1 < roundWinners.length) return prev + 1

				return prev
			})
			
		}
		else {
			console.debug('<< PREV')
			setCurrentRoundIndex(prev => {
				if (roundWinners?.length === undefined) return prev
				if (prev - 1 >= 0) return prev - 1

				return prev
				
			})
		}
	}


	function showScrollArrow() {

		let arrowParentStyle:React.CSSProperties = {
			position:'absolute',
			transform:'scale(150%,100%) rotate(-90deg)',
			width:'calc(100vw - 500px)',
			margin:'0px 30px 0px 470px',
			bottom:'60px',
			display:'flex',
			justifyContent:'center',
			opacity: currentRoundIndex === 0 ? "1" : "0",
			transition: `opacity 300ms cubic-bezier(0.21, 0.84, 0.16, 1)`
		}
		
		return (
			<div style={arrowParentStyle}>
				<svg
				style={{fill:'#404040', height:'4rem'}}
				viewBox="5 0 20 20" 
				preserveAspectRatio="none">
					<path d="M8.388,10.049l4.76-4.873c0.303-0.31,0.297-0.804-0.012-1.105c-0.309-0.304-0.803-0.293-1.105,0.012L6.726,9.516c-0.303,0.31-0.296,0.805,0.012,1.105l5.433,5.307c0.152,0.148,0.35,0.223,0.547,0.223c0.203,0,0.406-0.08,0.559-0.236c0.303-0.309,0.295-0.803-0.012-1.104L8.388,10.049z"></path>
				</svg>
			</div>
		)
	}

	function showRoundTabs() {
		if (shouldViewScores) return

		return (
			<div style={{marginLeft:'30px', height:'100vh', display:'flex', flexDirection:'column', justifyContent: 'center', rowGap:'20px'}} >
					{roundWinners && roundWinners.map((winners:any, roundIndex:number) => {
						let status = "lose"
						if (winners.length > 1) {
							if (winners.includes(props.playerId)) status = "tie"
						} else {
							if (winners.includes(props.playerId)) status = "win"
						}

						let color = ""
						if (currentRoundIndex < roundIndex) color = "var(--prompt)"
						if (currentRoundIndex > roundIndex) color = "#808080"
						if (currentRoundIndex === roundIndex) color = "white"

						let statusTranslation = currentRoundIndex === roundIndex ? "" : "translate(0px,-50%)"
						let statusOpacity = currentRoundIndex < roundIndex ? 0 : 1

						let roundTranslation = currentRoundIndex === roundIndex ? "" : "translate(0px,-50%)"
						let roundOpacity = currentRoundIndex === roundIndex ? 1 : 0
						
						return (
							<div style={{display:'flex', width:'fit-content', cursor:'pointer'}} onClick={()=>setCurrentRoundIndex(roundIndex)}>
								<div style={{width:'5px', height:'45px', marginRight:'10px', borderRadius:'10px', backgroundColor:color}} />
								<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-start', fontSize:'1.1rem' }}>
									<b style={{transform:roundTranslation, opacity:roundOpacity, transition:'opacity 500ms cubic-bezier(0.21, 0.84, 0.16, 1), transform 500ms cubic-bezier(0.21, 0.84, 0.16, 1)'}}>round {roundIndex + 1}</b>
									<p style={{color:color, transform:statusTranslation, opacity:statusOpacity, transition:'opacity 500ms cubic-bezier(0.21, 0.84, 0.16, 1), transform 500ms cubic-bezier(0.21, 0.84, 0.16, 1), color 500ms cubic-bezier(0.21, 0.84, 0.16, 1)'}}>{status}</p>
								</div>
							</div>
						)
					})}
					
				</div>
		)
	}
	
	const cardWidth = 250


	
	return (
		<div style={{  width: "100%", height: "100%", overflow: "hidden" }} onWheel={handleScroll}>

			{/* {showScrollArrow()} */}

			<div style={{position:'absolute', width:'100vw', height:'100vh', top:0, transform:`translate(${resultsPanelXOffset}vw,0px)`, transition:'transform 1200ms cubic-bezier(0.18, 0.58, 0.34, 1.01)'}}>

				<ResultsPanelMobile translate={`translate(0px,0px)`} transition={''} winners={winners} losers={losers} playerPlacement={playerPlacement} playerId={props.playerId} shouldViewScores={shouldViewScores} setShouldViewScores={setShouldViewScores}/>

				{/* {showRoundTabs()} */}
				
			</div>


			{/* <div style={{marginTop:'100px', position:'absolute', marginLeft:'220px'}}>

				{!shouldViewScores && roundCards && roundCards.map((round:any, roundIndex:number)=>{
					if (pileRef.current![roundIndex] === undefined) pileRef.current![roundIndex] = []
					let baselineY = 0
					let shouldUseTransition = true
					let clusterTextTransitionTime = 800
					let roundY = 0
					if (roundIndex > currentRoundIndex) roundY = window.innerHeight + 100
					if (roundIndex < currentRoundIndex) roundY = - (window.innerHeight + 100)

					
					return (
						<div>
							{roundCards[roundIndex].map((cardCluster:any, clusterIndex:number) => {
								if (pileRef.current![roundIndex][clusterIndex] === undefined) pileRef.current![roundIndex][clusterIndex] = []
								let centerOffset = 0

								return (
									<div style={{transform:`translate(-${centerOffset}px,0px)`}}>
										<h3
										style={{
											width:cardWidth,
											position:'absolute',
											transform:`translate(${window.innerWidth/2 - cardWidth/2}px,${baselineY - 80 + cardsYOffset + roundY}px)`,
											transition: shouldUseTransition ? `transform ${clusterTextTransitionTime}ms cubic-bezier(0.21, 0.84, 0.16, 1)` : '',
											fontSize:'1.5rem',
											zIndex:-2,
											fontWeight: props.playerNames[props.playerId].nickname === cardCluster.nickname ? "bold" : "normal"
										}}>
												{cardCluster.nickname} 
										</h3>


										<ResultsCard
										pileRef={(el:number) => (pileRef.current![roundIndex][clusterIndex][0] = el)}
										type='prompt'
										text={cardCluster.prompt.prompt}
										clusterIndex={clusterIndex}
										cardCluster={cardCluster}
										x={window.innerWidth/2 - cardWidth/2}
										y={cardsYOffset + baselineY + roundY}
										opacity={1}
										didUserWin={cardCluster.didUserWin}
										shouldUseTransition={shouldUseTransition}
										/>
										

										{cardCluster.fills.map((fill:string, fillIndex:number)=>{
											let prev = 0
											pileRef.current![roundIndex][clusterIndex].forEach((height, heightIndex)=>{
												if (heightIndex < fillIndex + 1) prev += height + 40
											})

											let currentClusterFillOffset = prev

											return (
												<>
													<ResultsCard
													pileRef={(el:number) => (pileRef.current![roundIndex][clusterIndex][fillIndex+1] = el)}
													key={cardCluster.key + `-${fillIndex}`}
													type='fill'
													text={fill}
													clusterIndex={clusterIndex}
													cardCluster={cardCluster}
													x={window.innerWidth/2 - cardWidth/2}
													y={currentClusterFillOffset + cardsYOffset + baselineY + roundY}
													opacity={1}
													didUserWin={cardCluster.didUserWin}
													shouldUseTransition={shouldUseTransition} />
												</>
											)
										})}
									</div>
								)
							})}
						</div>
					)
				})}
			</div> */}

		</div>
	)
}






export default Results