import { Dispatch, SetStateAction, useContext, useState } from "react"
import {MatchSettings} from '../MatchRoom/FillMyPromptRoom'
import { matchContext } from "../../App";


interface SettingsPanelProps {
    matchSettings: MatchSettings,
    setMatchSettings: Dispatch<SetStateAction<MatchSettings>>,
    showMobilePlayerCards: boolean,
    setShowMobilePlayerCards: Dispatch<SetStateAction<boolean>>,
    startMatch:() => void,
    isCreator: boolean,
    translate: any,
    transition:string
}


export default function SettingsPanel(props: SettingsPanelProps) {
    const [selectedGameModeIndex, setSelectedGameModeIndex] = useState<number>(0)
    const [selectedPromptCountIndex, setSelectedPromptCountIndex] = useState<number>(1)
    const [selectedRoundTimeLimitIndex, setSelectedRoundTimeLimitIndex] = useState<number>(0)
    const [selectedContentRatingIndex, setSelectedContentRatingIndex] = useState<number>(0)
    const mobileXTranslate = props.showMobilePlayerCards ? -100 : 0
    let matchContextValues = useContext(matchContext)
    


    function updatePromptCount(optionIndex:number) {
        let promptCountOptions = [3,5,10]
        setSelectedPromptCountIndex(optionIndex)
        props.setMatchSettings(prevMatchSettings => {
            return {...prevMatchSettings, promptCount:promptCountOptions[optionIndex]}
        })
    }



    function updateRoundTimeLimit(optionIndex:number) {
        setSelectedRoundTimeLimitIndex(optionIndex)
        props.setMatchSettings(prevMatchSettings => {
            return {...prevMatchSettings, matchTimeLimit:optionIndex}
        })
    }



    function updateSelectedGameMode(optionIndex:number) {
        setSelectedGameModeIndex(optionIndex)
        props.setMatchSettings(prevMatchSettings => {
            return {...prevMatchSettings, gameMode:optionIndex}
        })
    }



    function updateContentRatingLimit(optionIndex:number) {
        setSelectedContentRatingIndex(optionIndex)
        props.setMatchSettings(prevMatchSettings => {
            return {...prevMatchSettings, contentRatingIndex:optionIndex}
        })
    }


    function showGameModeButtons(selectedIndex:number) {
        let gameModeIndex = props.matchSettings.gameMode
        let transformTransition = "transform 500ms cubic-bezier(0.12, 0.45, 0.18, 1), opacity 500ms cubic-bezier(0.12, 0.45, 0.18, 1)"
        let leftCardTransform = gameModeIndex === 0 ? "rotate(-6deg)" : "translate(5px,0px) rotate(-4deg)"
        let middleCardTransform = gameModeIndex === 0 ? "translate(0px,-5px)" : ""
        let rightCardTransform = gameModeIndex === 0 ? "rotate(6deg)" : "translate(-5px,0px) rotate(4deg)"
        let newCardTransform = gameModeIndex === 1 ? "" : "translate(0px,2px) scale(0.95)"


        return (
            <div className="settingsPanel-optionRow">
                <button className= {gameModeIndex === 0 ? "settingsPanel-optionRow-Button optionRowButtonSelected" : "settingsPanel-optionRow-Button"} style={{'cursor':props.isCreator ? 'pointer': 'auto'}} onClick={()=>props.isCreator && updateSelectedGameMode(0)}>
                    <div className="settingsPanel-gameMode-Button">
                        <div style={{'display':'flex', 'justifyContent':'center', 'marginBottom':'20px'}}>
                            <div className={gameModeIndex === 0 ? "settingsPanel-gameMode-Card optionRowButtonSelected" : "settingsPanel-gameMode-Card"} style={{'transform':leftCardTransform, 'borderColor':selectedIndex === 0 ? "var(--promptText)" : "", "transition":transformTransition}} />
                            <div className={gameModeIndex === 0? "settingsPanel-gameMode-Card optionRowButtonSelected" : "settingsPanel-gameMode-Card"} style={{transform:middleCardTransform,'margin':'0px -10px', 'zIndex':1, 'borderColor':selectedIndex === 0 ? "var(--promptText)" : "", "transition":transformTransition}} />
                            <div className={gameModeIndex === 0 ? "settingsPanel-gameMode-Card optionRowButtonSelected" : "settingsPanel-gameMode-Card"} style={{'transform':rightCardTransform, 'zIndex':2, 'borderColor':selectedIndex === 0 ? "var(--promptText)" : "", "transition":transformTransition}} />
                        </div>
                        <p className={gameModeIndex === 0 ? "settingsPanel-gameMode-text optionRowButtonSelected" : "settingsPanel-gameMode-text"}> pick <b>fills</b> from a random selection </p>
                    </div>
                </button>
                
                <button className={gameModeIndex === 1 ? "settingsPanel-optionRow-Button optionRowButtonSelected" : "settingsPanel-optionRow-Button"} style={{'cursor':props.isCreator ? 'pointer': 'auto'}} onClick={()=>props.isCreator && updateSelectedGameMode(1)}>
                    <div className="settingsPanel-gameMode-Button">
                        <div style={{'display':'flex', 'justifyContent':'center', 'marginBottom':'20px', position:'relative'}}>
                            <div className={gameModeIndex === 1 ? "settingsPanel-gameMode-Card optionRowButtonSelected" : "settingsPanel-gameMode-Card"} style={{'borderColor':selectedIndex === 1 ? "var(--promptText)" : "", "transform":newCardTransform, "transition":transformTransition}} />
                            <div
                                style={{
                                    position:'absolute',
                                    backgroundColor:gameModeIndex === 1 ? 'var(--bg)' : 'var(--prompt)',
                                    transform:gameModeIndex === 1 ? 'translate(15px,-10px)' : 'translate(15px,-6px) scale(0.8)',
                                    width:'1.2rem',
                                    transition:transformTransition,
                                }}
                            >
                                +
                            </div>

                        </div>
                        <p className={gameModeIndex === 1 ? "settingsPanel-gameMode-text optionRowButtonSelected" : "settingsPanel-gameMode-text"}> type your own <b>fill</b> for each <b>prompt</b> </p>
                    </div>
                </button>
            </div>
        )
    }
    


    function showPromptCountButtons(selectedIndex:number) {
        let promptCountOptions = [3,5,10]
        let promptCount = props.matchSettings.promptCount
        return (
            <div className="settingsPanel-optionRow">
                {promptCountOptions.map((option, index)=> {
                    let cursor = 'auto'
                    if (props.isCreator && selectedIndex !== index) cursor = "pointer"
                    
                    return (
                        <button key={option} className={promptCount === promptCountOptions[index] ? "settingsPanel-optionRow-Button optionRowButtonSelected" : "settingsPanel-optionRow-Button"} style={{'cursor':cursor}} onClick={()=>props.isCreator && updatePromptCount(index)}>{option}</button>
                    )
                })}
            </div>
        )
    }
    



    function showRoundTimeLimitButtons(selectedIndex:number) {
        let options = ["none","10s","20s","30s"]
        let matchTimeLimitIndex = props.matchSettings.matchTimeLimit
        return (
            <div className="settingsPanel-optionRow">
                {options.map((option, index)=> {
                    let cursor = 'auto'
                    if (props.isCreator && selectedIndex !== index) cursor = "pointer"
                        
                    return (
                        <button key={option} className={matchTimeLimitIndex === index ? "settingsPanel-optionRow-Button optionRowButtonSelected" : "settingsPanel-optionRow-Button"} style={{'cursor':cursor}} onClick={()=>props.isCreator && updateRoundTimeLimit(index)}>{option}</button>
                    )
                })}
            </div>
        )
    }



    function showContentRatingButtons(selectedIndex:number) {
        let options = ["safe","mature"]
        let contentRatingIndex = props.matchSettings.contentRatingIndex

        return (
            <div className="settingsPanel-optionRow">
                {options.map((option, index)=> {
                    let cursor = 'auto'
                    if (props.isCreator && selectedIndex === index) cursor = "pointer"

                    return (
                        <button key={option} className={contentRatingIndex === index ? "settingsPanel-optionRow-Button optionRowButtonSelected" : "settingsPanel-optionRow-Button"} style={{'cursor':cursor}} onClick={()=>props.isCreator && updateContentRatingLimit(index)}>{option}</button>
                    )
                })}
            </div>
        )
    }




    return (
        <div style={{display:'flex', flexDirection:'column', height:'calc(100% - 40px)',width:'calc(100% - 40px)', justifyContent:'space-between', margin: "20px 20px", rowGap:'20px'}}>
            <div style={{flexShrink:2, minHeight:'0px', display:'flex', flexDirection:'column',transform:`translate(${props.translate.x + mobileXTranslate}vw, ${props.translate.y}vh)`, transition: props.transition}}>
                <h2 className="settingsPanel-header" style={{fontSize:'1.5rem', marginBottom:'10px'}}>FILL MY PROMPT <span style={{fontWeight:'normal', color:'#808080'}}>/ {matchContextValues.roomId}</span></h2> 
                <div className="settingsPanel-master" style={{position:'inherit',margin:"0px",width: "100%", overflow:"scroll", height:'max-content', maxHeight:'calc(100vh - 40px)'}}>
                    <div className="settingsPanel-content" style={{rowGap: 20, overflow:'scroll', padding:"20px", height:"auto"}}>
                        
                        
                        <div className="settingsPanel-settings">
                            <h4 className="settingsPanel-subHeader" >game mode</h4>
                            <div className="settingsPanel-settingsSection" style={{paddingBottom:"5px"}}>
                                {showGameModeButtons(selectedGameModeIndex)}
                            </div>

                            <h4 className="settingsPanel-subHeader" >prompt count</h4>
                            <div className="settingsPanel-settingsSection" style={{paddingBottom:"5px"}}>
                                    {showPromptCountButtons(selectedPromptCountIndex)}
                            </div>

                            <h4 className="settingsPanel-subHeader" >round time limit</h4>
                            <div className="settingsPanel-settingsSection" style={{paddingBottom:"5px"}}>
                                    {showRoundTimeLimitButtons(selectedRoundTimeLimitIndex)}
                            </div>

                            <h4 className="settingsPanel-subHeader" >content rating</h4>
                            <div className="settingsPanel-settingsSection" style={{paddingBottom:"5px"}}>
                                    {showContentRatingButtons(selectedContentRatingIndex ? 0 : 1)}
                            </div>
                        </div>

                        {!props.isCreator && <div className="settingsPanel-optionRow" style={{'justifyContent': 'center'}}>
                            <h3>waiting for host to start match</h3>
                        </div>}
                    </div>

                </div>
            </div>



            {props.isCreator && <div style={{transform:`translate(${props.translate.x}vw, ${props.translate.y}vh)`, transition: props.transition, width:'100%', backgroundColor:'transparent', borderRadius:"10px", zIndex:2}}>
                    <div className="settingsPanel-optionRow" style={{}}>
                        <button className="settingsPanel-optionRow-ActionButton" style={{'padding':'10px', backgroundColor:'var(--disabled)', borderColor:'#606060'}} onClick={()=>props.setShowMobilePlayerCards((prev)=>!prev)}>{props.showMobilePlayerCards? "show settings" : "show players"}</button>
                        <button className="settingsPanel-optionRow-ActionButton" style={{'padding':'10px', backgroundColor:'var(--disabled)', borderColor:'#606060'}} onClick={props.startMatch}>start match</button>
                    </div>
            </div>}
        </div>
    )
}