import React, { useState, useEffect, useMemo } from 'react';
import {debounce} from 'lodash'


export function populatePromptSafeZone(windowSize:any, cardWidth:number){
    
    let promptPosXMin = getPercentOfLength(windowSize.windowWidth,50) - cardWidth
    let promptPosXMax = promptPosXMin + 500
    let promptPosYMin = 150
    let promptPosYMax = promptPosYMin + 350
    return {
        promptPosXMin,
        promptPosXMax,
        promptPosYMin,
        promptPosYMax
    }

}

export function getPercentOfLength(totalLength:number, percent:number){
    return (totalLength / 100) * percent

}

export function getRandomNumberAbsolute(min:number, max:number, randomFlip:boolean = false){
    let rotAmount = Math.floor(Math.random() * (max - min + 1) + min)
    var invertRot = Math.random() < 0.5

    if (randomFlip && invertRot) return rotAmount * -1
    else return rotAmount
}

export function isOnlyLetters(str:string){
    return /^[a-zA-Z]+$/.test(str);
}

export function lerp(x:number, y:number, a:number){return x * (1 - a) + y * a}

export function mapRange(current: number, in_min: number, in_max: number, out_min: number, out_max: number): number {
    const mapped: number = ((current - in_min) * (out_max - out_min)) / (in_max - in_min) + out_min;
    return clamp(out_min, out_max,mapped);
}

export function getRandomNicknameNoun(){
    let nouns = [
        "capybara",
        "eggs",
        "wombat",
        "sempai",
        "grandma",
        "quale",
        "knight",
        "eggplant",
        "llama",
        "dumps",
        "lobster",
        "banana",
        "muffin",
        "dolphin",
        "crier",
        "loser",
        "winner",
        "sack",
        "melon",
        "cheese",
        "soldier",
        "wizard",
        "cookie",
        "mother",
        "father",
        "farmer",
        "meat",
        "chicken",
        "snail",
        "lizard",
        "rat",
        "fish",
        "berry",
        "beast",
        "plant",
        "toilet"
    ]

    let chosenNoun = nouns[nouns.length * Math.random() | 0]
    return chosenNoun
}

export function getRandomNicknameAdjective(){
    let adjectives = [
        "sassy",
        "strong",
        "flacid",
        "soggy",
        "dank",
        "floppy",
        "swole",
        "nerdy",
        "warty",
        "creepy",
        "ugly",
        "pretty",
        "fab",
        "smelly",
        "foul",
        "cool",
        "tiny",
        "huge",
        "scared",
        "wise",
        "sore",
        "sticky",
        "rotten",
        "sweet",
        "sleepy",
        "feisty",
        "meaty",
        "evil",
        "fresh",
        "sneaky",
        "fancy",
        "salty",
        "lawful",
        "shady",
        "minty",
        "dusty",
        "oily",
        "slimy",
        "loyal",
        "weird",
        "yucky",
        "wet"
    ]

    let chosenAdjective = adjectives[adjectives.length * Math.random() | 0]
    return chosenAdjective
}





export function useWindowDimension(useDebounce = true) {
  const [dimension, setDimension] = useState([
    window.innerWidth,
    window.innerHeight,
  ]);
 
  useEffect(() => {
    let debouncedResizeHandler:any
    if (useDebounce) {
        debouncedResizeHandler = debounce(() => {
            // console.log('***** debounced resize')
            setDimension([window.innerWidth, window.innerHeight]);
          }, 100); // 100ms
    }
    else {
        debouncedResizeHandler = () => {setDimension([window.innerWidth, window.innerHeight]);
          }; 
    }
    
    window.addEventListener('resize', debouncedResizeHandler);
    return () => window.removeEventListener('resize', debouncedResizeHandler);
  }, []); // Note this empty array. this effect should run only on mount and unmount
  return dimension;
}


export function calculateLines(text:string, containerWidth:number, fontSize = 14) {
	let lines = 1;  // Initiating number of lines with 1
  
  // widths & avg value based on `Helvetica` font.
	const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.278125,0.278125,0.35625,0.55625,0.55625,0.890625,0.6671875,0.24,0.334375,0.334375,0.390625,0.584375,0.278125,0.334375,0.278125,0.303125,0.55625,0.55625,0.55625,0.55625,0.55625,0.55625,0.55625,0.55625,0.55625,0.55625,0.278125,0.278125,0.5859375,0.584375,0.5859375,0.55625,1.015625,0.6671875,0.6671875,0.7234375,0.7234375,0.6671875,0.6109375,0.778125,0.7234375,0.278125,0.5,0.6671875,0.55625,0.834375,0.7234375,0.778125,0.6671875,0.778125,0.7234375,0.6671875,0.6109375,0.7234375,0.6671875,0.9453125,0.6671875,0.6671875,0.6109375,0.278125,0.35625,0.278125,0.478125,0.55625,0.334375,0.55625,0.55625,0.5,0.55625,0.55625,0.278125,0.55625,0.55625,0.2234375,0.2421875,0.5,0.2234375,0.834375,0.55625,0.55625,0.55625,0.55625,0.334375,0.5,0.278125,0.55625,0.5,0.7234375,0.5,0.5,0.5,0.35625,0.2609375,0.3546875,0.590625]
    // const widths = [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0.278125,0.278125,0.35625,0.55625,0.55625,0.890625,0.6671875,0.1921875,0.334375,0.334375,0.390625,0.584375,0.278125,0.334375,0.278125,0.303125,0.55625,0.55625,0.55625,0.55625,0.55625,0.55625,0.55625,0.55625,0.55625,0.55625,0.278125,0.278125,0.5859375,0.584375,0.5859375,0.55625,1.015625,0.6671875,0.6671875,0.7234375,0.7234375,0.6671875,0.6109375,0.778125,0.7234375,0.278125,0.5,0.6671875,0.55625,0.834375,0.7234375,0.778125,0.6671875,0.778125,0.7234375,0.6671875,0.6109375,0.7234375,0.6671875,0.9453125,0.6671875,0.6671875,0.6109375,0.278125,0.35625,0.278125,0.478125,0.55625,0.334375,0.55625,0.55625,0.5,0.55625,0.55625,0.278125,0.55625,0.55625,0.2234375,0.2421875,0.5,0.2234375,0.834375,0.55625,0.55625,0.55625,0.55625,0.334375,0.5,0.278125,0.55625,0.5,0.7234375,0.5,0.5,0.5,0.35625,0.2609375,0.3546875,0.590625]

    const avg = 0.5293256578947368

    const logList:any = []

    let mapped = text.split('')
	//   .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
      .map(c => {
            if (c.charCodeAt(0) < widths.length) {
                logList.push([c,c.charCodeAt(0),widths[c.charCodeAt(0)],'hasWidth'])
                return widths[c.charCodeAt(0)]
            }
            else {
                logList.push([c,c.charCodeAt(0),'taking avg'])
                return avg
            }
        })
    let added = mapped.reduce((cur, acc, index) => {
        // console.log(`${index} (${logList[index][0]} - size: ${(acc + cur)*fontSize}px`)

		if((acc + cur) * fontSize  > containerWidth) {
            // console.log('-- +1 line')
			lines ++;
			cur = acc;
		}
		return acc + cur;
	  }); 

    // console.log(mapped)
    // console.log(added)
    // console.table(logList)
  
	text.split('')
	//   .map(c => c.charCodeAt(0) < widths.length ? widths[c.charCodeAt(0)] : avg)
      .map(c => {
            if (c.charCodeAt(0) < widths.length) {
                // logList.push([c,c.charCodeAt(0),widths[c.charCodeAt(0)],'hasWidth'])
                return widths[c.charCodeAt(0)]
            }
            else {
                logList.push([c,c.charCodeAt(0),'taking avg'])
                return avg
            }
        })
	  .reduce((cur, acc, index) => {
        // console.log(index, cur ,acc)
		if((acc + cur) * fontSize  > containerWidth) {
			lines ++;
			cur = acc;
		}
		return acc + cur;
	  }); 
    // console.table(logList)
	return lines;
}



export function getRandomFromArray(arr:string[], n:number) {
    if (n>arr.length){
        console.log(`trying to grab ${n} from array BUT theres only ${arr.length} in there.`)
        n = arr.length
    }
    var result:any[] = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
}


export function clamp(minValue:number, maxValue:number, input:number){
    return Math.min(Math.max(input, minValue), maxValue)
}


export function returnTouchMoveDelta(event:any, touchStartPosition:number, swipeDeltaNeeded:number, clampDelta?:number){
    let touchDelta = event.touches[0].clientX - touchStartPosition
    if (clampDelta === undefined) clampDelta = swipeDeltaNeeded
   
    if (touchDelta > clampDelta) return clampDelta
    if (touchDelta < -clampDelta) return -clampDelta
    
    
    return touchDelta
}




// function throttle(f:any) {
//     let token:number|null = null,
//       lastArgs:any|null = null;
//     function invoke() {
//       f(...lastArgs);
//       token = null;
//     };
//     function result(...args) {
//       lastArgs = args;
//       if (!token) {
//         token = requestAnimationFrame(invoke);
//       }
//     };
//     result.cancel = () => token && cancelAnimationFrame(token);
//     return result;
//   };

