

interface ToolTipProps {
    text:string,
    fontSize?:string,
    widthRems:number,
    position:{x?:string, y?:string, calc?:string},
    alignment:string
}

export default function ToolTip(props:ToolTipProps){

    let showDebugDot = false
    
    let align:number
    if (props.alignment === 'left') align = 1.25
    else if (props.alignment === 'right') align = props.widthRems - 1.25
    else align = props.widthRems/2

    let translation:string
    if (props.position.calc) translation = `translate(calc(${props.position.calc}))`
    else translation = `translate(${props.position.x},${props.position.y})`

    


    return (
        <div
        style={{
            position:'absolute',
            transform:translation,
            pointerEvents:'none'
        }}
        >
            {showDebugDot && <div style={{backgroundColor:'red', width:'2px', height:'2px'}}/>}
            <div
            style={{
                position:'fixed',
                zIndex:2,
                opacity:'1',
                transform:`translate(-10px,3px)`,
                width:'0px',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: '10px solid var(--fillBorder)'
            }}
            />

            <div
            style={{
                position:'fixed',
                zIndex:3,
                opacity:'1',
                transform:`translate(-10px,6px)`,
                width:'0px',
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderBottom: '10px solid var(--fillColor)'
            }}
            />
            
            <div
            style={{
                position:'fixed',
                opacity:'1',
                backgroundColor:'var(--fillColor)',
                boxShadow:'0 0 0 2px var(--fillBorder)',
                borderRadius:'0.4rem',
                padding:'0.3rem 0',
                width:`${props.widthRems}rem`,
                transform:`translate(-${align}rem,14px)`
                // background-color: var(--fill);
                // box-shadow: 0 0 0 2px var(--prompt);
                // border-radius: 0.4rem;
                // padding: 0.3rem 0;
                // width: 9rem;
                // transform: translate(calc(-50% + 3rem),14px);
            }}
            >
                <b style={{fontSize:props.fontSize??'' }}>{props.text}</b>
            </div>
        </div>
    )
}