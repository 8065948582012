import React, {useEffect,useState, useContext, useRef} from 'react'
import ResultsCard from './ResultsCard';
import ResultsPanel from './ResultsPanel';
import { matchContext, matchContextInterface } from '../../App';
import ShareResultImage from './ShareResultImage';

function getRoundWinnersFromVotes(votes: any) {
	let winners: any[] = []
	Object.keys(votes).forEach((roundIndex) => {
		let roundObj: any = votes[roundIndex]
		const mostVotes = Object.entries(roundObj).reduce((bestEntry: any, thisEntry: any) =>
			thisEntry[1] > bestEntry[1] ? thisEntry : bestEntry
		)[1]

		let userWithMostVotes = Object.keys(roundObj).filter((key) => roundObj[key] === mostVotes)
		winners.push(userWithMostVotes)
	})

	return winners
}



interface resultsProps{
	playerId:string,
	votes:any,
	players:any,
	playerNames:any,
	prompts:any,

}



function Results(props:resultsProps){
	const [playerPlacement, setPlayerPlacement] = useState({didTie:false, place:0})
	const [currentRoundIndex, setCurrentRoundIndex] = useState<number>(0)
	const [roundWinners, setRoundWinners] = useState<string[]>()
	const [roundCards, setRoundCards] = useState<any>()
	const [cardsYOffset, setCardsYOffset] = useState<number>(window.innerHeight + 100)
	const [losers, setLosers] = useState<any>({})
	const [winners, setWinners] = useState<any>({})
	const matchContextValues:matchContextInterface = useContext(matchContext)
	const [resultsPanelXOffset, setResultsPanelXOffset] = useState<number>(-50)
	const pileRef = useRef<number[][][] | null>([])

	const renderCounter  = useRef(0);
	renderCounter.current = renderCounter.current + 1;
    console.debug('>>>>>> ', renderCounter.current)


	useEffect(() => {
		setRoundWinners(()=>{
			let roundWinners = getRoundWinnersFromVotes(props.votes)
			createCardClusterData(roundWinners)
			return roundWinners
		});

		setTimeout(() => {setCardsYOffset(0) }, 5)
		setTimeout(() => {setResultsPanelXOffset(0)},50)

		matchContextValues.setIsInMatch!(false)
	}, []);



	function createCardClusterData(roundWinners:string[][]){
        if (!roundWinners) return

        let playerScores:any = []
		let playerScoresObject:any = {}
		let topPoints = 0
		let winningPlayerIds:string[] = []

        Object.keys(props.playerNames).forEach((playerId:string) => {
            let nickname = props.playerNames[playerId].nickname
            let points = 0
			let wins = 0
			let ties = 0
			let votes = 0

			Object.keys(props.votes).forEach((round:any)=> {
				if (playerId in props.votes[round]) votes += props.votes[round][playerId]
			})
                
            for (let i = 0; i < roundWinners.length; i++) {
                if (roundWinners[i].length === 1 && roundWinners[i][0] === playerId) {
					wins += 1
				}
                else if (roundWinners[i].includes(playerId)){ 
					ties += 1
				}
            }

			points = (votes*1) + (ties*5) + (wins*10)

            playerScores.push([nickname,points])
			playerScoresObject[playerId] = {
				nickname:nickname,
				points:points,
				wins:wins,
				ties:ties,
				votes:votes
			}

			if (points === topPoints) winningPlayerIds.push(playerId)
			else if (points > topPoints) {
				winningPlayerIds = []
				winningPlayerIds.push(playerId)
				topPoints = points
			}
 
        })

		let tempPlayerPlacement = {
			didTie:false,
			place:0
		}

        playerScores.sort((first:any, second:any) => second[1] - first[1])

		let winners:any = {}
			winningPlayerIds.forEach((winningPlayerId:string)=>{
				winners[winningPlayerId] = playerScoresObject[winningPlayerId]
				delete playerScoresObject[winningPlayerId]
			})
		setWinners(winners) 
		
		setLosers(playerScoresObject)



		if (Object.keys(winners).includes(props.playerId)) {
			if (Object.keys(winners).length >= tempPlayerPlacement.place) tempPlayerPlacement.place = 1
			if (Object.keys(winners).length > 1) {
				if (tempPlayerPlacement.place === 1) tempPlayerPlacement.didTie = true
				else {
					tempPlayerPlacement.place = (tempPlayerPlacement.place + 1) - Object.keys(winners).length
				}
			}
		}
		else {
			let tempPointAmount = -1
			let tempPlacesToAdd = 1
			Object.keys(playerScoresObject).forEach((id, index)=> {
				if (playerScoresObject[id].points === tempPointAmount) {
					if (id === props.playerId || playerScoresObject[id].points === playerScoresObject[props.playerId].points) tempPlayerPlacement.didTie = true
				}
				else {
					tempPlacesToAdd += 1
					tempPointAmount = playerScoresObject[id].points
				}
				if (id === props.playerId) tempPlayerPlacement.place += tempPlacesToAdd 
			})
			
		}

		setPlayerPlacement(tempPlayerPlacement)

		let initialRoundCards: any[] = []

        roundWinners.forEach((roundWinnerArray, roundIndex) => {
			initialRoundCards.push([])
            roundWinners[roundIndex].forEach((user, index) => {
                
				initialRoundCards[roundIndex].push({
                    key:`${roundIndex}-${user}`,
                    round:roundIndex,
                    prompt:props.prompts[roundIndex],
                    fills:props.players[user].submittedFills[roundIndex],
                    user:user,
                    nickname:props.playerNames[user].nickname,
                    didUserWin:roundWinners[roundIndex].length === 1
                })

            })

			setRoundCards(initialRoundCards)
        })
    }



	function handleScroll(event:any) {
		if (event.deltaY > 0) {
			console.debug('NEXT >>')

			setCurrentRoundIndex(prev => {
				if (roundWinners?.length === undefined) return prev
				if (prev + 1 < roundWinners.length) return prev + 1

				return prev
			})
		}
		else {
			console.debug('<< PREV')
			
			setCurrentRoundIndex(prev => {
				if (roundWinners?.length === undefined) return prev
				if (prev - 1 >= 0) return prev - 1

				return prev
				
			})
		}
	}
	


	function getPercentWindowWidth(percent:number) {
		return (window.innerWidth/100) * percent
	}


	function showScrollHint() {

		let arrowParentStyle:React.CSSProperties = {
			position:'absolute',
			width:'calc(100vw - 500px)',
			margin:'0px 30px 0px 470px',
			bottom:'60px',
			display:'flex',
			justifyContent:'center',
			opacity: currentRoundIndex === 0 ? "1" : "0",
			transition: `opacity 300ms cubic-bezier(0.21, 0.84, 0.16, 1)`
		}
		
		return (
			<div style={arrowParentStyle}>
				<p style={{animationName:'resultsScrollHintAnimation',animationDelay:'2s', animationFillMode:'forwards', animationDuration:'2.5s', animationIterationCount:'infinite', fontSize:'20px', fontWeight:'bold', color:'#404040', opacity:0}}>scroll down to view other rounds</p>
			</div>
		)
	}

	
	const cardWidth = 250
	
	return (
		<div style={{  width: "100%", height: "100%", overflow: "hidden" }} onWheel={handleScroll}>

			{showScrollHint()}

			<div style={{position:'absolute', width:'100vw', height:'100vh', top:0, transform:`translate(${resultsPanelXOffset}vw,0px)`, transition:'transform 1200ms cubic-bezier(0.18, 0.58, 0.34, 1.01)'}}>

				<ResultsPanel translate={`0px,0px)`} transition={''} playerPlacement={playerPlacement} winners={winners} losers={losers} playerId={props.playerId}/>

				<div style={{marginLeft:'460px', height:'100vh', display:'flex', flexDirection:'column', justifyContent: 'center', rowGap:'20px'}} >
					{roundWinners && roundWinners.map((winners:any, roundIndex:number) => {
						let status = "lose"
						let color = ""

						if (winners.includes(props.playerId)) {
							if (winners.length > 1) status = "tie"
							else status = "win"
						}

						if (currentRoundIndex < roundIndex) color = "var(--prompt)"
						if (currentRoundIndex > roundIndex) color = "#808080"
						if (currentRoundIndex === roundIndex) color = "white"

						let statusTranslation = currentRoundIndex === roundIndex ? "" : "translate(0px,-50%)"
						let statusOpacity = currentRoundIndex < roundIndex ? 0 : 1

						let roundTranslation = currentRoundIndex === roundIndex ? "" : "translate(0px,-50%)"
						let roundOpacity = currentRoundIndex === roundIndex ? 1 : 0
						
						return (
							<div key={roundIndex} style={{display:'flex', width:'fit-content', cursor:'pointer'}} onClick={()=>setCurrentRoundIndex(roundIndex)}>
								<div style={{width:'5px', height:'45px', marginRight:'10px', borderRadius:'10px', backgroundColor:color}} />
								<div style={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'flex-start', fontSize:'1.1rem' }}>
									<b style={{transform:roundTranslation, opacity:roundOpacity, transition:'opacity 500ms cubic-bezier(0.21, 0.84, 0.16, 1), transform 500ms cubic-bezier(0.21, 0.84, 0.16, 1)'}}>round {roundIndex + 1}</b>
									<p style={{color:color, transform:statusTranslation, opacity:statusOpacity, transition:'opacity 500ms cubic-bezier(0.21, 0.84, 0.16, 1), transform 500ms cubic-bezier(0.21, 0.84, 0.16, 1), color 500ms cubic-bezier(0.21, 0.84, 0.16, 1)'}}>{status}</p>
								</div>
							</div>
						)
					})}
					
				</div>
			</div>


			

			<div style={{marginTop:'100px', position:'absolute', marginLeft:'220px'}}>
				
				{roundCards && roundCards.map((round:any, roundIndex:number)=>{
					if (pileRef.current![roundIndex] === undefined) pileRef.current![roundIndex] = []
					
					let baselineY = 0
					let clusterTextTransitionTime = 800
					let roundY = 0
					if (roundIndex > currentRoundIndex) roundY = window.innerHeight + 100
					if (roundIndex < currentRoundIndex) roundY = - (window.innerHeight + 100)

					
					return (
						<div key={roundIndex} style={{transform:'scale(1)'}}>
							{roundCards[roundIndex].map((cardCluster:any, clusterIndex:number) => {
								if (pileRef.current![roundIndex][clusterIndex] === undefined) pileRef.current![roundIndex][clusterIndex] = []
								let centerOffset = 0
								if (roundCards[roundIndex].length > 1) centerOffset = ((roundCards[roundIndex].length-1) * 300)/2

								return (
									<div key={cardCluster.key} style={{transform:`translate(-${centerOffset}px,0px)`}}>
										<h3
											style={{
												width:cardWidth,
												position:'absolute',
												transform:`translate(${window.innerWidth/2 - cardWidth/2 + (300*clusterIndex)}px,${baselineY - 80 + cardsYOffset + roundY}px)`,
												transition: `transform ${clusterTextTransitionTime}ms cubic-bezier(0.21, 0.84, 0.16, 1)`,
												fontSize:'1.5rem',
												zIndex:-2,
												fontWeight: props.playerNames[props.playerId].nickname === cardCluster.nickname ? "bold" : "normal",
												color : props.playerNames[props.playerId].nickname === cardCluster.nickname ? "var(--highlight)" : ""
											}}>
												{cardCluster.nickname}
										</h3>

										<ResultsCard
											pileRef={(el:number) => (pileRef.current![roundIndex][clusterIndex][0] = el)}
											type='prompt'
											text={cardCluster.prompt.prompt}
											clusterIndex={clusterIndex}
											x={window.innerWidth/2 - cardWidth/2 + (300*clusterIndex)}
											y={cardsYOffset + baselineY + roundY}
										/>

										{cardCluster.fills.map((fill:string, fillIndex:number) => {
											
											let prev = 0
											pileRef.current![roundIndex][clusterIndex].forEach((height, heightIndex)=>{
												if (heightIndex < fillIndex + 1) prev += height + 40
											})

											let currentClusterFillOffset = prev

											return (
												<div key={fill}>
													<ResultsCard
														pileRef={(el:number) => (pileRef.current![roundIndex][clusterIndex][fillIndex+1] = el)}
														key={cardCluster.key + `-${fillIndex}`}
														type='fill'
														text={fill}
														clusterIndex={clusterIndex}
														x={window.innerWidth/2 - cardWidth/2 + (300*clusterIndex)}
														y={currentClusterFillOffset + cardsYOffset + baselineY + roundY}
													/>

													<div style={{transform:`translate(${window.innerWidth/2 - cardWidth/2 + (300*clusterIndex)}px, ${currentClusterFillOffset + cardsYOffset + baselineY + roundY + 350 + 20}px)`, transition:`transform 750ms cubic-bezier(0.21, 0.84, 0.16, 1)`}}>
														<ShareResultImage prompt={cardCluster.prompt.prompt} fill={fill}/>
													</div>
													
												</div>
											)
										})}
									</div>
								)
							})}
						</div>
					)
				})}
				
			</div>

		</div>
	)
}



export default Results