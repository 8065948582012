import { useContext, useEffect, useState } from "react"
import { matchContext } from "../../App"
import { isMobile } from 'react-device-detect';


interface PrematchPlayerCardProps {
    placement:any,
    playerName:string,
    cardPlayerId:string,
    playerId:string,
    isCreator:boolean,
    isNewest:boolean,
    isPlayer:boolean,
    setHavePlayerCardsAnimatedOn: React.Dispatch<React.SetStateAction<boolean>>,
    havePlayerCardsAnimatedOn:boolean,
    raiseZIndex: boolean,
    showMobilePlayerCards: boolean,
    kickPlayer:(playerId:string) => void
}

export default function PrematchPlayerCard(props:PrematchPlayerCardProps) {
    let vw = (props.placement.x / window.innerWidth) * 100
    let vh = (props.placement.y / window.innerHeight) * 100
    
    const [translateY, setTranslateY] = useState<number>(props.havePlayerCardsAnimatedOn? vh : 100)
    const [translateX, setTranslateX] = useState<number>(vw)
    const [scale, setScale] = useState<number>(1)
    const [transition, setTransition] = useState<string>("transform 1000ms cubic-bezier(0.12, 0.45, 0.18, 1)")
    const [isHovering, setIsHovering] = useState<boolean>(false)
    let matchContextValues = useContext(matchContext)
    


    useEffect(()=> {
        if (props.havePlayerCardsAnimatedOn) return

        setTimeout(()=>setTranslateY((props.placement.y / window.innerHeight) * 100),50 + Math.random()*100)
        setTimeout(()=>props.setHavePlayerCardsAnimatedOn(true),200)
    },[])



    useEffect(()=>{
        if (isMobile && !props.showMobilePlayerCards) return

        if (matchContextValues.isInMatch) {
            setTransition("transform 1500ms cubic-bezier(0.63, 0, 0.34, 1.01)")
            setTimeout(()=>{
                setTranslateX(-100)
            }, Math.random()*100)
        }
    },[matchContextValues.isInMatch])



    useEffect(()=> {
        if (props.isNewest) {
            setTimeout(()=> {
                setScale(1.1)
                setTimeout(()=>setScale(1),500)
            },50)
        }
    },[props.isNewest])
    

    
    let transform = `translate(${translateX}vw, ${translateY}vh) rotate(${props.placement.rot}deg) scale(${scale})`
    let isPlayer = props.playerName === "" ? false : true
    let name = isPlayer ? props.playerName : "player"

    const playerStyle: React.CSSProperties = {
        backgroundColor:'var(--fill)',
        border:props.isPlayer? '2px solid #808080' : '2px solid var(--fillBorder)',
        borderRadius:'10px',
        width:'250px',
        height:'350px',
        transform:transform,
        transition:transition,
        zIndex:props.raiseZIndex? 2 : ''
    }

    const emptyCardStyle: React.CSSProperties = {
        backgroundColor:'#1a1a1adb',
        border:'2px dashed var(--fillBorder)',
        borderRadius:'10px',
        width:'250px',
        height:'350px',
        transform:transform,
        transition:transition
    }

    function showOptionButtons() {
        if (!isPlayer) return
        if (!props.isCreator) return
        if (props.playerId === props.cardPlayerId) return

        return (
            <div>
                <button className="settingsPanel-optionRow-ActionButton" style={{marginTop:'20px', padding:'10px', width:'auto', fontSize:'1rem', opacity:isHovering? 1 : 0, transition:'opacity 150ms ease-in-out'}} onClick={()=>props.kickPlayer(props.cardPlayerId)}>kick player</button>
            </div>
        )
    }



    return (
        <div key={props.placement.tempName} style={isPlayer? playerStyle : emptyCardStyle} onMouseOver={()=>setIsHovering(true)} onMouseOut={()=>setIsHovering(false)}>
            <div className="FMP-card-textbox--div" style={{fontSize:'20px', fontWeight:'bold', color:isPlayer? 'var(--fillText)' : 'var(--fillBorder)'}}> {name} </div>
            {showOptionButtons()}
        </div>
    )
}